const initialState = {
   errorMessagePayload: false,
   successMessagePayload: false,
   multiSuccessMessagePayload: false,
   multiErrorMessagePayload: false
};

export default function (state = {}, action) {
   switch (action.type) {
      case 'MESSAGE':
         {
            return {
               ...state,
               messagePayload: action.payload
            };
         }
      case 'ERROR_CLEARED': {
         const newState = Object.assign({}, initialState);
         return newState;
      }
      default:
         return state;
   }
}
