import React from 'react';
import { Container, Button, Modal, Dropdown, Form, Message, Icon, Confirm, Input, Popup, Segment, Dimmer, Loader, Select } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { getUserList, getUserRole, createUser, deleteUser, changePassword } from '../Redux/Actions/UsersAction'
import { clearMessage } from '../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';

const UserMaster = () => {

   const inputRef = React.useRef();

   const dispatch = useDispatch()
   React.useEffect(() => {
      dispatch(getUserList(authToken))
      dispatch(getUserRole(authToken))
      inputRef.current && inputRef.current.focus();
   }, [])

   const authToken = localStorage.getItem('accessToken');
   const [openAddModal, setOpenAddModal] = React.useState(false)
   const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
   const [rowId, setRowId] = React.useState(false)
   const userList = useSelector(state => state.userListReducer.userListPayload)
   const userRoleData = useSelector(state => state.userListReducer.userRolePayload)
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   const isPageLoading = loadingData && loadingData.string === 'user_list' ? loadingData.status : false
   const messageData = useSelector(state => state.messageReducer.messagePayload);
   const messageStatus = messageData && messageData.string === 'user_save' ? messageData.status : false

   const userRoleList = userRoleData && userRoleData.map((item)=>{
      return {
         key: item.RoleID,
         text: item.RoleName,
         value: item.RoleID
      }
   })
   const optionConv = (data) => {
      if (!Array.isArray(data)) return [];
      return data.map((m, i) => ({
         key: m.RoleID,
         text: m.RoleName,
         value: m.RoleID,
      }));
   };

   const [actionMode, setActionMode] = React.useState('');
   const [openFilter, setOpenFilter] = React.useState(false);
   const [userData, setUserData] = React.useState([]);
   const [searchInput, setSearchInput] = React.useState('');
   const [firstInput, setFirstInput] = React.useState('');
   const [secondInput, setSecondInput] = React.useState('');
   const [thirdInput, setThirdInput] = React.useState('');
   const [fourthInput, setFourthInput] = React.useState('');
   const [fifthInput, setFifthInput] = React.useState('');
   const [sixthInput, setSixthInput] = React.useState('');


   const filteredItems = userList && userList.filter(item => (
      openFilter ?
      item.FirstName.toLowerCase().includes(firstInput.toLowerCase()) &&
      item.LastName.toLowerCase().includes(secondInput.toLowerCase()) &&
      item.UserName.toLowerCase().includes(thirdInput.toLowerCase()) &&
      item.RoleName.toLowerCase().includes(fourthInput.toLowerCase()) &&
      item.MobileNo.toLowerCase().includes(fifthInput.toLowerCase()) &&
      item.EmailID.toLowerCase().includes(sixthInput.toLowerCase()) :
      item.FirstName.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.LastName.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.UserName.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.RoleName.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.EmailID.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.MobileNo.toLowerCase().includes(searchInput.toLowerCase())
   ));


   const actions = <React.Fragment>
      <Button
         color='violet' 
         onClick={() => setOpenFilter(openFilter => !openFilter)}>
            <Icon name='filter' /> 
            Advance Filter
      </Button>
      <Button
         primary 
         onClick={() => (setOpenAddModal(true), setActionMode('Add'))}>
            <Icon name='add user' /> 
            Add New
      </Button>
   </React.Fragment>;

   const searchComponent = <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />

   const getDropdownText = (value) => {
      const data = userRoleList.find(o => o.value === value);
      data ? setFourthInput(data.text) :setFourthInput('')
      // console.log(data)
   }
   const advanceSearchComponent = <div className="subheader-searchbar">
      <Input autoFocus placeholder='First Name' value={firstInput} onChange={e => setFirstInput(e.target.value)} />
      <Input placeholder='Last Name' value={secondInput} onChange={e => setSecondInput(e.target.value)} />
      <Input placeholder='User Name' value={thirdInput} onChange={e => setThirdInput(e.target.value)} />
      <Dropdown
         placeholder='User Type'
         selection
         clearable
         options={userRoleList}
         defaultValue={fourthInput}
         onChange={(e, {value}) => getDropdownText(value)}
      />
      <Input placeholder='Mobile No.' value={fifthInput} onChange={e => setFifthInput(e.target.value)} />
      <Input placeholder='Email Id.' value={sixthInput} onChange={e => setSixthInput(e.target.value)} />
   </div>

   const conditionalRowStyles = [
      {
         when: row => row.UserStat === 'Inactive',
         style: {
            backgroundColor: '#f3f3f3',
            color: 'rgb(132 132 132 / 87%)'
         },
      }
   ]
   const columns = [
      {
         name: 'First Name',
         selector: 'FirstName',
         sortable: true,
      },
      {
         name: 'Last Name',
         selector: 'LastName',
         sortable: true,
      },
      {
         name: 'User Name',
         selector: 'UserName',
         sortable: true,
      },
      {
         name: 'User type',
         selector: 'RoleName',
         sortable: true,
      },
      {
         name: 'Mobile No.',
         selector: 'MobileNo',
         sortable: true,
      },
      {
         name: 'Email Id',
         selector: 'EmailID',
         sortable: true,
      },
      {
         name: 'Action',
         sortable: false,
         right: true,
         cell: (data) => <React.Fragment>
            {data.UserName !== 'admin' ?
               <Button.Group basic size='small'>
                  <Popup
                     trigger={
                        <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('Delete'))}>
                           <Icon style={{ color: 'red' }} name='trash alternate outline' />
                        </Button>}
                     content='Delete'
                     position='top right'
                  />
                  <Popup
                     trigger={
                        <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('Reset'))}>
                           <Icon style={{ color: '#001fff' }} name='redo' />
                        </Button>}
                     content='Password Reset'
                     position='top right'
                  />
                  {data.UserStat === 'Inactive' ? <Popup
                     trigger={
                        <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('ReActive'))}>
                           <Icon style={{ color: '#ff8d00' }} name='lock' />
                        </Button>}
                     content='Make Active'
                     position='top right'
                  /> : <Popup
                        trigger={
                           <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('StatusInactive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock open' />
                           </Button>}
                        content='Make Inactive'
                        position='top right'
                     />}
                  <Popup
                     trigger={
                        <Button icon onClick={() => (setUserData(data), setOpenAddModal(true), setActionMode('Edit'))}>
                           <Icon style={{ color: '#001fff' }} name='edit outline' />
                        </Button>}
                     content='Edit'
                     position='top right'
                  />
               </Button.Group> : null}
         </React.Fragment>,
      },
   ];

   const customStyles = {
      headCells: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: '700',
         },
      }
   }

   const userSchema = Yup.object().shape({
      FirstName: Yup.string()
         .min(2, 'User name is too short!')
         .max(30, 'User name is too Long!')
         .required('User name is required'),
      LastName: Yup.string()
         .min(2, 'User name is too short!')
         .max(30, 'User name is too Long!'),
      MobileNo: Yup.string()
         .min(10, 'Mobile no. is too short!')
         .max(11, 'Mobile no. is too Long!')
         .matches(/^(?<=\s|^)\d+(?=\s|$)/, "Please provide valid mobile numbers"),
      EmailID: Yup.string()
         .min(2, 'Email id is too short!')
         .max(50, 'Email id is too Long!')
         .email('Please prvide valid email id'),
      UserName: Yup.string()
         .min(2, 'User name is too short!')
         .max(100, 'User name is too Long!')
         .required('User name is required'),
      RoleID: Yup.string()
         .required('User type is required'),
   });

   const maxLengthCheck = (object) => {
      // const re = /^[0-9\b]+$/; //rules
      if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
   }
   // let input = null

   setTimeout(() => {
      dispatch(clearMessage())
   }, 5000)

   return (
      <div>
         <Container fluid>
            <DataTable
               title='User Master'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               actions={actions}
               pagination={true}
               paginationResetDefaultPage={true}
               subHeader
               subHeaderComponent={openFilter ? advanceSearchComponent : searchComponent}
               conditionalRowStyles={conditionalRowStyles}
               persistTableHead
               progressPending={isPageLoading}
               progressComponent={
                  <Segment>
                     <Dimmer active inverted>
                        <Loader size='large'>Loading</Loader>
                     </Dimmer>
                  </Segment>
               }
            />
            <Modal open={openAddModal} size='tiny'>
               <Formik
                  enableReinitialize
                  initialValues={actionMode === "Add" ? {
                     Mode: "A",
                     UserID: 0,
                     RoleID: "",
                     UserName: "",
                     Password: "password",
                     FirstName: "",
                     LastName: "",
                     MobileNo: "",
                     EmailID: "",
                  } :
                     {
                        Mode: "E",
                        UserID: userData.UserID,
                        RoleID: userData.RoleID,
                        UserName: userData.UserName,
                        FirstName: userData.FirstName,
                        LastName: userData.LastName,
                        MobileNo: userData.MobileNo,
                        EmailID: userData.EmailID,
                     }
                  }
                  validationSchema={userSchema}
                  onSubmit={(values, actions) => { dispatch(createUser(values, actions, actionMode, authToken)) }}
               >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     isSubmitting,
                     handleSubmit,
                     setFieldValue
                  }) => (
                        <React.Fragment>
                           <Modal.Header>{actionMode === "Edit" ? 'Edit User' : 'Create user'}</Modal.Header>
                           <Modal.Content>
                              {messageStatus && <Message error header={messageData.message} />}
                              <Form>
                                 <Form.Field>
                                    <label>User Type</label>
                                    <Dropdown
                                       error={errors.RoleID && touched.RoleID}
                                       placeholder='Select user type'
                                       selection
                                       name="RoleID"
                                       onChange={(event, data) => {
                                          setFieldValue('RoleID', data.value)
                                       }}
                                       defaultValue={values.RoleID}
                                       options={userRoleList}
                                    />
                                 </Form.Field>
                                 <Form.Input
                                    autoFocus
                                    error={errors.UserName && touched.UserName ? { content: errors.UserName } : false}
                                    fluid
                                    label='Username'
                                    placeholder='Username'
                                    innerRef={inputRef}
                                    disabled={actionMode === "Edit" ? true : false}
                                    type="text"
                                    value={values.UserName}
                                    onChange={handleChange('UserName')}
                                 />
                                 <Form.Group widths='equal'>
                                    <Form.Input
                                       error={errors.FirstName && touched.FirstName ? { content: errors.FirstName } : false}
                                       fluid
                                       label='First Name'
                                       placeholder='First Name'
                                       type="text"
                                       value={values.FirstName}
                                       onChange={handleChange('FirstName')}
                                    />
                                    <Form.Input
                                       error={errors.LastName && touched.LastName ? { content: errors.LastName } : false}
                                       fluid
                                       label='Last Name'
                                       placeholder='Last Name'
                                       type="text"
                                       value={values.LastName}
                                       onChange={handleChange('LastName')}
                                    />
                                 </Form.Group>
                                 <Form.Group widths='equal'>
                                    <Form.Input
                                       error={errors.MobileNo && touched.MobileNo ? { content: errors.MobileNo } : false}
                                       fluid
                                       maxLength="11"
                                       label='Mobile Number'
                                       placeholder='Mobile Number'
                                       type="number"
                                       onInput={maxLengthCheck}
                                       value={values.MobileNo}
                                       onChange={handleChange('MobileNo')}
                                    />
                                    <Form.Input
                                       error={errors.EmailID && touched.EmailID ? { content: errors.EmailID } : false}
                                       fluid
                                       label='Email Id'
                                       placeholder='Email Id'
                                       type="text"
                                       value={values.EmailID}
                                       onChange={handleChange('EmailID')}
                                    />
                                 </Form.Group>
                              </Form>
                           </Modal.Content>
                           <Modal.Actions>
                              <Button negative onClick={() => (window.location.reload(), setOpenAddModal(false))}>
                                 Close
                                 </Button>
                              <Button
                                 content={actionMode === "Edit" ? "Save" : "Create"}
                                 type="submit"
                                 labelPosition='right'
                                 icon='checkmark'
                                 loading={isSubmitting}
                                 onClick={handleSubmit}
                                 positive
                              />
                           </Modal.Actions>
                        </React.Fragment>
                     )}
               </Formik>
            </Modal>
            <Confirm
               header='Are you sure?'
               content={
                  actionMode === 'Delete' ? 'Data will lost' :
                     actionMode === 'StatusInactive' ? 'Data will inactive.' :
                        actionMode === 'ReActive' ? 'Data will active?' :
                           actionMode === 'Reset' ? 'Password will reset as default' : null
               }
               open={openDeleteConfirm}
               onCancel={() => setOpenDeleteConfirm(false)}
               onConfirm={() => {
                  actionMode === 'Reset' ?
                     dispatch(changePassword(rowId, setOpenDeleteConfirm, actionMode, authToken)) :
                     dispatch(deleteUser(rowId, setOpenDeleteConfirm, actionMode, authToken))
               }}
               size='mini'
            />
         </Container>
      </div>
   )
}

export default UserMaster