import React from 'react'
import { Button, Segment, Dimmer, Modal, Loader } from 'semantic-ui-react'

const AppLoader = () => {

  return (
    <Segment className="loaderHeight">
      <Dimmer active inverted>
        <Loader size='medium'>Loading</Loader>
      </Dimmer>
    </Segment>
  )
}

export default AppLoader