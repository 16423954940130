import React from 'react';
import {
  Container,
  Button,
  Dropdown,
  Icon,
  Confirm,
  Input,
  Popup,
  Grid,
  Table,
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProduct, statusProduct } from '../../Redux/Actions/ProductAction'
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom';
import { IMAGE_URL } from '../../Resources/Api';
import noImage from '../../Resources/images/no-image.jpg';
import { getProductCategory } from '../../Redux/Actions/ProductCategoryAction'
import AppLoader from "../../Components/AppLoader";

const ProductMaster = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getProduct(authToken, 0))
    dispatch(getProductCategory(authToken))
  }, [])

  let location = useLocation();
  const menuList = useSelector(state => state.menuReducer.menuPayload)
  const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)
  const productList = useSelector(state => state.productReducer.productPayload)

  const productCategoryData = useSelector(state => state.productReducer.productCategoryPayload)
  const productCategoryList = productCategoryData && productCategoryData.filter(item => item.CatStatus === 'Active').map(data => {
    return {
      key: data.CategoryID,
      text: data.CategoryName,
      value: data.CategoryID
    }
  })

  const authToken = localStorage.getItem('accessToken');
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
  const [rowId, setRowId] = React.useState(false)
  const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
  const isLoading = loadingData && loadingData.status ? loadingData.status : false

  const [actionMode, setActionMode] = React.useState('');
  const [openFilter, setOpenFilter] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [firstInput, setFirstInput] = React.useState('');
  const [secondInput, setSecondInput] = React.useState('');
  const [thirdInput, setThirdInput] = React.useState('');
  const [fourthInput, setFourthInput] = React.useState('');

  const filteredItems = productList && productList.filter(item => (
    openFilter ?
      item.ProductName.toLowerCase().includes(firstInput.toLowerCase()) &&
      item.Category.toLowerCase().includes(secondInput.toLowerCase()) &&
      item.AvailableLoc.toString().includes(thirdInput) &&
      item.DeliveryInformation.toLowerCase().includes(fourthInput.toLowerCase()) :
      item.ProductName.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.Category.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.AvailableLoc.toString().includes(searchInput) ||
      item.ShelfLifeDays.toString().includes(searchInput) ||
      item.WeightPUnitInKG.toString().includes(searchInput) ||
      item.DeliveryInformation.toLowerCase().includes(searchInput.toLowerCase())
  ));

  // console.log(priceModalOpen)


  const actions = <React.Fragment>
    <Button
      color='violet'
      onClick={() => setOpenFilter(openFilter => !openFilter)}>
      <Icon name='filter' />
      Advance Filter
    </Button>
    {rollPermission && rollPermission.Canadd &&
      <Button
        primary
        onClick={() => history.push(`/Product-Save/${0}`)}>
        <Icon name='add circle' />
        Add new
      </Button>}
  </React.Fragment>;
  const [startDate, setStartDate] = React.useState(new Date());
  const searchComponent = <div className="table-search">
    <DatePicker
      dateFormat="dd-MM-yyyy"
      todayButton="Today"
      selected={startDate}
      showMonthDropdown
      showYearDropdown
      // disabled={isDisable}
      onChange={date => (
        setStartDate(date)
        // dispatch(getListAction(date, authToken, setProductData, setIsLoading))
      )}
      customInput={
        <Input placeholder='DD-MM-YYYY' action={{ icon: 'calendar alternate outline' }} />
      } />
    <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />
  </div>
  const getDropdownText = (value) => {
    const data = productCategoryList.find(o => o.value === value);
    data ? setSecondInput(data.text) : setSecondInput('')
    // console.log(data)
  }

  const deliveryAtData = [{ key: 'K', value: "K", text: 'Kolkata' },
  { key: 'O', value: "O", text: 'Outside Kolkata' },
  { key: 'B', value: "B", text: 'Both' }]

  const getDropdownTextDA = (value) => {
    const data = productCategoryList.find(o => o.value === value);
    data ? setThirdInput(data.text) : setThirdInput('')
    // console.log(data)
  }

  const advanceSearchComponent = <div className="subheader-searchbar">
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Input
            style={{width: '100%', marginTop: -4}}
            autoFocus
            placeholder='Product Name'
            value={firstInput}
            onChange={e => setFirstInput(e.target.value)} />
        </Grid.Column>
        <Grid.Column width={4}>
          <Dropdown
            style={{width: '100%'}}
            placeholder='Delivery At'
            selection
            clearable
            options={deliveryAtData}
            defaultValue={thirdInput}
            onChange={(e, { value }) => setThirdInput(value)}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Dropdown
            style={{width: '100%'}}
            placeholder='Product Category'
            selection
            clearable
            options={productCategoryList}
            defaultValue={secondInput}
            onChange={(e, { value }) => getDropdownText(value)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>

  const conditionalRowStyles = [
    {
      when: row => row.ProdStatus === 'Inactive',
      style: {
        backgroundColor: '#f3f3f3',
        color: 'rgb(132 132 132 / 87%)'
      },
    }
  ]
  const columns = [
    {
      name: 'Product Name',
      selector: 'ProductName',
      sortable: true,
    },
    {
      name: 'Delivery At',
      selector: 'AvailableLoc',
      cell: row => row.AvailableLoc === "O" ? "Outside Kolkata" : row.AvailableLoc === "K" ? "Kolkata" : "Both",
      sortable: true,
    },
    {
      name: 'Category Name',
      selector: 'Category',
      sortable: true,
    },
    {
      name: <Table basic='very' className="headerTable" >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={4}
              style={{
                color: "#FFFFFF",
                paddingBottom: 0,
                borderBottom: 0,
                textAlign: "center"
              }} >
              Qty
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              style={{
                color: "#FFFFFF",
                paddingBottom: 0,
                borderBottom: 0,
                textAlign: "center"
              }}>
              UOM
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              style={{
                color: "#FFFFFF",
                paddingBottom: 0,
                borderBottom: 0
              }} textAlign="center">
              Rate
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              style={{
                color: "#FFFFFF",
                paddingBottom: 0,
                borderBottom: 0
              }} textAlign="center">
              Wt.
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>,
      cell: (data) =>
        <Table basic='very' celled>
          <Table.Body>
            {data.productRate && data.productRate.map((item, index) =>
              <Table.Row key={index}>
                <Table.Cell width={4} textAlign="center">{item.PackQty}</Table.Cell>
                <Table.Cell width={4} textAlign="center">{item.UOM}</Table.Cell>
                <Table.Cell width={4} textAlign="center">{item.ProdRate}</Table.Cell>
                <Table.Cell width={4} textAlign="center">{item.WtInKG}</Table.Cell>
              </Table.Row>
            )
            }
          </Table.Body>
        </Table>,
      width: '300px'
    },
    {
      name: 'Image',
      selector: 'CatImagePath',
      sortable: true,
      grow: 0,
      cell: row => {
        const image = row.prodimage.find(item => item.DefImage === true)
        if (row.prodimage.length > 0) {
          return <Popup
            content={<img height="100px" width="100px" alt={image ? image.ImageName : "ImageName"} src={image ? IMAGE_URL + image.ImageName : noImage} />}
            trigger={<img height="50px" width="50px" alt={image ? image.ImageName : "ImageName"} src={image ? IMAGE_URL + image.ImageName : noImage} />}
          />
        }
      },
      width: '100px'
    },
    {
      name: 'Action',
      sortable: false,
      right: true,
      cell: (data) => <React.Fragment>
        <Button.Group basic size='small'>
          {rollPermission && rollPermission.Candelete &&
            <Popup
              trigger={
                <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.ProductID), setActionMode('Delete'))}>
                  <Icon style={{ color: 'red' }} name='trash alternate outline' />
                </Button>}
              content='Delete'
              position='top right'
            />}
          {rollPermission && rollPermission.Canedit &&
            <React.Fragment>
              {data.ProdStatus === 'Inactive' ? <Popup
                trigger={
                  <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.ProductID), setActionMode('ReActive'))}>
                    <Icon style={{ color: '#ff8d00' }} name='lock' />
                  </Button>}
                content='Make Active'
                position='top right'
              /> : <Popup
                trigger={
                  <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.ProductID), setActionMode('StatusInactive'))}>
                    <Icon style={{ color: '#ff8d00' }} name='lock open' />
                  </Button>}
                content='Make Inactive'
                position='top right'
              />}
              <Popup
                trigger={
                  // <Button icon onClick={() => (setRowData(data), setOpenAddModal(true), setActionMode('Edit'))}>
                  <Button icon onClick={() => history.push(`/Product-Save/${data.ProductID}`)}>
                    <Icon style={{ color: '#001fff' }} name='edit outline' />
                  </Button>}
                content='Edit'
                position='top right'
              />
            </React.Fragment>}
        </Button.Group>
      </React.Fragment>,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: 'rgb(33 133 208)',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '700',
      },
    }
  }

  return (
    <div>
      <Container fluid>
        <DataTable
          title='Product Master'
          columns={columns}
          customStyles={customStyles}
          data={filteredItems}
          actions={actions}
          pagination={true}
          paginationResetDefaultPage={true}
          subHeader
          subHeaderComponent={openFilter ? advanceSearchComponent : searchComponent}
          conditionalRowStyles={conditionalRowStyles}
          persistTableHead
          progressPending={isLoading}
          progressComponent={<AppLoader />}
        />
        <Confirm
          header='Are you sure?'
          content={
            actionMode === 'Delete' ? 'Data will lost' :
              actionMode === 'StatusInactive' ? 'Data will inactive.' :
                actionMode === 'ReActive' ? 'Data will active?' : null
          }
          open={openDeleteConfirm}
          onCancel={() => setOpenDeleteConfirm(false)}
          onConfirm={() => { dispatch(statusProduct(rowId, setOpenDeleteConfirm, actionMode, authToken)) }}
          size='mini'
        />
      </Container>
    </div>
  )
}

export default ProductMaster