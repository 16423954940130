const initialState = {
   productRatePayload: []
};

export default function (state = initialState, action) {
   switch (action.type) {
      case 'GET_CATEGORY': {
         return {
            ...state,
            productCategoryPayload: action.payload
         };
      }
      case 'GET_DISPLAY_CATEGORY': {
         return {
            ...state,
            productDisplayPayload: action.payload
         };
      }
      case 'GET_PRODUCT': {
         return {
            ...state,
            productPayload: action.payload
         };
      }
      case 'GET_PRODUCT_RATE': {
         return {
            ...state,
            productRatePayload: action.payload
         };
      }
      default:
         return state;
   }
}