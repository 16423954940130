import {
   GET_CATEGORY_URL,
   SAVE_CATEGORY_URL,
   STATUS_CATEGORY_URL,
} from '../../Resources/Api';
import axios from "axios";

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getProductCategory(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'product_category_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: GET_CATEGORY_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_CATEGORY',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_category_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'product_category_list',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_category_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'product_category_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'product_category_list'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "product_category_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "product_category_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}

// Save Action
export function saveProductCategory(values, actions, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(values.CatImagePath)
      actions.setSubmitting(true)
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      const formData = new FormData();
      formData.append("Mode", values.Mode);
      formData.append("CategoryID", values.CategoryID);
      formData.append("CategoryName", values.CategoryName);
      formData.append("files", values.CatImagePath);
      // formData.append('CatImagePath', fs.createReadStream(values.CatImagePath));

      // console.log(...formData)

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: SAVE_CATEGORY_URL,
         data: formData
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'product_category_save',
                        msgType: 'success',
                        status: true
                     }
                  })
                  actions.setSubmitting(false)
                  actions.resetForm()
                  if(actionMode === "Edit"){
                     window.location.reload()
                  }
               } else {
                  actions.setSubmitting(false)
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'product_category_save',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "product_category_save",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}


// Delete User
export function statusProductCategory(values, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(actionMode)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'product_category_delete'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: STATUS_CATEGORY_URL,
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'StatusInactive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            CategoryID: values,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_category_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'product_category_delete',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_category_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'product_category_delete',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'product_category_delete',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "product_category_delete",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "product_category_delete",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}
