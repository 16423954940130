import React, { useState } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import logo from '../../Resources/images/logo.jpg'
import { numberFormatPdf } from "../../Components/NumberFormat";

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 15,
        flex: 1
    },
    row: {
        flexDirection: 'row'
    },
    tableHeader: {
        borderWidth: 1,
        borderColor: '#000',
        width: '100%',
        flexDirection: 'row'
    },
    paddedContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    colHalf: {
        width: '50%'
    },
    colThree: {
        width: "30%"
    },
    colTwo: {
        width: "20%"
    },
    textCenter: {
        textAlign: 'center',
        margin: 5
    },
    companyLogo: {
        width: 200,
        alignSelf: 'center'
    },
    textTitle: {
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 4
    },
    textNormal: {
        flex: 1,
        width: '100%',
        fontSize: 9,
        fontFamily: 'Helvetica',
        marginBottom: 4
    },
    textBold: {
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 4
    },
    textRight: {
        flex: 1,
        width: '100%',
        textAlign: 'right',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        width: '100%',
        fontSize: 9,
        fontFamily: 'Helvetica',
        marginBottom: 4
    },
    textSmall: {
        fontSize: 8,
        fontFamily: 'Helvetica'
    },
    addressContailer: {
        padding: 10,
        flex: 1,
        justifyContent: 'center',
        borderLeftWidth: 1,
        height: '100%'
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 10
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: 'auto',
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        justifyContent: 'center',
        minHeight: 22,
        paddingLeft: 5,
        paddingRight: 5
    },
    tableCell: {
        fontSize: 10,
    },
    tableFooter: {
        position: 'absolute',
        bottom: 15,
        left: 15,
    },
    tableFooterBordered: {
        borderWidth: 1,
        borderStyle: 'solid',
        border: 1,
        borderColor: '#000',
        width: '100%',
        height: 190,
        padding: 15
    },
    signatureContainer: {
        paddingLeft: 10,
        paddingRight: 10,
        padding: "10px",
        justifyContent: 'center',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        height: '100%'
    },
    accContainer: {
        paddingLeft: 10,
        paddingRight: 10,
        padding: '10px',
        justifyContent: 'center',
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        height: '100%',
    },
    invCalContainer: {
        paddingLeft: 10,
        paddingRight: 10,
        padding: '10px',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        height: '100%'
    },
    tableGst: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderTopWidth: 1,
        borderRightWidth: 1,
        marginTop: 10,
        marginBottom: 10,
    },
    tableColIGst: {
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        width: "33.33%"
    },
    tableColGst: {
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        padding: 10,
        width: "20%"
    },
    tableCellGst: {
        fontSize: 10,
        textAlign: 'center',
        margin: 10,
        padding: 10
    }
});

const PDFSample = (props) => {
    const { data } = props

    // console.log("Print Imvoice", data?.invGSTDetails);

    return (
        <Document>
            <Page size="A4" orientation="portrait" wrap={ false }>
                <View style={ styles.page }>
                    <View style={ styles.tableHeader }>
                        <View style={ styles.colHalf }>
                            <View style={ { alignItems: 'center', justifyContent: 'center', flex: 1 } }>
                                <Image style={ styles.companyLogo } src={ { uri: logo, method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' } } } />
                                <Text style={ styles.textTitle }>{ data?.CompAddress } { data?.CompCity }</Text>
                                <Text style={ styles.textTitle }>GSTIN: { data?.GSTNo }</Text>
                                <Text style={ styles.textTitle }>{ "MSME: UDYAM-WB-10-0001628" }</Text>
                                <Text style={ styles.textTitle }>FASSAI: { data?.FASSAINo }</Text>
                                <Text style={ styles.textTitle }>{ `( INVOICE FOR E-COMMERCE SALES THROUGH WEBSITE WWW.BALARAMMULLICK.COM AT 3A, PADDAPUKUR ROAD, KOLKATA - 700020 )` }</Text>
                            </View>
                            <View style={ styles.textCenter }>
                                <View style={ styles.row }>
                                    <View style={ styles.colHalf }>
                                        <Text style={ styles.textNormal }><Text style={ styles.textBold }>Invoice #: </Text>{ data?.BillNo }</Text>
                                        <Text style={ styles.textNormal }><Text style={ styles.textBold }>Invoice Date #: </Text>{ moment(data?.BillDate).format('DD-MM-YYYY') }</Text>
                                    </View>
                                    <View style={ styles.colHalf }>
                                        <Text style={ styles.textNormal }><Text style={ styles.textBold }>Order #: </Text>{ data?.OrderNo }</Text>
                                        <Text style={ styles.textNormal }><Text style={ styles.textBold }>Order Date #: </Text>{ moment(data?.OrderDate).format('DD-MM-YYYY') }</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.colHalf }>
                            <View style={ styles.addressContailer }>
                                <View style={ styles.paddedContainer }>
                                    <Text style={ styles.textTitle }>Address</Text>
                                    <Text style={ styles.textNormal }><Text style={ styles.textBold }>Customer Name: </Text>{ data?.ShippingName }</Text>
                                    <Text style={ [styles.textNormal, { marginBottom: 15 }] }><Text style={ styles.textBold }>Customer Address: </Text>{ data?.ShippingAddress }</Text>
                                    <Text style={ styles.textNormal }><Text style={ styles.textBold }>City: </Text>{ data?.ShippingCity }</Text>
                                    <Text style={ styles.textNormal }><Text style={ styles.textBold }>State: </Text>{ data?.ShippingState }</Text>
                                    <Text style={ styles.textNormal }><Text style={ styles.textBold }>Phone: </Text>{ data?.ShippingMobile }</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={ styles.table }>
                        <View style={ styles.tableRow }>
                            <View style={ [styles.tableCol, { width: '8%' }] }>
                                <Text style={ [styles.tableCell, styles.textBold] }>S/N</Text>
                            </View>
                            <View style={ [styles.tableCol, { width: '10%' }] }>
                                <Text style={ [styles.tableCell, styles.textBold] }>HSN Code</Text>
                            </View>
                            <View style={ [styles.tableCol, { width: '30%' }] }>
                                <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'left' }] }>ITEM DESCRIPTIONS</Text>
                            </View>
                            <View style={ [styles.tableCol, { width: '10%' }] }>
                                <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'center' }] }>RATE</Text>
                            </View>
                            <View style={ [styles.tableCol, { width: '10%' }] }>
                                <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'center' }] }>QTY.</Text>
                            </View>
                            { data?.CGSTorIGST === "I" ?
                                <>
                                    <View style={ [styles.tableCol, { width: '20%' }] }>
                                        <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'center' }] }>IGST%</Text>
                                    </View>
                                </> :
                                <>
                                    <View style={ [styles.tableCol, { width: '10%' }] }>
                                        <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'center' }] }>CGST%</Text>
                                    </View>
                                    <View style={ [styles.tableCol, { width: '10%' }] }>
                                        <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'center' }] }>SGST%</Text>
                                    </View>
                                </> }
                            <View style={ [styles.tableCol, { width: '12%' }] }>
                                <Text style={ [styles.tableCell, styles.textBold, { textAlign: 'center' }] }>TOTAL</Text>
                            </View>
                        </View>
                        { data?.InvItemDetails?.map((item, i) =>
                            <View style={ styles.tableRow } key={ i } wrap={ false }>
                                <View style={ [styles.tableCol, { width: '8%' }] }>
                                    <Text style={ styles.tableCell }>{ i + 1 }</Text>
                                </View>
                                <View style={ [styles.tableCol, { width: '10%' }] }>
                                    <Text style={ styles.tableCell }>{ item.HSNCode }</Text>
                                </View>
                                <View style={ [styles.tableCol, { width: '30%' }] }>
                                    <Text style={ [styles.tableCell, { textAlign: 'left' }] }>{ item.ProdDescr }</Text>
                                </View>
                                <View style={ [styles.tableCol, { width: '10%' }] }>
                                    <Text style={ [styles.tableCell, , { textAlign: 'right' }] }>{ numberFormatPdf(item.BasicAmt) }</Text>
                                </View>
                                <View style={ [styles.tableCol, { width: '10%' }] }>
                                    <Text style={ [styles.tableCell, { textAlign: 'center' }] }>{ item.Qty }</Text>
                                </View>
                                { data?.CGSTorIGST === "I" ?
                                    <>
                                        <View style={ [styles.tableCol, { width: '20%' }] }>
                                            <Text style={ [styles.tableCell, { textAlign: 'center' }] }>{ item.CGSTPer + item.SGSTPer }%</Text>
                                        </View>
                                    </> :
                                    <>
                                        <View style={ [styles.tableCol, { width: '10%' }] }>
                                            <Text style={ [styles.tableCell, { textAlign: 'center' }] }>{ item.CGSTPer }%</Text>
                                        </View>
                                        <View style={ [styles.tableCol, { width: '10%' }] }>
                                            <Text style={ [styles.tableCell, { textAlign: 'center' }] }>{ item.SGSTPer }%</Text>
                                        </View>
                                    </> }
                                <View style={ [styles.tableCol, { width: '12%' }] }>
                                    <Text style={ [styles.tableCell, { textAlign: 'right' }] }>{ numberFormatPdf(item.TotalAmt) }</Text>
                                </View>
                            </View>) }
                    </View>
                    {/* <Text style={ { fontSize: 12 } }>Including packaging charges wherever applicable.</Text> */ }

                    <View style={ styles.tableFooter }>
                        <View render={ ({ pageNumber, totalPages }) => (
                            totalPages &&
                            <View styly={ styles.tableFooterBordered }>
                                <View style={ styles.row }>
                                    <View style={ styles.colHalf }>
                                        <View style={ styles.accContainer }>
                                            <View>
                                                { data?.PaymentMode === "" ? <Text></Text> : <Text style={ styles.textNormal }><Text style={ styles.textBold }>Payment Mode: </Text>{ data?.PaymentMode }</Text> }
                                                { data?.DispatchThru === "" ? <Text></Text> : <Text style={ styles.textNormal }><Text style={ styles.textBold }>Dispatch Through: </Text>{ data?.DispatchThru }</Text> }
                                                <Text style={ [styles.textNormal, { marginBottom: 15 }] }><Text style={ styles.textBold }>Rupees (In Word): </Text>
                                                    <Text style={ { fontSize: 9, fontFamily: 'Helvetica' } }>{ data?.RupeesInWord }</Text>
                                                </Text>
                                            </View>
                                            { data?.CGSTorIGST === "I" ?
                                                <View>
                                                    <View style={ styles.tableGst }>
                                                        <View style={ styles.tableRow }>
                                                            <View style={ styles.tableColIGst }>
                                                                <Text style={ styles.tableCell }>Basic</Text>
                                                            </View>
                                                            <View style={ styles.tableColIGst }>
                                                                <View style={ styles.textCenter }>
                                                                    <Text style={ styles.tableCell }>IGST%</Text>
                                                                </View>
                                                            </View>
                                                            <View style={ styles.tableColIGst }>
                                                                <Text style={ styles.tableCell }>IGST Amt.</Text>
                                                            </View>
                                                        </View>
                                                        <View>
                                                            { data?.invGSTDetails?.map((item, i) =>
                                                                <View style={ styles.tableRow } key={ i }>
                                                                    <View style={ styles.tableColIGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.BasicAmt) }</Text>
                                                                    </View>
                                                                    <View style={ styles.tableColIGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.SGSTPer + item.CGSTPer) }</Text>
                                                                    </View>
                                                                    <View style={ styles.tableColIGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.SGSTAmt + item.CGSTAmt) }</Text>
                                                                    </View>
                                                                </View>) }
                                                        </View>
                                                    </View>
                                                </View> :
                                                <View>
                                                    <View style={ styles.tableGst }>
                                                        <View style={ styles.tableRow }>
                                                            <View style={ styles.tableColGst }>
                                                                <Text style={ styles.tableCell }>Basic</Text>
                                                            </View>
                                                            <View style={ styles.tableColGst }>
                                                                <View style={ styles.textCenter }>
                                                                    <Text style={ styles.tableCell }>CGST%</Text>
                                                                </View>
                                                            </View>
                                                            <View style={ styles.tableColGst }>
                                                                <Text style={ styles.tableCell }>SGST%</Text>
                                                            </View>
                                                            <View style={ styles.tableColGst }>
                                                                <Text style={ styles.tableCell }>CGST Amt.</Text>
                                                            </View>
                                                            <View style={ styles.tableColGst }>
                                                                <Text style={ styles.tableCell }>SGST Amt.</Text>
                                                            </View>
                                                        </View>
                                                        <View>
                                                            { data?.invGSTDetails?.map((item, i) =>
                                                                <View style={ styles.tableRow } key={ i }>
                                                                    <View style={ styles.tableColGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.BasicAmt) }</Text>
                                                                    </View>
                                                                    <View style={ styles.tableColGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.CGSTPer) }</Text>
                                                                    </View>
                                                                    <View style={ styles.tableColGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.SGSTPer) }</Text>
                                                                    </View>
                                                                    <View style={ styles.tableColGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.CGSTAmt) }</Text>
                                                                    </View>
                                                                    <View style={ styles.tableColGst }>
                                                                        <Text style={ styles.textRight }>{ numberFormatPdf(item.SGSTAmt) }</Text>
                                                                    </View>
                                                                </View>) }
                                                        </View>
                                                    </View>
                                                </View> }
                                            <Text style={ styles.textTitle }>THANK YOU</Text>
                                        </View>
                                    </View>
                                    <View style={ styles.colHalf }>
                                        <View style={ styles.invCalContainer }>
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Basic Amount: </Text>
                                                <Text style={ styles.textRight }>{ numberFormatPdf(data?.BasicAmt) }
                                                </Text>
                                            </View>
                                            { data?.CGSTorIGST === "I" ?
                                                <View style={ styles.row }>
                                                    <Text style={ styles.textBold }>IGST: </Text>
                                                    <Text style={ styles.textRight }>{ numberFormatPdf(data?.SGSTAmt + data?.CGSTAmt) }
                                                    </Text>
                                                </View> :
                                                <View>
                                                    <View style={ styles.row }>
                                                        <Text style={ styles.textBold }>SGST: </Text>
                                                        <Text style={ styles.textRight }>{ numberFormatPdf(data?.SGSTAmt) }</Text>
                                                    </View>
                                                    <View style={ styles.row }>
                                                        <Text style={ styles.textBold }>CGST: </Text>
                                                        <Text style={ styles.textRight }>{ numberFormatPdf(data?.CGSTAmt) }</Text>
                                                    </View>
                                                </View> }
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Delivery Charges <Text style={ { fontSize: 8, fontFamily: 'Helvetica', marginBottom: 3, marginTop: -2 } }>{ `(HSN Code:${data?.ShippingHSN})` }</Text>: </Text>
                                                <Text style={ styles.textRight }>{ numberFormatPdf(data?.ShippingCharge) }</Text>
                                            </View>
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Delivery GST: </Text>
                                                <Text style={ styles.textRight }>{ numberFormatPdf(data?.ShippingGST) }</Text>
                                            </View>
                                            <Text style={ { fontSize: 8, fontFamily: 'Helvetica', marginBottom: 3, marginTop: -2 } }>{ data?.CGSTorIGST === "I" ? `(IGST(${numberFormatPdf(data?.ShippingGSTPer)}%) - ${numberFormatPdf(data?.ShippingGST)}` : `(CGST(${numberFormatPdf(data?.ShippingGSTPer / 2)}%) - ${numberFormatPdf((data?.ShippingGST) / 2)} + SGST(${numberFormatPdf(data?.ShippingGSTPer / 2)}%) - ${numberFormatPdf((data?.ShippingGST) / 2)})` }</Text>
                                            { data?.PackingCharge > 0 ? <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Packing Charges <Text style={ { fontSize: 8, fontFamily: 'Helvetica', marginBottom: 3, marginTop: -2 } }>{ `(HSN Code:${data?.PackingHSN})` }</Text>: </Text>
                                                <Text style={ styles.textRight }>{ numberFormatPdf(data?.PackingCharge) }</Text>
                                            </View> : <View></View> }
                                            { data?.PackingCharge > 0 ? <View>
                                                <View style={ styles.row }>
                                                    <Text style={ styles.textBold }>Packing GST: </Text>
                                                    <Text style={ styles.textRight }>{ numberFormatPdf(data?.PackingGST) }</Text>
                                                </View>
                                                <Text style={ { fontSize: 8, fontFamily: 'Helvetica', marginBottom: 3, marginTop: -2 } }>{ data?.CGSTorIGST === "I" ? `(IGST(${numberFormatPdf(data?.PackingGSTPer)}%) - ${numberFormatPdf(data?.PackingGST)}` : `(CGST(${numberFormatPdf(data?.PackingGSTPer)}%) - ${numberFormatPdf((data?.PackingGST) / 2)} + SGST(${numberFormatPdf(data?.PackingGSTPer)}%) - ${numberFormatPdf((data?.PackingGST) / 2)})` }</Text>
                                            </View> : <View></View> }
                                            {/* {data?.CGSTorIGST === "I" ?
                                                <View>
                                                    <View style={styles.row}>
                                                        <Text style={styles.textBold}>Delivery IGST: </Text>
                                                        <Text style={styles.textRight}>{numberFormatPdf(data?.ShippingGST)}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text style={styles.textBold}>IGST: </Text>
                                                        <Text style={styles.textRight}>{numberFormatPdf(data?.SGSTAmt + data?.CGSTAmt)}
                                                        </Text>
                                                    </View>
                                                </View> :
                                                <View>
                                                    <View style={styles.row}>
                                                        <Text style={styles.textBold}>Delivery CGST: </Text>
                                                        <Text style={styles.textRight}>{numberFormatPdf(data?.ShippingGST / 2)}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text style={styles.textBold}>Delivery SGST: </Text>
                                                        <Text style={styles.textRight}>{numberFormatPdf(data?.ShippingGST / 2)}</Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text style={styles.textBold}>SGST: </Text>
                                                        <Text style={styles.textRight}>{numberFormatPdf(data?.SGSTAmt)}</Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text style={styles.textBold}>CGST: </Text>
                                                        <Text style={styles.textRight}>{numberFormatPdf(data?.CGSTAmt)}</Text>
                                                    </View>
                                                </View>} */}
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Total Amount: </Text>
                                                <Text style={ styles.textRight }>{ numberFormatPdf(data?.GTotAmount) }</Text>
                                            </View>
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Round Off: </Text>
                                                <Text style={ styles.textRight }>{ numberFormatPdf(data?.Roff) }</Text>
                                            </View>
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Payment Rcvd: </Text>
                                                <Text style={ styles.textRight }>{ data?.PaymentMode !== "KOL COD" ? numberFormatPdf(data?.GTotAmount) : "0.00" }
                                                </Text>
                                            </View>
                                            <View style={ styles.row }>
                                                <Text style={ styles.textBold }>Receivable: </Text>
                                                <Text style={ styles.textRight }>{ data?.PaymentMode === "KOL COD" ? numberFormatPdf(data?.GTotAmount) : "0.00" }
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>) } fixed />
                    </View>
                </View>
            </Page>
        </Document>
    )
};

export default PDFSample