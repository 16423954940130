import React, { useEffect } from 'react'
import {
   Container,
   Button,
   Dropdown,
   Icon,
   Modal,
   Input,
   Popup,
   Grid,
   Label,
   Form
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import AppLoader from "../../../Components/AppLoader";
import { getOrderList } from '../../../Redux/Actions/OrdersAction';
import ExpandedComponent from "./ExpandedComponent";

const Orders = () => {
   const dispatch = useDispatch();
   let location = useLocation();
   const history = useHistory()
   const authToken = localStorage.getItem('accessToken');
   const [searchInput, setSearchInput] = React.useState('');
   const [firstInput, setFirstInput] = React.useState('');
   const [secondInput, setSecondInput] = React.useState('K');
   useEffect(() => {
      dispatch(getOrderList({
         InvoiceID: 0,
         StartDate: startDate,
         EndDate: endDate,
         OrderStatus: "",
         KolOrOutside: secondInput,
         authToken: authToken
      }));
   }, [])


   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   const isLoading = loadingData && loadingData.status ? loadingData.status : false
   const menuList = useSelector(state => state.menuReducer.menuPayload)
   const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)

   const orderData = useSelector(state => state.orderReducer.orderListPayload)

   const filteredItems = orderData?.pdata?.Invoices?.filter(item =>
      item.OrderStatusText.toLowerCase().includes(firstInput.toLowerCase()) &&
      (
         item.ShippingName.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.OrderNo.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.InvoiceNo.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.BillingName.toString().includes(searchInput) ||
         item.BillingMobile.toString().includes(searchInput) ||
         item.BillingPIN.toString().includes(searchInput) ||
         item.BillingCity.toLowerCase().includes(searchInput.toLowerCase())
      ));

   const currentDate = new Date();
   const [startDate, setStartDate] = React.useState(currentDate.setTime(currentDate.getTime() - 29 * 86400000));
   const [endDate, setEndDate] = React.useState(new Date());

   function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
   }
   var orderStatusList = orderData?.pdata?.Invoices?.map(item => item.OrderStatusText).filter(onlyUnique).map((item, i) => {
      return {
         key: i,
         text: item,
         value: item
      }
   });

   const orderStatusall = [{
      key: 0,
      text: "All List",
      value: ""
   }]

   const allOrderStatusList = orderStatusList && [...orderStatusList, ...orderStatusall];

   const searchComponent = <div className="table-search" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', alignItems: 'center' }}>
         <div style={{ width: '25%' }}>
            <DatePicker
               dateFormat="dd-MM-yyyy"
               todayButton="Today"
               selected={startDate}
               showMonthDropdown
               showYearDropdown
               // disabled={isDisable}
               onChange={date => (
                  setStartDate(date),
                  dispatch(getOrderList({
                     InvoiceID: 0,
                     StartDate: date,
                     EndDate: endDate,
                     OrderStatus: "",
                     KolOrOutside: secondInput,
                     authToken: authToken
                  }))
               )}
               customInput={
                  <Input placeholder='DD-MM-YYYY' action={{ icon: 'calendar alternate outline' }} />
               }
            />
         </div>
         <span> To </span>
         <div style={{ width: '25%' }}>
            <DatePicker
               dateFormat="dd-MM-yyyy"
               todayButton="Today"
               selected={endDate}
               showMonthDropdown
               showYearDropdown
               // disabled={isDisable}
               onChange={date => (
                  setEndDate(date),
                  dispatch(getOrderList({
                     InvoiceID: 0,
                     StartDate: startDate,
                     EndDate: date,
                     OrderStatus: "",
                     KolOrOutside: secondInput,
                     authToken: authToken
                  }))
               )}
               customInput={
                  <Input placeholder='DD-MM-YYYY' action={{ icon: 'calendar alternate outline' }} />
               }
            />
         </div>
         <Dropdown
            style={{ width: '25%' }}
            placeholder='Order Status'
            selection
            search
            options={allOrderStatusList}
            defaultValue={firstInput}
            onChange={(e, { value }) => setFirstInput(value)}
         />
         <Dropdown
            style={{ width: '20%' }}
            placeholder='Order Status'
            selection
            search
            options={[
               { key: 0, text: "Kolkata", value: "K" },
               { key: 1, text: "Outside Kolkata", value: "O" },
            ]}
            defaultValue={secondInput}
            onChange={(e, { value }) => {
               setSecondInput(value);
               dispatch(getOrderList({
                  InvoiceID: 0,
                  StartDate: startDate,
                  EndDate: endDate,
                  OrderStatus: "",
                  KolOrOutside: value,
                  authToken: authToken
               }))
            }}
         />
      </div>
      <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />
   </div >

   const columns = [
      {
         name: 'Cust. Name',
         selector: row => row.ShippingName,
         sortable: true,
         width: "15%"
      },
      {
         name: 'Order Date',
         selector: row => moment(row.OrderDate).format('DD-MM-YYYY'),
         sortable: true,
         width: "9%"
      },
      {
         name: 'Order No',
         selector: row => row.OrderNo,
         sortable: true,
         width: "12%"
      },
      {
         name: 'Invoice No',
         selector: row => row.InvoiceNo,
         sortable: true,
         width: "12%"
      },
      {
         name: 'Ord. Vl.',
         selector: row => row.OrderValue,
         right: true,
         sortable: true,
         width: "7%"
      },
      {
         name: 'Payment Status',
         width: "14%",
         cell: (data) => <Label
            content={data.paymentstatustext}
            color={data.OrderStatus === "CO" ?
               'blue' :
               data.OrderStatus === "PR" ?
                  'teal' :
                  data.OrderStatus === "PP" ?
                     'yellow' :
                     data.OrderStatus === "PF" ?
                        'red' :
                        data.OrderStatus === "OD" ?
                           'teal' :
                           data.OrderStatus === "DP" ?
                              'blue' :
                              data.OrderStatus === "DC" ?
                                 'green' :
                                 data.OrderStatus === "OC" ?
                                    'red' : ""}
         />,
         sortable: true,
         right: true,
      },
      {
         name: 'Delivery Status',
         sortable: true,
         width: "14%",
         cell: (data) => <p>{data.OrderStatusText} {data?.AWBNo !== "" && <Popup
            position='top right'
            flowing
            hoverable
            content={data?.AWBNo}
            trigger={<Label circular color="purple">AWB</Label>} />}
         </p>
      },
      {
         name: 'View',
         sortable: false,
         right: true,
         width: "12%",
         cell: (data) => <Button
            color='blue'
            icon='search'
            content='View'
            size="mini"
            onClick={() => history.push(`/OrderDetails/${data.InvoiceID}`)} />,
      },
   ];
   const customStyles = {
      header: {
         style: {
            minHeight: '40px',
         },
      },
      headRow: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            height: '10px',
            minHeight: '40px'
         },
      },
      headCells: {
         style: {
            color: '#FFF',
            fontSize: '15px',
            fontWeight: '700',
            padding: '10px'
         },
      }
   }

   return (
      <div>
         <Container fluid>
            <DataTable
               title='Order'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               pagination={true}
               paginationResetDefaultPage={true}
               subHeader
               subHeaderComponent={searchComponent}
               // conditionalRowStyles={conditionalRowStyles}
               persistTableHead
               expandableRows
               expandableRowsComponent={ExpandedComponent}
               progressPending={isLoading}
               progressComponent={<AppLoader />}
            />
         </Container>
      </div>
   )
}

export default Orders