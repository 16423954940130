import React from "react";
import { Table } from "semantic-ui-react";


const ExpandedComponent = ({ data }) => {
    console.log(data);
    return (
        <>
            <Table basic='very' compact celled collapsing style={{padding: 20, marginLeft: 50}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Product Name</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data && data.FestProducts.map((item, index) =>
                        <Table.Row key={index}>
                            <Table.Cell>{index+1}</Table.Cell>
                            <Table.Cell>{item.festProdName}</Table.Cell>
                            <Table.Cell>{item.festProdID}</Table.Cell>
                            <Table.Cell>{item.festProdID}</Table.Cell>
                        </Table.Row>)}
                </Table.Body>
            </Table>
        </>
    )
}

export default ExpandedComponent