import {
   GET_PRODUCT_RATE_URL,
   SAVE_PRODUCT_RATE_URL
} from '../../Resources/Api';
import axios from "axios";
import moment from 'moment'
import { toast } from 'react-toastify';

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getRateListAction(currentDate, authToken, setProductData, setIsLoading, setSkipPageReset) {
   return async function (dispatch) {
      setIsLoading(true)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            string: 'product_rate_list',
            status: true
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      // console.log("currentDate", currentDate)

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L',
            EffDate: currentDate,
            fk_TaxonomyID: 2
         },
         url: GET_PRODUCT_RATE_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            if (response.status === 200) {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'product_rate_list'
                  }
               })

               setIsLoading(false)
               // if (response.data.length > 0) {
               setSkipPageReset(false)
               setProductData(response.data)
               dispatch({
                  type: 'GET_PRODUCT_RATE',
                  payload: response.data
               })
               dispatch({
                  type: 'message',
                  payload: {
                     message: false,
                     string: 'product_rate_list',
                     msgType: 'success'
                  }
               })
               // } else {
               //    dispatch({
               //       type: 'message',
               //       payload: {
               //          message: "No reord found",
               //          string: 'product_rate_list',
               //          msgType: 'error'
               //       }
               //    })
               // }
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "product_rate_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            setIsLoading(false)
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "product_rate_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}


// Save Product Rate
export function saveAction(formatedData, setIsLoading, setProductData, setSkipPageReset, setActionMode, currentDate, actionMode, authToken) {
   return async function (dispatch) {
      setIsLoading(true)
      // console.log(formatedData)
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      const formData = new FormData()
      formData.append('Mode', actionMode === "Edit" ? "E" : "A");
      formData.append('fk_TaxonomyID', 2);
      formData.append('json', JSON.stringify(formatedData))

      for (let [key, value] of formData.entries()) {
         console.log(`${key}: ${value}`);
      }

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: SAVE_PRODUCT_RATE_URL,
         data: formData
      })
         .then(function (response) {
            // console.log('Product Rate Save Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  setSkipPageReset(false)
                  dispatch(getRateListAction(currentDate, authToken, setProductData, setIsLoading, setSkipPageReset))
                  toast.success("Rate saves successfully.")
                  setIsLoading(false)
                  setActionMode('')
                  // window.location.reload()

               } else {
                  setIsLoading(false)
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_save',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            }
         })
         .catch(function (error) {
            setIsLoading(false)
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_save",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}

