import React, { useState } from 'react'
import {
  Grid,
  Icon,
  Menu,
  Sidebar,
  Accordion
} from 'semantic-ui-react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../Resources/images/logo.jpg';
import MenuLoader from "./MenuLoader";
import _ from "lodash";

const AppSidebar = (props) => {

  const location = useLocation();
  const { animation, direction, visible, routes } = props
  const [activeMenu, setActiveMenu] = useState('')
  const [activeSubMenu, setActiveSubMenu] = useState('')
  const loadingData = useSelector(state => state.loadingReducer.loadingMenuPayload)
  const isLoading = loadingData && loadingData.string === "admin_menu" ? loadingData.status : false


  const handleMenuActive = (e, subIndex) => {
    const index = subIndex
    const newIndex = activeMenu === index ? -1 : index
    setActiveMenu(newIndex)
    // localStorage.removeItem('subMenuIndex')
  }

  const subMenuIndex = localStorage.getItem('subMenuIndex');


  return (
    <Sidebar
      as={Menu}
      animation={animation}
      direction={direction}
      icon='labeled'
      inverted
      vertical
      visible={visible}
      width={'very wide'}
      className="app-sidebar">
      <div className="sidebar-header">
        <img src={logo} height={50} />
      </div>
      {isLoading ?
        <MenuLoader /> :
        <div className="sidebar-content">
          {routes && routes.map((route, index) =>
            <React.Fragment key={index}>
              {route.sub.length <= 0 &&
                <Menu.Item
                  as={NavLink}
                  to={route.Navigation}
                  onClick={() => (localStorage.removeItem('subMenuIndex'), setActiveMenu(''))}
                  index={index}>
                  <Grid.Row className="menu-item main-menu">
                    <Icon className="left-icon" name={route.IconName} />
                    {route.MenuName}
                  </Grid.Row>
                </Menu.Item>
              }
              {route.sub.length > 0 &&
                <Accordion className="sub-menu">
                  <Accordion.Title
                    active={activeMenu === index || JSON.parse(subMenuIndex) === index}
                    onClick={() => handleMenuActive(route.sub, index)}
                  >
                    <Icon className="left-icon" name={route.IconName} />
                    <span>{route.MenuName}</span>
                    <Icon className="right-icon" name='dropdown' />
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeMenu === index || JSON.parse(subMenuIndex) === index}>
                    {route.sub.map((submenu, subIndex) =>
                      <Menu.Item
                        key={subIndex}
                        onClick={()=>localStorage.setItem('subMenuIndex', index)}
                        as={NavLink}
                        to={submenu.Navigation}>
                        <Grid.Row className="menu-item">
                          <Icon name={submenu.SubIcon} className="left-icon" />
                          {submenu.SubMenu}
                        </Grid.Row>
                      </Menu.Item>
                    )}
                  </Accordion.Content>
                </Accordion>}
            </React.Fragment>
          )}
        </div>}
      <div className="sidebar-footer">
        <div className="sidebar-straight-line"></div>
        <Icon name='chevron circle left' size='large' />
      </div>
    </Sidebar >
  )
}


export default React.memo(AppSidebar)