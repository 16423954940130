import React from 'react';

const CMSPages = () => {

    return (
      <view>
        <h1> CMS Pages </h1>
      </view>
    );
  }


export default CMSPages