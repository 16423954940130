export default function (state = {}, action) {
   switch (action.type) {

      case 'GET_ORDER_LIST':
         {
            return {
               ...state,
               orderListPayload: action.payload
            };
         }
      case 'GET_ORDER_DETAILS':
         {
            return {
               ...state,
               orderDetailPayload: action.payload
            };
         }
      case 'GET_DeleveryAgentList':
         {
            return {
               ...state,
               deleveryAgentListPayload: action.payload
            };
         }
      case 'GET_DispatchList':
         {
            return {
               ...state,
               dispatchListPayload: action.payload
            };
         }
      default:
         return state;
   }
}
