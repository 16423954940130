import React from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import moment from 'moment'

const AppFooter = () => {
   return (
      <React.Fragment>
         <Menu secondary className="app-footer">
            <Menu.Item>Balaram Mullick <Icon name='copyright outline' size='small' /> {moment().format('YYYY')}</Menu.Item>
            <Menu.Menu position='right'>
               <Menu.Item>
                  <a href="http://europia.co.in/" target="_blank">Europia Aces Pvt. Ltd.</a>
               </Menu.Item>
            </Menu.Menu>
         </Menu>
      </React.Fragment>
   )
}

export default AppFooter