import {
   MENU_URL,
} from '../../Resources/Api';
import axios from "axios";

// const authToken = localStorage.getItem('accessToken');

// Get Menu
export function menuAction(authToken) {

   return async (dispatch) => {
      dispatch({
         type: 'LOADING_STATUS_MENU',
         payload: {
            status: true,
            string: 'admin_menu'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })
      
      // console.log("menuAction====", authToken)

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: MENU_URL,
      })
         .then(function (response) {
            // console.log("menuAction", response)
            if (response.status === 200) {
               dispatch({
                  type: 'ADMIN_MENU',
                  payload: response.data.data.Menuitems
               })
               dispatch({
                  type: 'LOADING_STATUS_MENU',
                  payload: {
                     status: false,
                     string: 'admin_menu'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: false,
                     string: 'admin_menu'
                  }
               })
            } else {
               dispatch({
                  type: 'LOADING_STATUS_MENU',
                  payload: {
                     status: false,
                     string: 'admin_menu'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "admin_menu",
                     msgType: 'error'
                  }
               })
            }

         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS_MENU',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "email_login",
                  msgType: 'error',
               }
            })
            console.log(error);
         });
   }
}