import React from 'react'
import { Form, Segment, Grid, Dropdown, Radio, Header, Icon, Button, Card, Popup, Divider, Message, Input, Select, Table } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux';
import { getProductCategory } from '../../Redux/Actions/ProductCategoryAction'
import { getDisplayCategory } from '../../Redux/Actions/DesplayCategoryAction'
import { saveProduct, getProduct } from '../../Redux/Actions/ProductAction'
import { getTaxClass } from '../../Redux/Actions/TaxClassAction'
import { getTaxonomyList } from '../../Redux/Actions/TaxonomyAction'
import { Formik } from 'formik'
import * as Yup from 'yup';
import ImageUploading from 'react-images-uploading';
import { Link, useParams } from "react-router-dom";
import { clearMessage } from '../../Redux/Actions/ButtonAction'
import { IMAGE_URL } from '../../Resources/Api';
import { useState } from 'react';
import { toast } from 'react-toastify';

const ProductForm = (props) => {

   let { paramId } = useParams();
   const dispatch = useDispatch()
   const authToken = localStorage.getItem('accessToken');

   React.useEffect(() => {
      if (paramId && paramId > 0) {
         dispatch(getProduct(authToken, paramId, setImages, setCurrentImage, setPackingSchema))
      }
      dispatch(getProductCategory(authToken))
      dispatch(getDisplayCategory(authToken))
      dispatch(getTaxClass(authToken))
      dispatch(getTaxonomyList(authToken))
   }, [])

   const productList = useSelector(state => state.productReducer.productPayload)
   const messageData = useSelector(state => state.messageReducer.messagePayload);
   const messageStatus = messageData && messageData.string === 'product_save' ? messageData.status : false
   // console.log(productList)
   setTimeout(() => {
      dispatch(clearMessage())
   }, 5000)

   // const taxonomyData = useSelector(state => state.taxonomyReducer.taxonomyListPayload)
   // const taxonomyList = taxonomyData && taxonomyData.map((item) => {
   //    return {
   //       key: item.TaxonomyID,
   //       text: item.Title,
   //       value: item.TaxonomyID
   //    }
   // })
   const taxClassData = useSelector(state => state.taxClassReducer.taxClassListPayload)
   const taxClassList = taxClassData && taxClassData.map((item) => {
      return {
         key: item.TaxClassID,
         text: item.CGSTPer + item.SGSTPer + '%',
         value: item.TaxClassID,
      }
   })

   const productCategoryData = useSelector(state => state.productReducer.productCategoryPayload)
   const productCategoryList = productCategoryData && productCategoryData.filter(item => item.CatStatus === 'Active').map(data => {
      return {
         key: data.CategoryID,
         text: data.CategoryName,
         value: data.CategoryID
      }
   })
   const productDisplayData = useSelector(state => state.productReducer.productDisplayPayload)
   const productDisplayList = productDisplayData && productDisplayData.map(data => {
      return {
         key: data.CategoryID,
         text: data.CategoryName,
         value: data.CategoryID
      }
   })

   const productSchema = Yup.object().shape({
      CategoryID: Yup.string()
         .required('Category is required'),
      TaxonomyID: Yup.string()
         .required('Taxonomy is required'),
      ProductName: Yup.string()
         .min(5, 'Product name is too short!')
         .max(50, 'Product name is too Long!')
         .required('Product name is required'),
      ProductDescription: Yup.string(),
      // .min(20, 'Product description is too short!')
      // .max(500, 'Product description is too Long!')
      // .required('Product description is required'),
      DeliveryInformation: Yup.string(),
      // .min(2, 'User name is too short!')
      // .max(50, 'User name is too Long!')
      // .required('User name is required'),
      HSNCode: Yup.string()
         .required('User type is required'),
      SKU: Yup.string()
         .required('User type is required'),
      // WeightPUnitInKG: Yup.string()
      //    .required('User type is required'),
      // ShelfLifeDays: Yup.string()
      //    .required('User type is required'),
      // AllowBulk: Yup.string()
      //    .required('Allow bulk is required'),
      CalcMethod: Yup.string()
         .required('Calc method is required'),
      TaxClassID: Yup.string()
         .required('Tax class is required'),
   });


   // console.log(redusedImages)

   const [images, setImages] = React.useState([]);
   const [currentImage, setCurrentImage] = React.useState([]);
   const [defaultImages, setDefaultImages] = React.useState(0);

   // console.log(defaultImages)

   const onChangeImage = (imageList, addUpdateIndex) => {
      // console.log(imageList)
      setImages(imageList);
      // setError({ status: false })
   };


   const defaultImageChange = (e) => {
      setDefaultImages(e)
      // setError({ status: false })
   }

   const [PackQty, setPackQty] = useState('');
   const [UOM, setUOM] = useState('');
   const [WtInKG, setWtInKG] = useState('');
   const [packingSchema, setPackingSchema] = useState([]);
   const [packageError, setPackageError] = useState(false);
   const onNumberOnlyChange = (event: any) => {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);
      const isValid = new RegExp("[0-9]").test(keyValue);
      if (!isValid) {
         event.preventDefault();
         return;
      }
   };

   const handleTableSubmit = () => {
      if (PackQty === "" || UOM === "" || WtInKG === "") {
         setPackageError(true)
      } else {
         setPackQty('');
         setUOM('');
         setWtInKG('');

         setPackingSchema([...packingSchema, {
            ProdSizeID: 0,
            ProdID: 0,
            PackQty: PackQty,
            UOM: UOM,
            WtInKG: WtInKG
         }])
      }
   }
   // console.log("packingSchema", packingSchema);

   const handleRemoveItem = (index) => {
      const itemList = [...packingSchema];
      itemList.splice(index, 1);
      setPackingSchema(itemList);
   };


   return (
      <Segment className="forum-segmont">
         <Formik
            enableReinitialize
            initialValues={paramId && paramId > 0 ? {
               Mode: "E",
               ProductId: productList && paramId,
               ProductName: productList && productList[0].ProductName,
               ProductDescription: productList && productList[0].ProductDescription,
               CategoryID: productList && productList[0].CategoryID,
               TaxonomyID: 2,
               TaxClassID: productList && productList[0].TaxClassID,
               DeliveryInformation: productList && productList[0].DeliveryInformation,
               CareInstruction: productList && productList[0].CareInstruction,
               HSNCode: productList && productList[0].HSNCode,
               CalcMethod: "B",
               SKU: productList && productList[0].SKU,
               WeightPUnitInKG: productList && productList[0].WeightPUnitInKG,
               ShelfLifeDays: productList && productList[0].ShelfLifeDays,
               LinkedProducts: productList && productList[0].LinkedProducts,
               AvailableLoc: productList && productList[0].AvailableLoc,
               ProdDispCatID: productList && productList[0].ProdDispCatID,
               GiftStatus: productList && productList[0].GiftStatus === true ? 1 : 0,
               FestStatus: productList && productList[0].FestStatus === true ? 1 : 0,
               // AllowBulk: productList && productList[0].AllowBulk,
               // ProdRate: productList && productList[0].ProdRate,
            } :
               {
                  Mode: "A",
                  ProductId: 0,
                  TaxonomyID: 2,
                  CategoryID: '',
                  TaxClassID: 1,
                  ProductName: '',
                  ProductDescription: '',
                  DeliveryInformation: '',
                  CareInstruction: '',
                  CalcMethod: 'B',
                  HSNCode: '',
                  SKU: '',
                  WeightPUnitInKG: '',
                  ShelfLifeDays: '',
                  GiftStatus: 0,
                  FestStatus: 0,
                  AvailableLoc: 'K',
                  ProdDispCatID: '',
                  // AllowBulk: '',
                  // ProdRate: '',
                  ImageList: [],
               }
            }
            validationSchema={productSchema}
            onSubmit={(values, actions) => {
               dispatch(saveProduct(
                  values,
                  packingSchema,
                  setPackingSchema,
                  actions,
                  images,
                  setImages,
                  currentImage,
                  defaultImages,
                  authToken,
                  props.history,
                  toast
               ))
            }}
         // onSubmit={(values, actions) => console.log(values)}
         >
            {({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               isSubmitting,
               handleSubmit,
               setFieldValue
            }) => (
               <Grid>
                  <Grid.Row>
                     <Grid.Column width={11}>
                        <Form>
                           <Form.Group>
                              <Form.Field required>
                                 <label>Product Category</label>
                                 <Dropdown
                                    error={errors.CategoryID && touched.CategoryID ? true : false}
                                    options={productCategoryList && productCategoryList}
                                    placeholder='Product Category'
                                    selection
                                    name="CategoryID"
                                    value={values.CategoryID}
                                    onChange={(e, { name, value }) => {
                                       setFieldValue('CategoryID', value)
                                    }}
                                 />
                              </Form.Field>
                              <Form.Field
                                 required
                                 width={11}
                                 error={errors.ProductName && touched.ProductName ? { content: errors.ProductName } : false}
                                 name="ProductName"
                                 label='Product Name'
                                 control='input'
                                 search
                                 value={values.ProductName || ''}
                                 onChange={handleChange('ProductName')}
                              />
                           </Form.Group>
                           <Form.Field
                              error={errors.ProductDescription && touched.ProductDescription ? { content: errors.ProductDescription } : false}
                              name="ProductDescription"
                              label='Product Descriptions'
                              control='textarea'
                              rows='3'
                              value={values.ProductDescription}
                              onChange={handleChange('ProductDescription')}
                           />
                           <Form.Field
                              error={errors.DeliveryInformation && touched.DeliveryInformation ? { content: errors.DeliveryInformation } : false}
                              name="DeliveryInformation"
                              label='Delivery Information'
                              control='input'
                              value={values.DeliveryInformation}
                              onChange={handleChange('DeliveryInformation')}
                           />

                           <Grid columns={3}>
                              <Grid.Row className="grid-row">
                                 <Grid.Column>
                                    <Form.Field required>
                                       <label>Tax Class (%)</label>
                                       <Dropdown
                                          error={errors.TaxClassID && touched.TaxClassID}
                                          placeholder='Tax Class (%)'
                                          selection
                                          name="TaxClassID"
                                          options={taxClassList}
                                          value={values.TaxClassID}
                                          onChange={(e, { name, value }) => {
                                             setFieldValue('TaxClassID', value)
                                          }}

                                       />
                                    </Form.Field>
                                 </Grid.Column>
                                 {/* <Grid.Column>
                                    <Form.Field required>
                                       <label>Calculation Method</label>
                                       <Dropdown
                                          error={errors.CalcMethod && touched.CalcMethod}
                                          placeholder='Calculation Method'
                                          selection
                                          name="CalcMethod"
                                          options={
                                             [{ key: 'F', value: 'F', text: 'Gst Forward Calculation' },
                                             { key: 'B', value: 'B', text: 'Gst Backward Calculation' }]
                                          }
                                          value={values.CalcMethod}
                                          onChange={(e, { value }) => setFieldValue("CalcMethod", value)}
                                       />
                                    </Form.Field>
                                 </Grid.Column> */}
                                 <Grid.Column>
                                    {/* <Form.Group> */}
                                    <Form.Field
                                       required
                                       error={errors.HSNCode && touched.HSNCode ? { content: errors.HSNCode } : false}
                                       name="HSNCode"
                                       label='HSN Code'
                                       control='input'
                                       value={values.HSNCode}
                                       onChange={handleChange('HSNCode')}
                                    />
                                 </Grid.Column>
                              </Grid.Row>
                           </Grid>
                           <Grid>
                              <fieldset className="pack-section">
                                 <legend><b> Package Information <span style={{ color: 'red' }}>* </span></b></legend>
                                 <Grid.Row className="grid-row">
                                    <Grid.Column width={16}>
                                       {packageError &&
                                          <span style={{ color: 'red' }}>Fill all the package information</span>}
                                       <Table basic='very' celled>
                                          <Table.Header>
                                             <Table.Row>
                                                <Table.HeaderCell width={5}>
                                                   <Form.Input
                                                      type="number"
                                                      onKeyPress={onNumberOnlyChange}
                                                      fluid
                                                      placeholder='Pack Qty.'
                                                      value={PackQty}
                                                      onChange={e => (setPackQty(e.target.value), e.target.value.replace(/[^0-9]/g, ""))} />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={5}>
                                                   <Form.Input
                                                      fluid
                                                      placeholder='Unit'
                                                      value={UOM}
                                                      onChange={e => setUOM(e.target.value)} />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={5}>
                                                   <Input
                                                      type="number"
                                                      fluid
                                                      label={{ basic: true, content: 'Kg' }}
                                                      labelPosition='right'
                                                      placeholder='Total Pack Weight'
                                                      value={WtInKG}
                                                      onChange={e => setWtInKG(e.target.value)} />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                   <Button icon positive onClick={handleTableSubmit}>
                                                      <Icon name='check' />
                                                   </Button>
                                                </Table.HeaderCell>
                                             </Table.Row>
                                             <Table.Row>
                                                <Table.HeaderCell>Pack Qty</Table.HeaderCell>
                                                <Table.HeaderCell>UOM</Table.HeaderCell>
                                                <Table.HeaderCell>Total Pack Weight</Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>#</Table.HeaderCell>
                                             </Table.Row>
                                          </Table.Header>
                                          <Table.Body>
                                             {packingSchema.length > 0 && packingSchema.map((item, index) =>
                                                <Table.Row key={index}>
                                                   <Table.Cell>{item.PackQty}</Table.Cell>
                                                   <Table.Cell>{item.UOM}</Table.Cell>
                                                   <Table.Cell>{item.WtInKG}</Table.Cell>
                                                   <Table.Cell width={2} textAlign='center'>
                                                      <Link to="#" onClick={() => handleRemoveItem(index)}>
                                                         <Icon name="times" color="red" size="small" />
                                                      </Link>
                                                   </Table.Cell>
                                                </Table.Row>)}
                                          </Table.Body>
                                       </Table>
                                    </Grid.Column>
                                 </Grid.Row>
                              </fieldset>
                           </Grid>
                           <Grid columns={3}>
                              <Grid.Row className="grid-row">
                                 {/* <Grid.Column>
                                       <Form.Field
                                          error={errors.WeightPUnitInKG && touched.WeightPUnitInKG ? { content: errors.WeightPUnitInKG } : false}
                                          name="WeightPUnitInKG"
                                          label='Weight Per Unit in KG'
                                          control='input'
                                          value={values.WeightPUnitInKG}
                                          onChange={handleChange('WeightPUnitInKG')}
                                       />
                                    </Grid.Column> */}
                                 <Grid.Column>
                                    <Form.Field
                                       required
                                       error={errors.SKU && touched.SKU ? { content: errors.SKU } : false}
                                       name="SKU"
                                       label='Stock Keeping Unit(SKU)'
                                       control='input'
                                       value={values.SKU}
                                       onChange={handleChange('SKU')}
                                    />
                                 </Grid.Column>
                                 <Grid.Column>
                                    <Form.Field
                                       error={errors.CareInstruction && touched.CareInstruction ? { content: errors.CareInstruction } : false}
                                       name="CareInstruction"
                                       label='Care Instruction'
                                       control='input'
                                       value={values.CareInstruction}
                                       onChange={handleChange('CareInstruction')}
                                    />
                                 </Grid.Column>
                                 <Grid.Column>
                                    <Form.Field required>
                                       <label>Available Location</label>
                                       <Dropdown
                                          error={errors.AvailableLoc && touched.AvailableLoc}
                                          placeholder='Available Location'
                                          selection
                                          name="AvailableLoc"
                                          options={
                                             [{ key: 'K', value: "K", text: 'Kolkata' },
                                             { key: 'O', value: "O", text: 'Outside Kolkata' },
                                             { key: 'B', value: "B", text: 'Both' }]
                                          }
                                          value={values.AvailableLoc}
                                          onChange={(e, { value }) => setFieldValue("AvailableLoc", value)}
                                       />
                                    </Form.Field>
                                 </Grid.Column>
                              </Grid.Row>
                           </Grid>
                           <Grid>
                              <Grid.Row>
                                 <Grid.Column width={4}>
                                    <label><b>Festival Status</b></label>
                                    <Form.Group>
                                       <Form.Radio
                                          label='Yes'
                                          value={1}
                                          name="FestStatus"
                                          checked={values.FestStatus === 1}
                                          onChange={() => setFieldValue("FestStatus", 1)}
                                       />
                                       <Form.Radio
                                          label='No'
                                          value={0}
                                          name="FestStatus"
                                          checked={values.FestStatus === 0}
                                          onChange={() => setFieldValue("FestStatus", 0)}
                                       />
                                    </Form.Group>
                                 </Grid.Column>
                                 <Grid.Column width={12}>
                                    <Form.Field required>
                                       <label>Display Category</label>
                                       <Dropdown
                                          error={errors.ProdDispCatID && touched.ProdDispCatID}
                                          placeholder='Available Location'
                                          selection
                                          name="ProdDispCatID"
                                          options={productDisplayList}
                                          value={values.ProdDispCatID}
                                          onChange={(e, { value }) => setFieldValue("ProdDispCatID", value)}
                                       />
                                    </Form.Field>
                                 </Grid.Column>
                              </Grid.Row>
                           </Grid>
                        </Form>
                     </Grid.Column>
                     <Grid.Column width={5}>
                        <div className="App">
                           <ImageUploading
                              multiple
                              value={images}
                              name="files"
                              onChange={onChangeImage}
                              maxNumber={1}
                              maxFileSize={409600}
                              dataURLKey="ImageName"
                              acceptType={['jpg', 'png', 'jpeg']}
                           >
                              {({
                                 imageList,
                                 onImageUpload,
                                 onImageRemoveAll,
                                 onImageUpdate,
                                 onImageRemove,
                                 isDragging,
                                 dragProps,
                                 errors
                              }) => (
                                 <div className="upload__image-wrapper">
                                    <div onClick={onImageUpload} {...dragProps}>
                                       <Segment textAlign='center' color='blue' className="">
                                          <Header icon>
                                             <Icon name='images outline' />
                                             Click or Drop here.
                                             <h5 style={{ marginTop: 0 }}>(Image Size - 800x530)</h5>
                                          </Header>
                                       </Segment>
                                    </div>
                                    {errors && <div>
                                       {errors.maxNumber && <span>Maximum 1 image can choose</span>}
                                       {errors.acceptType && <span>Only .png/.jpg/.jpeg image are allowed</span>}
                                       {errors.maxFileSize && <span>Selected image size exceed 400kb</span>}
                                    </div>}
                                    {imageList.length > 0 &&
                                       <Grid>
                                          <Grid.Row>
                                             <Grid.Column floated='right'>
                                                <Button onClick={onImageRemoveAll} style={{ float: 'right', marginTop: 20 }} color='red'>
                                                   <Icon name="times circle" />Clear all</Button>
                                             </Grid.Column>
                                          </Grid.Row>
                                       </Grid>
                                    }
                                    <Grid divided='vertically'>
                                       <Grid.Row columns={2}>
                                          {/* <Card.Group> */}
                                          {imageList.map((image, index) => (
                                             <Grid.Column key={index}>
                                                <Card>
                                                   <img
                                                      src={image.Existing === true ? IMAGE_URL + image['ImageName'] : image['ImageName']}
                                                      height={100}
                                                   />
                                                   <Card.Content extra>
                                                      <Grid>
                                                         <Grid.Row>
                                                            <Button.Group basic size='small' className="align-self-center">
                                                               <Popup
                                                                  content='Update'
                                                                  position='top left'
                                                                  trigger={
                                                                     <Button onClick={() => onImageUpdate(index)} icon>
                                                                        <Icon style={{ color: 'blue' }} name='upload' />
                                                                     </Button>
                                                                  } />
                                                               <Popup
                                                                  content='Delete'
                                                                  position='top right'
                                                                  trigger={
                                                                     <Button onClick={() => onImageRemove(index)} icon>
                                                                        <Icon style={{ color: 'red' }} name='trash alternate outline' />
                                                                     </Button>
                                                                  } />
                                                            </Button.Group>
                                                            <Form.Field className="align-self-center" style={{ marginTop: 8 }}>
                                                               <Radio
                                                                  label='Default'
                                                                  value={index}
                                                                  name="defaultImages"
                                                                  defaultChecked={image.DefImage}
                                                                  checked={defaultImages === index}
                                                                  onChange={(e, { value }) => defaultImageChange(value)}
                                                               />
                                                               {/* <p>{JSON.stringify(image.DefImage)}</p> */}
                                                            </Form.Field>
                                                         </Grid.Row>
                                                      </Grid>
                                                   </Card.Content>
                                                </Card>
                                             </Grid.Column>
                                          ))}
                                       </Grid.Row>
                                    </Grid>
                                 </div>
                              )}
                           </ImageUploading>
                        </div>
                     </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  {messageStatus && <Message error header={messageData.message} style={{ float: 'right' }} />}
                  <div style={{ width: '100%' }}>
                     <Button
                        positive
                        type="submit"
                        style={{ float: 'right' }}
                        loading={isSubmitting}
                        onClick={() => {
                           images <= 0 ?
                              toast.error("Please upload at list one image") :
                              defaultImages === undefined ?
                                 toast.error("Please set the default image") :
                                 packingSchema <= 0 ?
                                    toast.error("Please fill at list one package information") :
                                    handleSubmit()
                        }}>
                        <Icon name="save" /> Save Product
                     </Button>
                     <Button
                        negative
                        style={{ float: 'right' }}
                        onClick={() => (props.history.push('/Product'), getProduct(authToken, 0))}
                     >
                        <Icon name="times circle" /> Cancel
                     </Button>
                  </div>
               </Grid>
            )}
         </Formik>

      </Segment>
   )
}

export default ProductForm