import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Segment, Image, Button, Grid } from 'semantic-ui-react'
import logoImage from '../../Resources/images/square-image.png'

const ForgotPassword = () => {

   return (
      <Segment className="login-body" placeholder>
         <div className="login-form-content">
            <Image className="login-logo" src={logoImage} size='small' circular />
            <Form>
               <Form.Input
                  fluid
                  label='Email'
                  placeholder='example@domain.com'
                  id='form-input-first-name'
               />
               <Grid.Row className="forgon-button-container">
                  <Link to='/login'>Back to login</Link>
                  <Button size='large' className="login-button" positive>
                     <i aria-hidden="true" class="check icon"></i>
                  Submit
                  </Button>
               </Grid.Row>
            </Form>
         </div>
      </Segment>
   )
}

export default ForgotPassword