import {
   LOGIN_URL,
   LOGOUT_URL,
   REGISTER_URL,
   EMAIL_VALIDATION,
   GET_PROFILE_URL,
   EDIT_PROFILE_URL,
   CHANGE_PASSWORD_URL,
   OTP_VERIFICATION_URL,
   FORGOT_PASSWORD_URL,
   FORGOT_PASSWORD_RESET_URL
} from '../../Resources/Api';
import axios from "axios";
import { toast } from 'react-toastify';

// Login
export function loginAction(values, history) {

   return async (dispatch) => {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'admin_login'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      const hashedPassword = Buffer.from(`bm_secKey/2020/*${values.Password}`).toString('base64');

      axios({
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: `${LOGIN_URL}?UserName=${values.UserName}&Password=${hashedPassword}`,
      })
         .then(function (response) {
            if (response.status === 200) {
               if (response.data.Status > 0) {
                  // console.log(response.data.RoleName);
                  localStorage.setItem('userData', JSON.stringify(response.data))
                  localStorage.setItem('accessToken', response.data.token);
                  localStorage.setItem('userRoleName', response.data.RoleName);
                  dispatch({
                     type: 'GET_PROFILE',
                     payload: response.data
                  })
                  dispatch({
                     type: 'ACCESS_TOKEN',
                     payload: response.data.token
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'admin_login'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: false,
                        string: 'admin_login',
                        msgType: 'success'
                     }
                  })
                  history.push('/Home')
                  window.location.reload()
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'admin_login'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        status: true,
                        message: response.data.Message,
                        string: "admin_login",
                        msgType: 'error'
                     }
                  })
               }
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "admin_login",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}

// OTp Verification Action

export function otpVerificationAction(mobile) {
   return async (dispatch) => {
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: {
            status: true,
            data: 'otp_verification'
         }
      })
      dispatch({
         type: 'MULTI_ERROR_MESSAGE',
         payload: {
            message: false,
            data: "otp_verification"
         }
      })

      axios({
         method: 'post',
         url: OTP_VERIFICATION_URL,
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            mobile: mobile,
         }
      })
         .then(function (response) {
            // console.log("OTP Action===", response)
            if (response.data.error === false) {
               dispatch({
                  type: 'MULTI_LOADING_STATUS',
                  payload: {
                     status: false,
                     data: 'otp_verification'
                  }
               })
               dispatch({
                  type: 'SUCCESS_MESSAGE',
                  payload: response.data.data
               })
               dispatch({
                  type: 'OTPINPUT_OPEN',
                  payload: true
               })
               dispatch({
                  type: 'OTP_BUTTON_OPEN',
                  payload: false
               })
               dispatch({
                  type: 'ERROR_MESSAGE',
                  payload: false
               })
               dispatch({
                  type: 'MULTI_ERROR_MESSAGE',
                  payload: {
                     message: false,
                     data: "otp_verification"
                  }
               })
            } else {
               dispatch({
                  type: 'MULTI_LOADING_STATUS',
                  payload: {
                     status: false,
                     data: 'otp_verification'
                  }
               })
               // dispatch({
               //    type: 'ERROR_MESSAGE',
               //    payload: response.data.errorMessage
               // })
               dispatch({
                  type: 'MULTI_ERROR_MESSAGE',
                  payload: {
                     message: response.data.errorMessage,
                     data: "otp_verification"
                  }
               })
            }
         })
         .catch(function (error) {
            console.log(error);
            dispatch({
               type: 'MULTI_LOADING_STATUS',
               payload: {
                  status: false,
                  data: 'otp_verification'
               }
            })
            dispatch({
               type: 'MULTI_ERROR_MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  data: "otp_verification"
               }
            })
            console.log(error);
         });
   }
}

// Log out

export function logoutAction(authToken, history) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: true
      })

      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json",
         "Authorization": 'Bearer ' + authToken
      };
      // console.log(headers)
      axios({
         headers: headers,
         method: 'POST',
         url: LOGOUT_URL,
      })
         .then(function (response) {
            console.log("Logout", response.status)
            if (response.status === 200) {
               dispatch({
                  type: 'ACCESS_TOKEN',
                  payload: null
               })
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: false
               })
               localStorage.clear();
               history.push('/login')
               window.location.reload();
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: false
               })
               dispatch({
                  type: 'ERROR_MESSAGE',
                  payload: response.data.message
               })
            }

         })
         .catch(function (error) {
            // console.log(error.response.status);
            if (error.response.status === 401) {
               localStorage.clear();
               history.push('/login')
               window.location.reload();
            }
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'ERROR_MESSAGE',
               payload: "Server connection failed. Ensure network connection"
            })
            // console.log(error);
         });
   }
}

// Get Profile
export function getProfile(authToken) {
   return async function (dispatch) {
      // console.log(authToken)
      const headers = { Authorization: 'Bearer ' + authToken };
      axios({
         headers: headers,
         method: 'post',
         url: GET_PROFILE_URL,
         responseType: 'json'
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            dispatch({
               type: 'GET_PROFILE',
               payload: response.data
            })
         });
   }
}

// Edit Profile
export function editProfile(name, authToken) {
   // console.log("Edit name===", name)
   return async function (dispatch) {
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: {
            status: true,
            data: 'edit_profile'
         }
      })
      const headers = {
         Authorization: 'Bearer ' + authToken,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
      };

      // console.log(authToken)

      axios({
         headers: headers,
         method: 'post',
         url: EDIT_PROFILE_URL,
         responseType: 'json',
         data: {
            name: name
         }
      })
         .then(function (response) {
            // console.log(response.data.data);
            if (response.data.error === false) {
               dispatch({
                  type: 'GET_PROFILE',
                  payload: response.data.data
               })
               dispatch({
                  type: 'SUCCESS_MESSAGE',
                  payload: response.data.message
               })
               dispatch({
                  type: 'EDIT_PROFILE_NAME_STATUS',
                  payload: false
               })
               dispatch({
                  type: 'MULTI_LOADING_STATUS',
                  payload: {
                     status: false,
                     data: 'edit_profile'
                  }
               })
            } else {
               dispatch({
                  type: 'MULTI_LOADING_STATUS',
                  payload: {
                     status: false,
                     data: 'edit_profile'
                  }
               })
               dispatch({
                  type: 'MULTI_ERROR_MESSAGE',
                  payload: {
                     data: "edit_profile",
                     message: "Server connection failed. Ensure network connection",
                     status: true
                  }
               })
            }
         }).catch(function (error) {
            // if (error.response.status === 401) {
            //    localStorage.clear();
            //    history.push('/login')
            //    window.location.reload();
            // } 
            dispatch({
               type: 'MULTI_LOADING_STATUS',
               payload: {
                  status: false,
                  data: 'edit_profile'
               }
            })
            dispatch({
               type: 'MULTI_ERROR_MESSAGE',
               payload: {
                  data: "edit_profile",
                  message: "Server connection failed. Ensure network connection",
                  status: true
               }
            })
         })
   }
}


// Change Password

export function changePasswordAction(values, actions, history, accessToken) {
   return async (dispatch) => {
      actions.setSubmitting(true);
      const data = {
         Mode: values.Mode,
         OldPw: Buffer.from(`bm_secKey/2020/*${values.OldPw}`).toString('base64'),
         NewPw: Buffer.from(`bm_secKey/2020/*${values.NewPw}`).toString('base64'),
         UserID: values.UserID
      }
      axios({
         method: 'post',
         url: CHANGE_PASSWORD_URL,
         headers: {
            Authorization: 'Bearer ' + accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: data
      })
         .then(function (response) {
            actions.setSubmitting(false);
            // console.log("Reg===", response.data)
            if (response.data.Result > 0) {
               actions.setSubmitting(true);
               actions.resetForm()
               toast.success(`${response.data.Error}. Please login again.`)
               setTimeout(() => {
                  dispatch(logoutAction(accessToken, history))
               }, 3000);
            } else {
               toast.warn(response.data.Error)
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false);
            toast.error('Something went wrong. Please try again')
            console.log(error);
         });
   }
}

//Forgot Password

export function forgotPasswordAction(email, history) {
   return async (dispatch) => {
      dispatch({
         type: 'LOADING_STATUS',
         payload: true
      })

      axios({
         method: 'post',
         url: FORGOT_PASSWORD_URL,
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            email: email
         }
      })
         .then(function (response) {
            // console.log("Reg===", response)
            if (response.data.error === false) {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: false
               })
               dispatch({
                  type: 'SUCCESS_MESSAGE',
                  payload: response.data.data
               })
               dispatch({
                  type: 'ERROR_MESSAGE',
                  payload: false
               })
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: false
               })
               dispatch({
                  type: 'SUCCESS_MESSAGE',
                  payload: false
               })
               dispatch({
                  type: 'ERROR_MESSAGE',
                  payload: response.data.errorMessage
               })
            }
         })
         .catch(function (error) {
            if (error.response.status === 401) {
               localStorage.clear();
               history.push('/login')
               window.location.reload();
            }
            console.log(error);
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'ERROR_MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  status: true
               }
            })
            // console.log(error);
         });
   }
}
//Forgot Password Reset

export function forgotResetAction(password, passwordConfirm, rToken, history) {
   return async (dispatch) => {
      dispatch({
         type: 'LOADING_STATUS',
         payload: true
      })

      axios({
         method: 'post',
         url: FORGOT_PASSWORD_RESET_URL,
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            password: password,
            confirm_password: passwordConfirm,
            token: rToken,
         }
      })
         .then(function (response) {
            // console.log("Reg===", response)
            if (response.data.error === false) {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: false
               })
               dispatch({
                  type: 'SUCCESS_MESSAGE',
                  payload: response.data.data
               })
               dispatch({
                  type: 'ERROR_MESSAGE',
                  payload: false
               })
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: false
               })
               dispatch({
                  type: 'SUCCESS_MESSAGE',
                  payload: false
               })
               dispatch({
                  type: 'ERROR_MESSAGE',
                  payload: response.data.errorMessage
               })
            }
         })
         .catch(function (error) {
            if (error.response.status === 401) {
               localStorage.clear();
               history.push('/login')
               window.location.reload();
            }
            console.log(error);
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'ERROR_MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  status: true
               }
            })
            // console.log(error);
         });
   }
}


