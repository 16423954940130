const initialState = {
   openRegPayload: false,
   openOtpPayload: false,
   openPasswordPayload: false,
   groupCreateOpenPayload: false,
   changePasswordBtnPayload: false,
   editProfileNamePayload: false,
   addAddressPayload: false,
   dispatchModalPayload: false,
   confirmModalPayload: false,
   completeModalPayload: false,
}

export default function (state = initialState, action) {
   switch (action.type) {
      case 'CHANGE_PASSWORD_BTN_STATUS':
         {
            return {
               ...state,
               changePasswordBtnPayload: action.payload
            };
         }
      case 'EDIT_PROFILE_NAME_STATUS':
         {
            return {
               ...state,
               editProfileNamePayload: action.payload
            };
         }
      case 'ADD_ADDRESS_OPEN':
         {
            return {
               ...state,
               addAddressPayload: action.payload
            };
         }
      case 'ADD_ADDRESS_CLOSE':
         {
            return {
               ...state,
               addAddressPayload: action.payload
            };
         }
      case 'REG_OPEN_STATUS':
         {
            return {
               ...state,
               openRegPayload: action.payload
            };
         }
      case 'OTP_OPEN_STATUS':
         {
            return {
               ...state,
               openOtpPayload: action.payload
            };
         }
      case 'PASSWORD_OPEN_STATUS':
         {
            return {
               ...state,
               openPasswordPayload: action.payload
            };
         }
      case 'GROUP_CREATE_OPEN_STATUS':
         {
            return {
               ...state,
               groupCreateOpenPayload: action.payload
            };
         }
      case 'TOGGLE_MENU':
         {
            return {
               ...state,
               toggleMenuPayload: action.payload
            };
         }
      case 'DISPATCH_MODAL':
         {
            return {
               ...state,
               dispatchModalPayload: action.payload
            };
         }
      case 'CONFIRM_MODAL':
         {
            return {
               ...state,
               confirmModalPayload: action.payload
            };
         }
      case 'COMPLETE_MODAL':
         {
            return {
               ...state,
               completeModalPayload: action.payload
            };
         }
      case 'CANCEL_MODAL':
         {
            return {
               ...state,
               cancelModalPayload: action.payload
            };
         }
      default:
         return state;
   }
}