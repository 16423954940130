import React from "react";
import {Segment, Dimmer, Loader } from 'semantic-ui-react'

const TableLoader = () => {
    return(
        
        <Segment style={{height: 350, width: '100%'}}>
        <Dimmer active inverted>
           <Loader size='large'>Loading</Loader>
        </Dimmer>
     </Segment>
    )
}

export default TableLoader