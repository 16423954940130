import { BASE_URL } from '../../Resources/Api';
import axios from "axios";

// Get User List
export function getGalleryTypeAction(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'gallery_category_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: BASE_URL + "GalleryCategoryList",
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            dispatch({
               type: 'LOADING_STATUS',
               payload: {
                  status: false,
                  string: 'gallery_category_list'
               }
            })
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_GALLERYTYPE',
                     payload: response.data
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'gallery_category_list',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'gallery_category_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "gallery_category_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "gallery_category_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}

// Save Action
export function saveGalleryCategoryAction(values, actions, actionMode, authToken, toast) {
   return async function (dispatch) {
      console.log(values)
      actions.setSubmitting(true)

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: BASE_URL + "GalleryCategoryList",
         data: {
            Mode: values.Mode,
            GalleryCatName: values.CategoryName,
            GalleryCatID: values.GalleryCategoryID
         }
      })
         .then(function (response) {
            // console.log('Gallery Category Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  toast.success(response.data.Error)
                  actions.setSubmitting(false)
                  actions.resetForm()
                  if (actionMode === "Edit") {
                     window.location.reload()
                  }
               } else {
                  actions.setSubmitting(false)
                  toast.warn(response.data.Error)
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            toast.error("Something went wrong. Please try again.")
            console.log(error);
         });
   }
}


// Delete User
export function statusGalleryCategoryAction(rowId, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      console.log(rowId, actionMode)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'gallery_type_save'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: BASE_URL + "GalleryCategoryList",
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'InActive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            GalleryCatID: rowId,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);

            dispatch({
               type: 'LOADING_STATUS',
               payload: {
                  status: false,
                  string: 'gallery_type_save'
               }
            })
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'gallery_type_save',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'gallery_type_save',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "gallery_type_save",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "gallery_type_save",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}


// Get User List
export function getGalleryAction(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'gallery_category_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: BASE_URL + "GalleryList",
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            dispatch({
               type: 'LOADING_STATUS',
               payload: {
                  status: false,
                  string: 'gallery_category_list'
               }
            })
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_GALLERY',
                     payload: response.data
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'gallery_category_list',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'gallery_category_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "gallery_category_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "gallery_category_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}



// Save Action
export function saveGalleryAction(values, actions, actionMode, authToken, toast) {
   return async function (dispatch) {
      console.log(values, actions, actionMode, authToken)
      actions.setSubmitting(true)

      const formData = new FormData();
      formData.append("Mode", values.Mode);
      formData.append("GalleryID", values.GalleryID);
      formData.append("GalleryCatID", values.GalleryCategoryID);
      formData.append("ShortTitle", values.ShortTitle);
      formData.append("ShortDesc", "");
      formData.append("file1", values.GalleryImage);
      formData.append("file2", values.GalleryThumbnailImage);
      // formData.append('CatImagePath', fs.createReadStream(values.CatImagePath));

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
         },
         url: BASE_URL + "GallerySave",
         data: formData
      })
         .then(function (response) {
            // console.log('Gallery Save Action=================', response);
            actions.setSubmitting(false)
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  toast.success(response.data.Error)
                  actions.resetForm()
                  actions.setFieldValue('GalleryCategoryID', values.GalleryCategoryID)
                  actions.setFieldValue('GalleryImage', [])
                  if (actionMode === "Edit") {
                     window.location.reload()
                  }
               } else {
                  toast.warn(response.data.Error);
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            toast.error("Somethint went wrong. Please try again.");
            console.log(error);
         });
   }
}


// Delete User
export function statusGalleryAction(rowId, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(rowId, setOpenConfirm, actionMode, authToken)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'gallery_status'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: BASE_URL + "GalleryCategoryList",
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'InActive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            GalleryID: rowId,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            dispatch({
               type: 'LOADING_STATUS',
               payload: {
                  status: false,
                  string: 'gallery_status'
               }
            })
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'gallery_status',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'gallery_status',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "gallery_status",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "gallery_status",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}