import React from 'react';
import { Container, Button, Modal, Image, Form, Message, Icon, Confirm, Input, Popup, Segment, Dimmer, Loader, Dropdown } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { saveGalleryAction, statusGalleryAction, getGalleryAction, getGalleryTypeAction } from '../../Redux/Actions/GalleryAction'
import { clearMessage } from '../../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { IMAGE_URL } from '../../Resources/Api';
import TableLoader from "../../Components/TableLoader";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export default function ImageGallery() {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(getGalleryAction(authToken))
        dispatch(getGalleryTypeAction(authToken))
    }, [])

    const location = useLocation();
    const menuList = useSelector(state => state.menuReducer.menuPayload)
    const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)

    const galleryList = useSelector(state => state.galleryReducer.galleryListPayload)
    const galleryTypeData = useSelector(state => state.galleryReducer.galleryTypeListPayload)
    const galleryTypeList = galleryTypeData && galleryTypeData.map((item) => {
        return {
            key: item.GalleryCategoryID,
            text: item.GalleryCategoryName,
            value: item.GalleryCategoryID,
        }
    })
    console.log(galleryTypeData)

    const authToken = localStorage.getItem('accessToken');
    const [openAddModal, setOpenAddModal] = React.useState(false)
    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
    const [rowId, setRowId] = React.useState(false)
    const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
    // const isPageLoading = loadingData && loadingData.string === 'product_category_list' ? loadingData.status : false
    const isLoading = loadingData && loadingData.string === 'gallery_save' ? loadingData.status : false
    // const isDeleteLoading = loadingData && loadingData.string === 'product_category_delete' ? loadingData.status : false
    const messageData = useSelector(state => state.messageReducer.messagePayload);
    const messageStatus = messageData && messageData.string === 'gallery_save' ? messageData.status : false

    const [actionMode, setActionMode] = React.useState('');
    const [rowData, setRowData] = React.useState([]);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [firstInput, setFirstInput] = React.useState('');
    const [secondInput, setSecondInput] = React.useState('');

    console.log(galleryList);
    const filteredItems = galleryList && galleryList.filter(item => (
        openFilter ?
            item.ShortTitle.toLowerCase().includes(firstInput.toLowerCase()) &&
            item.GalleryCategory.toLowerCase().includes(secondInput.toLowerCase()) :
            item.ShortTitle.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.GalleryCategory.toLowerCase().includes(searchInput.toLowerCase())
    ));

    const searchComponent = <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />

    const advanceSearchComponent = <div className="subheader-searchbar">
        <Input autoFocus placeholder='Gallery Title' value={firstInput} onChange={e => setFirstInput(e.target.value)} />
        <Input placeholder='Short Description' value={secondInput} onChange={e => setSecondInput(e.target.value)} />
    </div>

    const actions = <React.Fragment>
        <Button
            color='violet'
            onClick={() => setOpenFilter(openFilter => !openFilter)}>
            <Icon name='filter' />
            Advance Filter
        </Button>
        {/* {rollPermission && rollPermission.Canadd && */}
            <Button
                primary
                onClick={() => (setOpenAddModal(true), setActionMode('Add'))}>
                <Icon name='add circle' />
                Add New
            </Button>
            {/* } */}
    </React.Fragment>;

    const conditionalRowStyles = [
        {
            when: row => row.GalleryStatus === 'InActive',
            style: {
                backgroundColor: '#f3f3f3',
                color: 'rgb(132 132 132 / 87%)'
            },
        }
    ]
    const columns = [
        {
            name: 'Gallery Title',
            selector: 'ShortTitle',
            sortable: true,
        },
        {
            name: 'Gallery Category',
            selector: 'GalleryCategory',
            sortable: true,
        },
        {
            name: 'Image',
            selector: 'GalleryImage',
            sortable: true,
            grow: 0,
            cell: row => <img height="50px" width="100px" alt={row.ShortTitle} src={row.GalleryImage && IMAGE_URL + row.GalleryImage} />,
        },
        {
            name: 'Action',
            sortable: false,
            right: true,
            cell: (data) => <React.Fragment>
                <Button.Group basic size='small'>
                    {rollPermission && rollPermission.Candelete &&
                        <Popup
                            trigger={
                                <Button icon onClick={() => (
                                    setOpenDeleteConfirm(true),
                                    setRowId(data.GalleryID),
                                    setActionMode('Delete'))}>
                                    <Icon style={{ color: 'red' }} name='trash alternate outline' />
                                </Button>}
                            content='Delete'
                            position='top right'
                        />}
                    {rollPermission && rollPermission.Canedit &&
                        <React.Fragment>
                            {data.GalleryStatus === 'InActive' ? <Popup
                                trigger={
                                    <Button icon onClick={() => (
                                        setOpenDeleteConfirm(true),
                                        setRowId(data.GalleryID),
                                        setActionMode('ReActive'))}>
                                        <Icon style={{ color: '#ff8d00' }} name='lock' />
                                    </Button>}
                                content='Make Active'
                                position='top right'
                            /> : <Popup
                                trigger={
                                    <Button icon onClick={() => (
                                        setOpenDeleteConfirm(true),
                                        setRowId(data.GalleryID),
                                        setActionMode('InActive'))}>
                                        <Icon style={{ color: '#ff8d00' }} name='lock open' />
                                    </Button>}
                                content='Make Inactive'
                                position='top right'
                            />}
                            <Popup
                                trigger={
                                    <Button icon onClick={() => (setRowData(data), setOpenAddModal(true), setActionMode('Edit'))}>
                                        <Icon style={{ color: '#001fff' }} name='edit outline' />
                                    </Button>}
                                content='Edit'
                                position='top right'
                            />
                        </React.Fragment>}
                </Button.Group>
            </React.Fragment>,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: 'rgb(33 133 208)',
                color: '#FFF',
                fontSize: '16px',
                fontWeight: '700',
            },
        }
    }

    const categorySchema = Yup.object().shape({
        GalleryCategoryID: Yup.string()
            .required('Gallery category is required'),
        ShortTitle: Yup.string()
            .min(4, 'Product category name is too short!')
            .max(100, 'Product category name is too Long!')
            .required('Product category name is required')
    });

    setTimeout(() => {
        dispatch(clearMessage())
    }, 5000)

    const [imageUrl, setImageUrl] = React.useState([])
    const onImageChange = (event, setFieldValue) => {
        if (event.target.files && event.target.files[0]) {
            setFieldValue('GalleryImage', event.target.files[0])
            let reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    const [imageThumbnailUrl, setImageThumbnailUrl] = React.useState([])
    const onImageThumbnailChange = (event, setFieldValue) => {
        if (event.target.files && event.target.files[0]) {
            setFieldValue('GalleryImage', event.target.files[0])
            let reader = new FileReader();
            reader.onload = (e) => {
                setImageThumbnailUrl(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    return (
        <div>
            <Container fluid>
                <DataTable
                    title='Gallery'
                    columns={columns}
                    customStyles={customStyles}
                    data={filteredItems}
                    actions={actions}
                    pagination={true}
                    paginationResetDefaultPage={true}
                    subHeader
                    subHeaderComponent={openFilter ? advanceSearchComponent : searchComponent}
                    conditionalRowStyles={conditionalRowStyles}
                    persistTableHead
                    progressPending={isLoading}
                    progressComponent={<TableLoader />}
                />
                <Modal
                    open={openAddModal}
                    size='tiny'
                >
                    <Formik
                        enableReinitialize
                        initialValues={actionMode === "Add" ? {
                            Mode: "A",
                            GalleryID: 0,
                            GalleryCategoryID: 0,
                            ShortTitle: "",
                            GalleryImage: [],
                            GalleryThumbnailImage: []
                        } :
                            {
                                Mode: "E",
                                GalleryID: rowData.GalleryID,
                                GalleryCategoryID: rowData.GalleryCatID,
                                ShortTitle: rowData.ShortTitle,
                                GalleryImage: rowData.GalleryImage,
                                GalleryThumbnailImage: rowData.GalleryThumbnailImage,
                            }
                        }
                        validationSchema={categorySchema}
                        onSubmit={(values, actions) => { dispatch(saveGalleryAction(values, actions, actionMode, authToken, toast)) }}
                    // onSubmit={(values, actions) => { console.log(values) }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <React.Fragment>
                                <Modal.Header>{actionMode === "Edit" ? 'Edit Gallery' : 'Create Gallery'}</Modal.Header>
                                <Modal.Content>
                                    {messageStatus && <Message error header={messageData.message} />}
                                    <Form>
                                        <Form.Field>
                                            <label>Gallery Category</label>
                                            <Dropdown
                                                error={errors.GalleryCategoryID && touched.GalleryCategoryID}
                                                placeholder='Select user type'
                                                selection
                                                search
                                                name="GalleryCategoryID"
                                                onChange={(event, data) => {
                                                    setFieldValue('GalleryCategoryID', data.value)
                                                }}
                                                defaultValue={values.GalleryCategoryID}
                                                options={galleryTypeList}
                                            />
                                        </Form.Field>
                                        <Form.Input
                                            error={errors.ShortTitle && touched.ShortTitle ? { content: errors.ShortTitle } : false}
                                            fluid
                                            label='Gallery Title'
                                            placeholder='Gallery Title'
                                            type="text"
                                            value={values.ShortTitle}
                                            onChange={handleChange('ShortTitle')}
                                        />
                                        
                                        <label for="GalleryImage">Select PDF</label>
                                        <input
                                            type="file"
                                            id="GalleryImage"
                                            onChange={(event) => onImageChange(event, setFieldValue)}/>
                                        <label for="GalleryThumbnailImage">Select Image</label>
                                        <input
                                            type="file"
                                            id="GalleryThumbnailImage"
                                            onChange={(event) => onImageThumbnailChange(event, setFieldValue)}/>
                                        <h5 style={{ marginTop: 0 }}>(Image Size 200x220)</h5>
                                        {actionMode === 'Add' ?
                                            <img src={imageThumbnailUrl}
                                                style={imageThumbnailUrl.length > 0 ? {
                                                    width: '100%',
                                                    height: 200,
                                                    marginTop: 20
                                                } : {
                                                    display: 'none'
                                                }}
                                                alt="..." /> :
                                            <img key={imageThumbnailUrl} src={imageThumbnailUrl.length > 0 ? imageThumbnailUrl : IMAGE_URL + values.GalleryThumbnailImage} style={{ width: '100%', marginTop: 20 }} alt="..." />
                                        }

                                    </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button negative onClick={() => (window.location.reload(), setOpenAddModal(false))}>
                                        Close
                                    </Button>
                                    <Button
                                        content={actionMode === "Edit" ? "Save" : "Create"}
                                        type="submit"
                                        labelPosition='right'
                                        icon='checkmark'
                                        loading={isSubmitting}
                                        onClick={handleSubmit}
                                        positive
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        )}
                    </Formik>
                </Modal>
                <Confirm
                    header='Are you sure?'
                    content={
                        actionMode === 'Delete' ? 'Data will lost' :
                            actionMode === 'InActive' ? 'Data will inactive.' :
                                actionMode === 'ReActive' ? 'Data will active?' : null
                    }
                    open={openDeleteConfirm}
                    onCancel={() => setOpenDeleteConfirm(false)}
                    onConfirm={() => { dispatch(statusGalleryAction(rowId, setOpenDeleteConfirm, actionMode, authToken)) }}
                    size='mini'
                />
            </Container>
        </div>
    )
}