import {
   GET_USERLIST_URL,
   GET_USERROLELIST_URL,
   GET_USERSAVE_URL,
   GET_USERDELETE_URL,
   GET_CHANGE_PASSWORD_URL
} from '../../Resources/Api';
import axios from "axios";

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getUserList(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: GET_USERLIST_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_USERLIST',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'user_list',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'user_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'user_list'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "user_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}

// Get User Type
export function getUserRole(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_role'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: GET_USERROLELIST_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data.length);
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_USERROLE',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_role'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'user_role',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_role'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'user_role',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'user_role'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "user_role",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_role",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}

// Create User
export function createUser(values, actions, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(setFieldValue)
      actions.setSubmitting(true)
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      const hashedPassword = Buffer.from(`bm_secKey/2020/*${values.Password}`).toString('base64');

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: GET_USERSAVE_URL,
         data: {
            Mode: values.Mode,
            UserID: values.UserID,
            RoleID: values.RoleID,
            UserName: values.UserName,
            Password: hashedPassword,
            FirstName: values.FirstName,
            LastName: values.LastName,
            MobileNo: values.MobileNo,
            EmailID: values.EmailID,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_save',
                        msgType: 'success',
                        status: true
                     }
                  })
                  actions.setSubmitting(false)
                  actions.resetForm()
                  actions.setFieldValue('RoleID', values.RoleID)
                  if(actionMode === "Edit"){
                     window.location.reload()
                  }
               } else {
                  actions.setSubmitting(false)
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_save',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_save",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}


// Delete User
export function deleteUser(values, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(actionMode)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_delete'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: GET_USERDELETE_URL,
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'StatusInactive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            UserID: values,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'CREATE_USER',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'user_delete',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "user_delete",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_delete",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}

// Password Change
export function changePassword(rowId, setOpenDeleteConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(rowId, authToken)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_delete'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })
      const hashedPassword = Buffer.from(`bm_secKey/2020/*${'password'}`).toString('base64');

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: GET_CHANGE_PASSWORD_URL,
         data: {
            Mode: 'R',
            NewPw: hashedPassword,
            UserID: rowId,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenDeleteConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'user_delete',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "user_delete",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_delete",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}
