import {
   GET_PRODUCT_URL,
   SAVE_PRODUCT_URL,
   STATUS_PRODUCT_URL,
} from '../../Resources/Api';
import axios from "axios";

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getProduct(authToken, paramId, setImages, setCurrentImage, setPackingSchema) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'product_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      // console.log("Action", paramId)

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L',
            ProductId: paramId,
            TaxonomyID: 2
         },
         url: GET_PRODUCT_URL,
      })
         .then(function (response) {
            // console.log('Product Action=================', response.data);
            if (response.status === 200) {
               if (response.data.pdata.Proditems.length > 0) {
                  dispatch({
                     type: 'GET_PRODUCT',
                     payload: response.data.pdata.Proditems
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'product_list',
                        msgType: 'success'
                     }
                  })
                  setImages(response.data.pdata.Proditems[0].prodimage)
                  setCurrentImage(response.data.pdata.Proditems[0].prodimage)
                  setPackingSchema(response.data.pdata.Proditems[0].productRate)
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'product_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'product_list'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "product_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "product_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}

// Save Action
export function saveProduct(values, packingSchema, setPackingSchema, actions, images, setImages, currentImage, defaultImages, authToken, history, toast) {
   return async function (dispatch) {

      // console.log('Form Data====', values, packingSchema)

      actions.setSubmitting(true)
      const multiImage = images.filter(data => data.Existing !== true).map((file) => {
         return file.file
      })

      const jsonExisting = images.filter(data => data.Existing === true)
      const jsonNewData = images.filter(data => data.Existing !== true).map((item, index) => {
         return {
            FileName: null,
            existing: false,
            file: `file${index}`,
            DefImage: false,
            Stat: 'A',
         }
      })

      const jsonDeleted = currentImage.filter(function (oldItem) {
         return !jsonExisting.some(function (changedItem) {
            return oldItem.ImageName === changedItem.ImageName;
         });
      }).map((item, index) => {
         return {
            FileName: item.ImageName,
            existing: item.Existing,
            file: null,
            DefImage: item.DefImage,
            Stat: 'D',
         }
      })

      const allData = [...jsonExisting, ...jsonNewData]

      const imagesJson = allData.map((item, index) => {
         return {
            FileName: item.Existing === true ? item.ImageName : null,
            existing: item.Existing === true ? true : false,
            file: item.Existing === true ? null : item.file,
            DefImage: index === defaultImages ? true : false,
            Stat: item.Existing === true ? 'E' : 'A',
         }
      })

      const finalImageJson = [...jsonDeleted, ...imagesJson]

      const packQty = packingSchema.map(item => {
         return {
            ProdSizeID: item.ProdSizeID,
            ProdID: item.ProdID,
            PackQty: item.PackQty,
            UOM: item.UOM,
            WtInKG: item.WtInKG
         }
      })

      const formData = new FormData();
      formData.append('Mode', values.Mode);
      formData.append('ProductID', values.ProductId);
      formData.append('ProductName', values.ProductName);
      formData.append('ProductDescription', values.ProductDescription);
      formData.append('CategoryID', values.CategoryID);
      formData.append('TaxonomyID', values.TaxonomyID);
      formData.append('TaxClassID', values.TaxClassID);
      formData.append('DeliveryInformation', values.DeliveryInformation);
      // formData.append('CareInstruction', values.CareInstruction);
      formData.append('HSNCode', values.HSNCode);
      formData.append('CalcMethod', values.CalcMethod);
      formData.append('SKU', values.SKU);
      // formData.append('WeightPUnitInKG', values.WeightPUnitInKG);
      // formData.append('AllowBulk', values.AllowBulk);
      formData.append('GiftStatus', values.GiftStatus);
      formData.append('FestStatus', values.FestStatus);
      formData.append('AvailableLoc', values.AvailableLoc);
      formData.append('ProdDispCatID', values.ProdDispCatID);
      formData.append('packingSchema', JSON.stringify(packQty));
      multiImage.map((file, index) => {
         return formData.append(`file${index}`, file)
      })
      formData.append('json', JSON.stringify(finalImageJson));

      // console.log(...formData)

      for (let [key, value] of formData.entries()) {
         console.log(`${key}: ${value}`);
      }

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken
         },
         url: SAVE_PRODUCT_URL,
         data: formData
      })
         .then(function (response) {
            // console.log('Product Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  toast.success(response.data.Error);
                  actions.setSubmitting(false)
                  actions.resetForm()
                  // onImageRemoveAll()
                  setPackingSchema([])
                  actions.setFieldValue('TaxonomyID', values.TaxonomyID)
                  actions.setFieldValue('CategoryID', values.CategoryID)
                  actions.setFieldValue('TaxClassID', values.TaxClassID)
                  actions.setFieldValue('CalcMethod', values.CalcMethod)
                  actions.setFieldValue('AllowBulk', values.AllowBulk)
                  setImages([])
                  if (values.Mode === "E") {
                     history.push('/Product')
                     // window.location.reload()
                     getProduct(authToken, 0)
                  }
               } else {
                  actions.setSubmitting(false)
                  toast.error(response.data.Error)
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            toast.error("Server connection failed. Ensure network connection")
            console.log(error);
         });
   }
}


// Delete User
export function statusProduct(values, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(actionMode)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'product_delete'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: STATUS_PRODUCT_URL,
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'StatusInactive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            ProductID: values,
            TaxonomyID: 2
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'product_delete',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  getProduct(authToken, 0)
                  // window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'product_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'product_delete',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'product_delete',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "product_delete",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "product_delete",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}
