
// export const BASE_URL = 'http://3.143.156.85/WebStoreAPI/api/v1/'
// export const IMAGE_URL = 'http://3.143.156.85/WebStoreAPI'
// export const BASE_URL = process.env.NODE_ENV === 'https://api.balarammullick.com/api/v1/'
// export const IMAGE_URL = process.env.NODE_ENV === 'https://api.balarammullick.com'
export const BASE_URL = process.env.NODE_ENV === "development_" ? 'http://103.139.59.169:5009/api/v1/' : 'https://api.balarammullick.com/api/v1/'
export const IMAGE_URL = process.env.NODE_ENV === "development_" ? 'http://103.139.59.169:5009' : 'https://api.balarammullick.com'
// Admin Auth
export const LOGIN_URL = BASE_URL + 'AdminLogin'
export const LOGOUT_URL = BASE_URL + 'Logout'
export const MENU_URL = BASE_URL + 'LoadUserMenu'
export const GET_USERLIST_URL = BASE_URL + 'UserList'
export const GET_USERROLELIST_URL = BASE_URL + 'RoleList'
export const GET_USERSAVE_URL = BASE_URL + 'UserSave'
export const GET_USERDELETE_URL = BASE_URL + 'UserChangeStatus'
export const GET_CHANGE_PASSWORD_URL = BASE_URL + 'ChangePassword'
export const GET_PROFILE_URL = BASE_URL + 'PublicUserProfile'
export const GET_CATEGORY_URL = BASE_URL + 'CategoryList'
export const SAVE_CATEGORY_URL = BASE_URL + 'CategorySave'
export const STATUS_CATEGORY_URL = BASE_URL + 'CategoryChangeStatus'
export const GET_DISPLAY_CATEGORY_URL = BASE_URL + 'ProdDispCategoryList'
export const GET_PRODUCT_URL = BASE_URL + 'ProductList'
export const SAVE_PRODUCT_URL = BASE_URL + 'ProductSave'
export const STATUS_PRODUCT_URL = BASE_URL + 'ChangeProdStatus'
export const GET_PRODUCT_RATE_URL = BASE_URL + 'ProdRateList'
export const SAVE_PRODUCT_RATE_URL = BASE_URL + 'ProductRateSave'
export const GET_TAXONOMY_URL = BASE_URL + 'TaxonomyList'
export const GET_TAXCALSS_URL = BASE_URL + 'TaxClassList'
export const GET_BANNERTYPE_URL = BASE_URL + 'BannerCategoryList'
export const SAVE_BANNERTYPE_URL = BASE_URL + 'BannerCategorySave'
export const STATUS_BANNERTYPE_URL = BASE_URL + 'BannerCategoryChangeStatus'
export const GET_BANNER_URL = BASE_URL + 'BannerList'
export const SAVE_BANNER_URL = BASE_URL + 'BannerSave'
export const STATUS_BANNER_URL = BASE_URL + 'BannerChangeStatus'
export const GET_FEST_URL = BASE_URL + 'FestivalList'
export const GET_FEST_YEAR_URL = BASE_URL + 'FestivalYearList'
export const FESTIVAL_PRODUCT_SAVE_URL = BASE_URL + 'FestivalSaveWithItem'
export const INVOICE_DISPLAY_URL = BASE_URL + 'OrderList'
export const MANAGE_ORDER_URL = BASE_URL + 'GenerateInvoice'
export const ENQUIRY_LIST_URL = BASE_URL + 'ListEnquiry'
export const ENQUIRY_REPLY_URL = BASE_URL + 'ReplyEnquiry'


export const FORGOT_PASSWORD_URL = BASE_URL + 'auth/forgot-password'
export const FORGOT_PASSWORD_RESET_URL = BASE_URL + 'auth/reset-password'
export const HOME_ALL_DATA = BASE_URL + 'home-page'
export const REGISTER_URL = BASE_URL + 'auth/signup'
export const EDIT_PROFILE_URL = BASE_URL + 'edit-profile'
export const EMAIL_VALIDATION = BASE_URL + 'auth/verifyemail'
export const PRODUCTS_DETAILS_URL = BASE_URL + 'get-product'
export const PRODUCTS_REVIEW_URL = BASE_URL + 'ratings'
export const PRODUCTS_REVIEW_ADD_URL = BASE_URL + 'rating/add'
export const GET_PAGE_URL = BASE_URL + 'get-page'
export const CHANGE_PASSWORD_URL = BASE_URL + 'ChangePassword'
export const CONTACT_US_URL = BASE_URL + 'contact-us'
export const SITE_SETTINGS_URL = BASE_URL + 'get-setting'
export const CHECK_PINCODE_URL = BASE_URL + 'check-pincode'
export const OTP_VERIFICATION_URL = BASE_URL + 'auth/login-otp'
export const WISHLIST_URL = BASE_URL + 'wishlist'
export const WISHLIST_ADD_URL = BASE_URL + 'wishlist/add'
export const WISHLIST_DELETE_URL = BASE_URL + 'wishlist/remove'
export const ORDER_LIST_URL = BASE_URL + 'order-history'
export const AUTO_COMPLETE = BASE_URL + 'autocomplete'


export const HOME_BANNERL = BASE_URL + 'banner'
export const HOME_DATA_URL = BASE_URL + 'auth/register'
export const TEMP_REGISTER_URL = BASE_URL + 'auth/tempRegister'
export const V_CODE_URL = BASE_URL + 'auth/vCodeVerify'
export const LIST_URL = BASE_URL + 'getList'
export const DELETE_URL = BASE_URL + 'delete'
export const PRODUCT_DETAILS_URL = BASE_URL + 'details'
export const EMPLOYEE_IMAGE_URL = IMAGE_URL + 'employee/'
export const PRODUCTS_LIST_URL = BASE_URL + 'search'
export const PRODUCTS_FILTER_URL = BASE_URL + 'filters'
export const PRODUCTS_CART_URL = BASE_URL + 'cart'
export const PRODUCTS_CART_ADD_URL = BASE_URL + 'cart/add'
export const PRODUCTS_CART_REMOVE_URL = BASE_URL + 'cart/remove'
export const PRODUCTS_CART_UPDATE_URL = BASE_URL + 'cart/update'
export const PRODUCTS_LIST_ADDRESS_URL = BASE_URL + 'my-address'
export const GET_CITY_URL = BASE_URL + 'get-city'
export const ADDRESS_ADD = BASE_URL + 'my-address/add'
export const ADDRESS_DELETE = BASE_URL + 'my-address/delete'
export const CHECKOUT_ADDRESS_CART_UPDATE = BASE_URL + 'checkout-address-cart-update'