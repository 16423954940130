import React, { useEffect } from 'react'
import {
    Button,
    Modal,
    Form,
    Dropdown
} from 'semantic-ui-react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux';
import { changeOrderStatus, getDeleveryAgentList } from '../../Redux/Actions/OrdersAction';
import { openDispatchModal } from '../../Redux/Actions/ButtonAction';

export default function OrderDispatchModal(props) {
    const { InvoiceID, authToken } = props
    const dispatch = useDispatch();
    // const authToken = localStorage.getItem('accessToken');
    const isDispatchOpen = useSelector(state => state.buttonReducer.dispatchModalPayload)
    // const orderDetailsData = useSelector(state => state.orderReducer.orderDetailPayload)
    const deleveryAgentData = useSelector(state => state.orderReducer.deleveryAgentListPayload)
    const deleveryAgentList = deleveryAgentData?.map(item => {
        return {
            key: item.DeliveryAgentID,
            text: item.DeliveryAgentName,
            value: item.DeliveryAgentID
        }
    })

    // console.log("deleveryAgentData", deleveryAgentData);
    return (
        <div>
            <Modal open={isDispatchOpen} size='tiny'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        Mode: "D",
                        InvoiceID: InvoiceID,
                        DelAgentID: "",
                        AWBNo: "",
                        comment: "",
                        RP_OrderID: "",
                        RP_PaymentID: "",
                    }}
                    onSubmit={(values, actions) => { dispatch(changeOrderStatus(values, actions, authToken)) }}
                // onSubmit={(values) => { console.log(values) }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <React.Fragment>
                            <Modal.Header>Dispatch Order</Modal.Header>
                            <Modal.Content>
                                {/* {orderDetailsData?.AvilableLoc !== "K" ? */}
                                    <Form>
                                        <Form.Field>
                                            <label>Delevery Agent</label>
                                            <Dropdown
                                                placeholder='Delevery Agent'
                                                fluid
                                                search
                                                selection
                                                options={deleveryAgentList}
                                                value={values.DelAgentID}
                                                onChange={(e, { name, value }) => setFieldValue("DelAgentID", value)} />
                                        </Form.Field>
                                        <Form.Input
                                            error={errors.AWBNo && touched.AWBNo ? { content: errors.AWBNo } : false}
                                            fluid
                                            label='AWB Number'
                                            placeholder='AWB Number'
                                            type="text"
                                            value={values.AWBNo}
                                            autoFocus
                                            onChange={handleChange('AWBNo')}
                                        />
                                    </Form>
                                     {/* :
                                    <h5>Are you sure, want to dispatch the order?</h5>} */}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => (dispatch(openDispatchModal(false)))}>
                                    Close
                                </Button>
                                <Button
                                    content="Update"
                                    type="submit"
                                    labelPosition='right'
                                    icon='checkmark'
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    positive
                                />
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}
