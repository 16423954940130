import React from 'react'
// import styled from 'styled-components'
import { Container, Button, Modal, Dropdown, Grid, Pagination, Icon, Input, Table, Select } from 'semantic-ui-react'
// import moduleName from 'module';
import { useTable, usePagination, useResizeColumns, useFlexLayout, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { useSelector, useDispatch } from 'react-redux';
import { getRateListAction, saveAction } from '../../Redux/Actions/ProductRateAction'
import moment from 'moment'
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import AppLoader from '../../Components/AppLoader';
import { getProductCategory } from '../../Redux/Actions/ProductCategoryAction'

const EditableCell = ({
   value: initialValue,
   row: { index },
   column: { id },
   cell,
   updateMyData, // This is a custom function that we supplied to our table instance
   actionMode
}) => {

   const [value, setValue] = React.useState(initialValue)

   const onChange = e => {
      e.preventDefault()
      setValue(e.target.value)
   }

   const onBlur = (e) => {
      e.preventDefault();
      updateMyData(index, id, value)
   }

   React.useEffect(() => {
      setValue(initialValue)
   }, [initialValue])

   return <Input
      type="number"
      style={{ width: 80 }}
      disabled={cell.row.original.Editable === true ? false : true}
      value={value || ''}
      onChange={onChange}
      className="tableDesabledInput"
      onBlur={onBlur} />
}

const NonEditableCell = ({ cell }) => <div>{cell.value}</div>;
const DesabledCell = ({ cell }) => <Input style={{ width: 80 }} disabled className="tableDesabledInput" />;

const defaultColumn = {
   Cell: NonEditableCell,
}


function GlobalFilter({
   preGlobalFilteredRows,
   globalFilter,
   setGlobalFilter
}) {
   const count = preGlobalFilteredRows.length;
   const [value, setValue] = React.useState(globalFilter);
   const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
   }, 200);

   return (
      <Input
         icon='search'
         placeholder={`${count} records...`}
         value={value || ""}
         onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
         }}
         style={{
            float: 'right',
            position: 'absolute',
            right: 35,
            marginTop: -38
         }}
      />
   );
}


function ReactTable({ columns, data, updateMyData, skipPageReset }) {
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      rows,
      state: { pageIndex, pageSize, globalFilter },
      preGlobalFilteredRows,
      setGlobalFilter
   } = useTable(
      {
         columns,
         data,
         defaultColumn,
         useResizeColumns,
         useFlexLayout,
         autoResetPage: !skipPageReset,
         // autoResetPage: false,
         updateMyData,
         // filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      usePagination
   )

   // const firstPageRows = rows.slice(0, 10)

   return (
      <>
         <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
         />
         <Table celled {...getTableProps()} className="dataTable">
            <Table.Header style={{ backgroundColor: 'rgb(33 133 208)' }}>
               {headerGroups.map(headerGroup => (
                  <Table.Row {...headerGroup.getHeaderGroupProps()}>
                     {headerGroup.headers.map(column => (
                        <Table.HeaderCell {...column.getHeaderProps()}>{column.render('Header')}</Table.HeaderCell>
                     ))}
                  </Table.Row>
               ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
               {page.map((row, i) => {
                  prepareRow(row)
                  return (
                     <Table.Row {...row.getRowProps()}>
                        {row.cells.map(cell => {
                           return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                        })}
                     </Table.Row>
                  )
               })}
            </Table.Body>
         </Table>

         <Grid>
            <Grid.Column floated='left' width={8} className="paginationIndex">
               <Select
                  options={[
                     {key: 10, text: 10, value: 10},
                     {key: 20, text: 20, value: 20},
                     {key: 30, text: 30, value: 30},
                     {key: 40, text: 40, value: 40},
                     {key: 50, text: 50, value: 40},
                  ]} 
                  defaultValue={10}
                  onChange={(e, {value}) => {
                     setPageSize(Number(value))
                  }}/>
               <span style={{ marginLeft: 10 }}>
                  Page{' '}
                  <strong>
                     {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
               </span>
            </Grid.Column>
            <Grid.Column floated='right' width={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
               <Button.Group basic size='small'>
                  <Button icon='angle double left' onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Button icon='angle left' onClick={() => previousPage()} disabled={!canPreviousPage} />
                  <Button icon='angle right' onClick={() => nextPage()} disabled={!canNextPage} />
                  <Button icon='angle double right' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
               </Button.Group>
            </Grid.Column>
         </Grid>
      </>
   )
}

function App() {

   const [actionMode, setActionMode] = React.useState('')

   const columns = React.useMemo(
      () => [
         {
            Header: 'Product Name',
            accessor: 'ProductName',
         },
         {
            Header: 'Delivery At',
            accessor: d => d.AvailableAt === "O" ? "Outside Kolkata" : d.AvailableAt === "K" ? "Kolkata" : "Both",
            // cell: (row) => row.AvailableAt === "O" ? "Outside Kolkata" : row.AvailableAt === "K" ? "Kolkata" : "Both",
         },
         {
            Header: 'Category Name',
            accessor: 'CategoryName',
         },
         {
            Header: 'Effective Date',
            accessor: d => {
               return moment(d.effDate).format("DD-MM-YYYY")
            },
         },
         {
            Header: 'Prod Rate',
            accessor: 'ProdRate',
         },
         {
            Header: 'Pack Qty.',
            accessor: 'PackQty',
         },
         {
            Header: 'Weight(KG)',
            accessor: 'WtInKG',
         },
         {
            Header: "New Price",
            accessor: 'NewRate',
            Cell: actionMode === "Edit" || actionMode === "Add" ? EditableCell : DesabledCell
         },
      ],
      [actionMode]
   )

   React.useEffect(() => {
      dispatch(getRateListAction(currentDate, authToken, setProductData, setIsLoading, setSkipPageReset))
      dispatch(getProductCategory(authToken))
   }, [])

   let location = useLocation();
   const menuList = useSelector(state => state.menuReducer.menuPayload)
   const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)
   const currentDate = moment().format('YYYY-MM-DD');
   const authToken = localStorage.getItem('accessToken');
   const dispatch = useDispatch()
   const [openFilter, setOpenFilter] = React.useState(false);
   const [firstInput, setFirstInput] = React.useState('');
   const [secondInput, setSecondInput] = React.useState('');
   const [thirdInput, setThirdInput] = React.useState('');
   const [searchInput, setSearchInput] = React.useState('');
   const [startDate, setStartDate] = React.useState(new Date());
   const [openCalender, setCalenderOpen] = React.useState(false);
   const [isDisable, setIsDisable] = React.useState(false);
   const [productData, setProductData] = React.useState([])

   const filteredItems = productData && productData.filter(item => (
      openFilter ?
         item.ProductName.toLowerCase().includes(firstInput.toLowerCase()) &&
         item.CategoryName.toLowerCase().includes(secondInput.toLowerCase()) &&
         item.ProdRate.toString().includes(thirdInput) :
         item.ProductName.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.CategoryName.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.ProdRate.toString().includes(searchInput)
   ));

   const productCategoryData = useSelector(state => state.productReducer.productCategoryPayload)
   const productCategoryList = productCategoryData && productCategoryData.filter(item => item.CatStatus === 'Active').map(data => {
      return {
         key: data.CategoryID,
         text: data.CategoryName,
         value: data.CategoryID
      }
   })
   const getDropdownText = (value) => {
      const data = productCategoryList.find(o => o.value === value);
      data ? setSecondInput(data.text) : setSecondInput('')
      // console.log(data)
   }

   const [isLoading, setIsLoading] = React.useState(false)
   const [skipPageReset, setSkipPageReset] = React.useState(false)
   const updateMyData = (rowIndex, columnId, value) => {
      setSkipPageReset(true)
      setProductData(old =>
         old.map((row, index) => {
            if (index === rowIndex) {
               return {
                  ...old[rowIndex],
                  [columnId]: value
               }
            }
            return row
         })
      )
   }

   const formatedData = productData.filter(item => item.NewRate !== undefined && item.NewRate !== "").map((data) => {
      return {
         Mode: actionMode === "Edit" ? "E" : "A",
         pk_RateID: actionMode === 'Edit' ? data.ProdRateID : 0,
         fk_ProdID: data.ProdID,
         fk_ProdSizeID: data.ProdSizeID,
         EffectiveDate: moment(startDate).format('YYYY-MM-DD'),
         SellingRate: data.NewRate
      }
   })

   // console.log("formatedData", formatedData);

   const handleSubmit = () => {
      dispatch(saveAction(formatedData, setIsLoading, setProductData, setSkipPageReset, setActionMode, currentDate, actionMode, authToken))
      // console.log(formatedData)
   }

   return (
      <div>
         <Container fluid>
            <Grid>
               <Grid.Column floated='left' width={8}>
                  <h2 style={{ fontSize: 22, color: 'rgba(0,0,0,0.87)', fontWeight: 400 }}>Product Rate</h2>
               </Grid.Column>
               <Grid.Column floated='right' width={8}>
                  {actionMode === 'Edit' || actionMode === "Add" ?
                     <>
                        <Button
                           floated='right'
                           primary
                           onClick={handleSubmit}>
                           <Icon name='save outline' />
                           Save Record
                        </Button>
                        <Button
                           floated='right'
                           negative
                           onClick={() => setActionMode(false)}>
                           <Icon name='times circle' />
                           Cancel
                        </Button>
                     </> :
                     <>
                        {rollPermission && rollPermission.Canadd &&
                           <Button
                              floated='right'
                              primary
                              onClick={() => (setCalenderOpen(true), setStartDate(new Date()))}>
                              <Icon name='add circle' />
                              Add new rate
                           </Button>}
                        {rollPermission && rollPermission.Canedit &&
                           <Button
                              floated='right'
                              positive
                              onClick={() => setActionMode('Edit')}>
                              <Icon name='edit outline' />
                              Edit Rate
                           </Button>}
                     </>}
                  <Button
                     floated='right'
                     onClick={() => setOpenFilter(openFilter => !openFilter)}
                     color='violet'>
                     <Icon name='filter' />
                     Advance Filter
                  </Button>
               </Grid.Column>
            </Grid>
            {openFilter === false ?
               <Grid>
                  <Grid.Column floated='left' width={5}>
                     <DatePicker
                        dateFormat="dd-MM-yyyy"
                        todayButton="Today"
                        selected={startDate}
                        showMonthDropdown
                        showYearDropdown
                        disabled={isDisable}
                        onChange={date => (
                           setStartDate(date),
                           dispatch(getRateListAction(date, authToken, setProductData, setIsLoading, setSkipPageReset))
                        )}
                        customInput={
                           <Input placeholder='DD-MM-YYYY' action={{ icon: 'calendar alternate outline' }} />
                        } />
                  </Grid.Column>
                  <Grid.Column floated='right' width={5}>
                  </Grid.Column>
               </Grid> :
               <Grid className="grid-searchbar">
                  <Grid.Column>
                     <Input autoFocus placeholder='Product Name' value={firstInput} onChange={e => setFirstInput(e.target.value)} />
                     <Dropdown
                        placeholder='Product Category'
                        selection
                        clearable
                        options={productCategoryList}
                        defaultValue={secondInput}
                        onChange={(e, { value }) => getDropdownText(value)}
                     />
                     {/* <Input placeholder='Product Category' value={secondInput} onChange={e => setSecondInput(e.target.value)} /> */}
                     <Input placeholder='Product Rate' type="number" value={thirdInput} onChange={e => setThirdInput(e.target.value)} />
                  </Grid.Column>
               </Grid>}
            {isLoading ? <AppLoader /> :
               <ReactTable
                  columns={columns}
                  data={filteredItems}
                  updateMyData={updateMyData}
                  skipPageReset={skipPageReset}
               // actionMode={actionMode}
               />}

            <Modal
               basic
               onClose={() => setCalenderOpen(false)}
               onOpen={() => setCalenderOpen(true)}
               open={openCalender}
               size='mini'
               closeIcon
               className="modelContentCenter"
            >
               <Modal.Content>
                  <DatePicker
                     dateFormat="dd-MM-yyyy"
                     todayButton="Today"
                     selected={startDate}
                     showMonthDropdown
                     showYearDropdown
                     minDate={moment().toDate()}
                     onChange={date => (
                        setStartDate(date),
                        setActionMode("Add"),
                        setCalenderOpen(false),
                        setIsDisable(true)
                     )}
                     inline />
               </Modal.Content>
            </Modal>
         </Container>
      </div>
   )
}

export default App
