const initial = {
   otpOpenStatusPayload: false,
   otpOpenButtonPayload: true
}

export default function (state = initial, action) {
   switch (action.type) {

      case 'ACCESS_TOKEN':
         {
            return {
               ...state,
               tokenPayload: action.payload
            };
         }
      case 'USER_RAGISTER':
         {
            return {
               ...state,
               profilePayload: action.payload
            };
         }
      case 'USER_LOGIN':
         // alert(JSON.stringify(action.payload.data));
         {
            return {
               ...state,
               profilePayload: action.payload
            };
         }
      case 'GET_PROFILE':
         {
            return {
               ...state,
               profilePayload: action.payload
            };
         }
      case 'OTPINPUT_OPEN':
         {
            return {
               ...state,
               otpOpenStatusPayload: action.payload
            };
         }
      case 'OTP_BUTTON_OPEN':
         {
            return {
               ...state,
               otpOpenButtonPayload: action.payload
            };
         }
      case 'EDIT_PROFILE':
         {
            return { ...state, profilePayload: action.payload };
         }
      default:
         return state;
   }
}
