import React from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useResizeColumns } from 'react-table'
import { useSelector, useDispatch } from 'react-redux';
import { getRateListAction, saveAction } from '../Redux/Actions/ProductRateAction'
import moment from 'moment'

import makeData from './makeData'

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`
const EditableCell = ({
   value: initialValue,
   row: { index },
   column: { id },
   updateMyData, // This is a custom function that we supplied to our table instance
}) => {
   
   const [value, setValue] = React.useState(initialValue)

   const onChange = e => {
      setValue(e.target.value)
   }

   const onBlur = () => {
      updateMyData(index, id, value)
   }
   
   React.useEffect(() => {
      setValue(initialValue)
   }, [initialValue])

   return <input value={value} onChange={onChange} onBlur={onBlur} />
}


const NonEditableCell = ({ cell }) => <div>{cell.value}</div>;

const defaultColumn = {
   Cell: EditableCell,
}

function Table({ columns, data, updateMyData, skipPageReset }) {
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
   } = useTable(
      {
         columns,
         data,
         defaultColumn,
         useResizeColumns,
         autoResetPage: !skipPageReset,
         updateMyData,
      },
      usePagination,
   )

   // Render the UI for your table
   return (
      <>
         <table {...getTableProps()}>
            <thead>
               {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                     ))}
                  </tr>
               ))}
            </thead>
            <tbody {...getTableBodyProps()}>
               {page.map((row, i) => {
                  prepareRow(row)
                  return (
                     <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                           return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                     </tr>
                  )
               })}
            </tbody>
         </table>
         <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
               {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
               {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
               {'>'}
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
               {'>>'}
            </button>{' '}
            <span>
               Page{' '}
               <strong>
                  {pageIndex + 1} of {pageOptions.length}
               </strong>{' '}
            </span>
            <span>
               | Go to page:{' '}
               <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                     const page = e.target.value ? Number(e.target.value) - 1 : 0
                     gotoPage(page)
                  }}
                  style={{ width: '100px' }}
               />
            </span>{' '}
            <select
               value={pageSize}
               onChange={e => {
                  setPageSize(Number(e.target.value))
               }}
            >
               {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                     Show {pageSize}
                  </option>
               ))}
            </select>
         </div>
      </>
   )
}

function App() {
   const columns = React.useMemo(
      () => [
         {
            Header: 'Product Name',
            accessor: 'ProductName',
            Cell: NonEditableCell
         },
         {
            Header: 'Category Name',
            accessor: 'CategoryName',
            Cell: NonEditableCell
         },
         {
            Header: 'Prod Rate',
            accessor: 'ProdRate',
            Cell: NonEditableCell
         },
         {
            Header: 'New Price',
            accessor: 'NewPrice',
         },
      ],
      []
   )

   const productRateList = useSelector(state => state.productReducer.productRatePayload)

   const currentDate = moment().format('YYYY-MM-DD');
   const authToken = localStorage.getItem('accessToken');
   const dispatch = useDispatch()

   const [productData, setProductData] = React.useState([])
   const [skipPageReset, setSkipPageReset] = React.useState(false)

   
   console.log(productData)

   const updateMyData = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      console.log(rowIndex, columnId, value)
      setSkipPageReset(true)
      setProductData(old =>
         old.map((row, index) => {
            if (index === rowIndex) {
               return {
                  ...old[rowIndex],
                  [columnId]: value,
               }
            }
            return row
         })
      )
   }

   React.useEffect(() => {
      dispatch(getRateListAction(currentDate, authToken, setProductData))
         setSkipPageReset(false)
   }, [])

   const handleSubmit = () => console.log(productData)

   return (
      <Styles>
         <button onClick={handleSubmit}>Save Data</button>
         <Table
            columns={columns}
            data={productData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
         />
      </Styles>
   )
}

export default App
