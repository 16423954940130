import React from 'react';

const Testimonials = () => {

    return (
      <view>
        <h1> Testimonials </h1>
      </view>
    );
  }


export default Testimonials