const initial = {
   banners: [],
   ads: [],
   features_category: [],
   testimonials: [],
   best_sellers: [],
   devines: [],
   mmtc_products: [],
}
export default function (state = {initial}, action) {
   switch (action.type) {
      case 'HOME_DATA':
         {
            return {
               ...state,
               homeDataPayload: action.payload
            };
         }
      case 'SITE_SETTINGS':
         {
            return {
               ...state,
               siteSettingsPayload: action.payload
            };
         }
      case 'AUTO_COMPLETE_DATA':
         {
            return {
               ...state,
               autoCompletePayload: action.payload
            };
         }
      
      default:
         return state;
   }
}