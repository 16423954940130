import {
   GET_BANNERTYPE_URL,
   SAVE_BANNERTYPE_URL,
   STATUS_BANNERTYPE_URL,
   GET_BANNER_URL,
   SAVE_BANNER_URL,
   STATUS_BANNER_URL,
} from '../../Resources/Api';
import axios from "axios";

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getBannerTypeAction(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'banner_category_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: GET_BANNERTYPE_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_BANNERTYPE',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'banner_category_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'banner_category_list',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'banner_category_list'
                     }
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'banner_category_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'banner_category_list'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "banner_category_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "banner_category_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}

// Save Action
export function saveBannerCategoryAction(values, actions, actionMode, authToken, toast) {
   return async function (dispatch) {
      console.log(values)
      actions.setSubmitting(true)

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: SAVE_BANNERTYPE_URL,
         data: {
            Mode: values.Mode,
            BannerCatName: values.CategoryName,
            BannerCatID: values.BannerCategoryID
         }
      })
         .then(function (response) {
            // console.log('Banner Category Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  toast.success(response.data.Error)
                  actions.setSubmitting(false)
                  actions.resetForm()
                  if(actionMode === "Edit"){
                     window.location.reload()
                  }
               } else {
                  actions.setSubmitting(false)
                  toast.warn(response.data.Error)
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            toast.error("Something went wrong. Please try again.")
            console.log(error);
         });
   }
}


// Delete User
export function statusBannerCategoryAction(rowId, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      console.log(rowId, actionMode)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'banner_type_save'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: STATUS_BANNERTYPE_URL,
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'InActive' ? 'I' : actionMode === 'ReActive'? 'R' : null,
            BannerCatID: rowId,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'banner_type_save'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'banner_type_save',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'banner_type_save'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'banner_type_save',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'banner_type_save',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "banner_type_save",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "banner_type_save",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}


// Get User List
export function getBannerAction(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'banner_save'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: GET_BANNER_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);

            dispatch({
               type: 'LOADING_STATUS',
               payload: {
                  status: false,
                  string: 'banner_save'
               }
            })
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_BANNER',
                     payload: response.data
                  })
                  dispatch({
                     type: 'message',
                     payload: {
                        message: false,
                        string: 'banner_save',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'message',
                     payload: {
                        message: "No reord found",
                        string: 'banner_save',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "banner_save",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "banner_category_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}



// Save Action
export function saveBannerAction(values, actions, actionMode, authToken, toast) {
   return async function (dispatch) {
      console.log(values, actions, actionMode, authToken)
      actions.setSubmitting(true)

      const formData = new FormData();
      formData.append("Mode", values.Mode);
      formData.append("BannerID", values.BannerID);
      formData.append("BannerCatID", values.BannerCategoryID);
      formData.append("ShortTitle", values.ShortTitle);
      formData.append("ShortTitleCC", values.ShortTitleCC);
      formData.append("ShortDesc", values.ShortDesc);
      formData.append("ShortDescCC", values.ShortDescCC);
      formData.append("files", values.BannerImage);
      // formData.append('CatImagePath', fs.createReadStream(values.CatImagePath));

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
         },
         url: SAVE_BANNER_URL,
         data: formData
      })
         .then(function (response) {
            // console.log('Banner Save Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  toast.success(response.data.Error)
                  actions.setSubmitting(false)
                  actions.resetForm()
                  actions.setFieldValue('BannerCategoryID', values.BannerCategoryID)
                  actions.setFieldValue('BannerImage', [])
                  if(actionMode === "Edit"){
                     window.location.reload()
                  }
               } else {
                  actions.setSubmitting(false)
                  toast.warn(response.data.Error);
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            toast.error("Somethint went wrong. Please try again.");
            console.log(error);
         });
   }
}


// Delete User
export function statusBannerAction(rowId, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      console.log(rowId, setOpenConfirm, actionMode, authToken)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'banner_status'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: STATUS_BANNER_URL,
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'InActive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            BannerID: rowId,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'banner_status'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'banner_status',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'banner_status'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'banner_status',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'banner_status',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "banner_status",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "banner_status",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}