
export default function (state = {}, action) {
   switch (action.type) {
      case 'ADMIN_MENU':
         {
            return {
               ...state,
               menuPayload: action.payload
            };
         }
      default:
         return state;
   }
}
