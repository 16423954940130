import {
    INVOICE_DISPLAY_URL,
    MANAGE_ORDER_URL,
    GET_USERSAVE_URL,
    GET_USERDELETE_URL,
    GET_CHANGE_PASSWORD_URL,
    BASE_URL
} from '../../Resources/Api';
import axios from "axios";
import { toast } from 'react-toastify';
import moment from 'moment'
import { openDispatchModal, openConfirmModal, openCompleteModal, openCancelModal } from './ButtonAction';

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getOrderList(values) {
    return async function (dispatch) {
        dispatch({
            type: 'LOADING_STATUS',
            payload: {
                status: true,
                string: 'user_list'
            }
        })

        dispatch({
            type: 'GET_ORDER_LIST',
            payload: []
        })

        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + values.authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                InvoiceID: values.InvoiceID,
                StartDate: moment(values.StartDate).format("YYYY-MM-DD"),
                EndDate: moment(values.EndDate).format("YYYY-MM-DD"),
                OrderStatus: values.OrderStatus,
                KolOrOutside: values.KolOrOutside,
            },
            url: INVOICE_DISPLAY_URL,
        })
            .then(function (response) {
                // console.log('Get Order Action=================', response.data);
                if (response.status === 200) {
                    if (response.data) {
                        dispatch({
                            type: 'GET_ORDER_LIST',
                            payload: response.data
                        })
                        dispatch({
                            type: 'LOADING_STATUS',
                            payload: {
                                status: false,
                                string: 'user_list'
                            }
                        })
                    } else {
                        dispatch({
                            type: 'LOADING_STATUS',
                            payload: {
                                status: false,
                                string: 'user_list'
                            }
                        })
                        toast.warn("Something went wrong. Please refresh again.")
                    }
                } else {
                    dispatch({
                        type: 'LOADING_STATUS',
                        payload: {
                            status: false,
                            string: 'user_list'
                        }
                    })
                    toast.warn("Something went wrong. Please refresh again.")
                }
            })
            .catch(function (error) {
                dispatch({
                    type: 'LOADING_STATUS',
                    payload: false
                })
                toast.warn("Something went wrong. Please refresh again.")
                console.log(error);
            });
    }
}

// Get User Type
export function changeOrderStatus(values, actions, authToken) {
    console.log(values);
    return async function (dispatch) {
        actions.setSubmitting(true)

        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            url: MANAGE_ORDER_URL,
            data: {
                Mode: values.Mode,
                InvoiceID: values.InvoiceID,
                DelAgentID: values.DelAgentID,
                AWBNo: values.AWBNo,
                comment: values.comment,
                RP_OrderID: values.RP_OrderID,
                RP_PaymentID: values.RP_PaymentID,
            }
        })
            .then(function (response) {
                // console.log('Auth Action=================', response.data.length);
                if (response.status === 200) {
                    if (response.data) {
                        actions.setSubmitting(false)
                        toast.success("Order updated successfully.")
                        dispatch(openDispatchModal(false))
                        dispatch(openConfirmModal(false))
                        dispatch(openCompleteModal(false))
                        dispatch(openCancelModal(false))
                        dispatch(getOrderList({
                            InvoiceID: 0,
                            StartDate: moment().add(-1, 'M').format('YYYY-MM-DD'),
                            EndDate: moment().format('YYYY-MM-DD'),
                            OrderStatus: "",
                            authToken: authToken
                        }));
                        dispatch(getOrderDetails(authToken, values.InvoiceID))
                        // window.location.reload()
                    } else {
                        actions.setSubmitting(false)
                        toast.warn("Something went wrong. Please refresh again.")
                    }
                } else {
                    actions.setSubmitting(false)
                    toast.warn("Something went wrong. Please refresh again.")
                }
            })
            .catch(function (error) {
                actions.setSubmitting(false)
                toast.error("Something went wrong. Please refresh again.")
                console.log(error);
            });
    }
}


// Get User List
export function getOrderDetails(authToken, slug) {
    return async function (dispatch) {
        dispatch({
            type: 'LOADING_STATUS',
            payload: {
                status: true,
                string: 'user_list'
            }
        })

        dispatch({
            type: 'GET_ORDER_DETAILS',
            payload: []
        })

        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                InvoiceID: Number(slug),
                Result: "",
                BillType: "B",
                TaxanomyID: 2
            },
            url: BASE_URL + "InvoicePrint",
        })
            .then(function (response) {
                // console.log('Get Order Action=================', response.data);

                dispatch({
                    type: 'LOADING_STATUS',
                    payload: {
                        status: false,
                        string: 'user_list'
                    }
                })
                if (response.status === 200) {
                    if (response.data) {
                        dispatch({
                            type: 'GET_ORDER_DETAILS',
                            payload: response.data
                        })
                    } else {
                        toast.warn("Something went wrong. Please refresh again.")
                    }
                } else {
                    toast.warn("Something went wrong. Please refresh again.")
                }
            })
            .catch(function (error) {
                dispatch({
                    type: 'LOADING_STATUS',
                    payload: false
                })
                toast.warn("Something went wrong. Please refresh again.")
                console.log(error);
            });
    }
}

// Get Agent List
export function getDeleveryAgentList() {
    return async function (dispatch) {

        axios({
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            url: BASE_URL + "DeleveryAgentList",
        })
            .then(function (response) {

                dispatch({
                    type: 'GET_DeleveryAgentList',
                    payload: response.data
                })
            })
            .catch(function (error) {
                toast.warn("Something went wrong. Please refresh again.")
                console.log(error);
            });
    }
}

export function getDispatchList(values, authToken) {
    return async function (dispatch) {
        dispatch({
            type: 'LOADING_STATUS',
            payload: {
                status: true
            }
        })
        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: values,
            url: BASE_URL + "DispatchList",
        })
            .then(function (response) {
                // console.log('Get DispatchList=================', response.data);
                // setDispatchList(response.data)
                dispatch({
                    type: 'LOADING_STATUS',
                    payload: {
                        status: false
                    }
                })
                dispatch({
                    type: 'GET_DispatchList',
                    payload: response.data
                })
            })
            .catch(function (error) {
                dispatch({
                    type: 'LOADING_STATUS',
                    payload: {
                        status: false
                    }
                })
                toast.warn("Something went wrong. Please refresh again.")
                console.log(error);
            });
    }
}