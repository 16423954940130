import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isLoggedIn } from './AuthService';

const PrivateRoute = ({ component: content, ...rest }) => (
      <Route
            render={({ location }) => {
                  if (isLoggedIn()) {
                        return <Route component={content} {...rest} />;
                  }
                  return <Redirect
                        to={{
                              pathname: "/login",
                              state: { from: location }
                        }}
                  />;
            }}
      />
);

export default PrivateRoute;