import {
   GET_TAXONOMY_URL,
} from '../../Resources/Api';
import axios from "axios";

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getTaxonomyList(authToken) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'taxonomy_list'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            TaxonomyType: 'I'
         },
         url: GET_TAXONOMY_URL,
      })
         .then(function (response) {
            // console.log('Taxonomy Action=================', response.data);
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_TAXONOMY',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'taxonomy_list'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: false,
                        string: 'taxonomy_list',
                        msgType: 'success'
                     }
                  })
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'taxonomy_list'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: "No reord found",
                        string: 'taxonomy_list',
                        msgType: 'error'
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'taxonomy_list'
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Message,
                     string: "taxonomy_list",
                     msgType: 'error'
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "taxonomy_list",
                  msgType: 'error'
               }
            })
            console.log(error);
         });
   }
}
