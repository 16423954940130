import React from 'react';
import { Container, Button, Modal, Form, Message, Icon, Confirm, Input, Popup, Dropdown, Grid } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { getBannerTypeAction, getBannerAction, saveBannerAction, statusBannerAction } from '../../Redux/Actions/BnnerAction'
import { clearMessage } from '../../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { IMAGE_URL } from '../../Resources/Api';
import TableLoader from "../../Components/TableLoader";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const BannerMaster = () => {

   const dispatch = useDispatch()
   React.useEffect(() => {
      dispatch(getBannerAction(authToken))
      dispatch(getBannerTypeAction(authToken))
   }, [])

   const location = useLocation();
   const menuList = useSelector(state => state.menuReducer.menuPayload)
   const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)

   const bannerList = useSelector(state => state.bannerReducer.bannerListPayload)
   const bannerTypeData = useSelector(state => state.bannerReducer.bannerTypeListPayload)
   const bannerTypeList = bannerTypeData && bannerTypeData.map((item) => {
      return {
         key: item.BannerCategoryID,
         text: item.CategoryName,
         value: item.BannerCategoryID,
      }
   })
   // console.log(bannerList)

   const authToken = localStorage.getItem('accessToken');
   const [openAddModal, setOpenAddModal] = React.useState(false)
   const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
   const [rowId, setRowId] = React.useState(false)
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   // const isPageLoading = loadingData && loadingData.string === 'product_category_list' ? loadingData.status : false
   const isLoading = loadingData && loadingData.string === 'banner_save' ? loadingData.status : false
   // const isDeleteLoading = loadingData && loadingData.string === 'product_category_delete' ? loadingData.status : false
   const messageData = useSelector(state => state.messageReducer.messagePayload);
   const messageStatus = messageData && messageData.string === 'banner_save' ? messageData.status : false

   const [actionMode, setActionMode] = React.useState('');
   const [rowData, setRowData] = React.useState([]);
   const [openFilter, setOpenFilter] = React.useState(false);
   const [searchInput, setSearchInput] = React.useState('');
   const [firstInput, setFirstInput] = React.useState('');
   const [secondInput, setSecondInput] = React.useState('');
   const [thirdInput, setThirdInput] = React.useState('');
   const [fourthInput, setFourthInput] = React.useState('');
   const [fifthInput, setFifthInput] = React.useState('');
   const [sixthInput, setSixthInput] = React.useState('');

   const filteredItems = bannerList && bannerList.filter(item => (
      openFilter ?
         item.ShortTitle.toLowerCase().includes(firstInput.toLowerCase()) &&
         item.ShortDesc.toLowerCase().includes(secondInput.toLowerCase()) &&
         item.BannerCatName.toLowerCase().includes(thirdInput.toLowerCase()) :
         item.ShortTitle.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.ShortDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.BannerCatName.toLowerCase().includes(searchInput.toLowerCase()) ||
         item.Link.toLowerCase().includes(searchInput.toLowerCase())
   ));

   const searchComponent = <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />

   const advanceSearchComponent = <div className="subheader-searchbar">
      <Input autoFocus placeholder='Banner Title' value={firstInput} onChange={e => setFirstInput(e.target.value)} />
      <Input placeholder='Short Description' value={secondInput} onChange={e => setSecondInput(e.target.value)} />
      <Input placeholder='Banner Category' value={thirdInput} onChange={e => setThirdInput(e.target.value)} />
   </div>

   const actions = <React.Fragment>
      <Button
         color='violet'
         onClick={() => setOpenFilter(openFilter => !openFilter)}>
         <Icon name='filter' />
         Advance Filter
      </Button>
      {rollPermission && rollPermission.Canadd &&
         <Button
            primary
            onClick={() => (setOpenAddModal(true), setActionMode('Add'))}>
            <Icon name='add circle' />
            Add New
         </Button>}
   </React.Fragment>;

   const conditionalRowStyles = [
      {
         when: row => row.BannerStatus === 'InActive',
         style: {
            backgroundColor: '#f3f3f3',
            color: 'rgb(132 132 132 / 87%)'
         },
      }
   ]
   const columns = [
      {
         name: 'Banner Title',
         selector: 'ShortTitle',
         sortable: true,
      },
      {
         name: 'Short Description',
         cell: (data) => { return data.ShortDesc.substr(0, 25) + "..." },
         sortable: true,
      },
      {
         name: 'Banner Category',
         selector: 'BannerCatName',
         sortable: true,
      },
      {
         name: 'Image',
         selector: 'BannerImage',
         sortable: true,
         grow: 0,
         cell: row => <img height="50px" width="100px" alt={row.ShortTitle} src={row.BannerImage && IMAGE_URL + row.BannerImage} />,
      },
      {
         name: 'Action',
         sortable: false,
         right: true,
         cell: (data) => <React.Fragment>
            <Button.Group basic size='small'>
               {rollPermission && rollPermission.Candelete &&
                  <Popup
                     trigger={
                        <Button icon onClick={() => (
                           setOpenDeleteConfirm(true),
                           setRowId(data.BannerID),
                           setActionMode('Delete'))}>
                           <Icon style={{ color: 'red' }} name='trash alternate outline' />
                        </Button>}
                     content='Delete'
                     position='top right'
                  />}
               {rollPermission && rollPermission.Canedit &&
                  <React.Fragment>
                     {data.BannerStatus === 'InActive' ? <Popup
                        trigger={
                           <Button icon onClick={() => (
                              setOpenDeleteConfirm(true),
                              setRowId(data.BannerID),
                              setActionMode('ReActive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock' />
                           </Button>}
                        content='Make Active'
                        position='top right'
                     /> : <Popup
                        trigger={
                           <Button icon onClick={() => (
                              setOpenDeleteConfirm(true),
                              setRowId(data.BannerID),
                              setActionMode('InActive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock open' />
                           </Button>}
                        content='Make Inactive'
                        position='top right'
                     />}
                     <Popup
                        trigger={
                           <Button icon onClick={() => (setRowData(data), setOpenAddModal(true), setActionMode('Edit'))}>
                              <Icon style={{ color: '#001fff' }} name='edit outline' />
                           </Button>}
                        content='Edit'
                        position='top right'
                     />
                  </React.Fragment>}
            </Button.Group>
         </React.Fragment>,
      },
   ];

   const customStyles = {
      headCells: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: '700',
         },
      }
   }

   const categorySchema = Yup.object().shape({
      BannerCategoryID: Yup.string()
         .required('Banner category is required'),
      ShortTitle: Yup.string()
         .min(4, 'Product category name is too short!')
         .max(100, 'Product category name is too Long!')
         .required('Product category name is required'),
      ShortTitleCC: Yup.string(),
      ShortDesc: Yup.string()
         .min(4, 'Product category name is too short!')
         .max(500, 'Product category name is too Long!')
         .required('Product category name is required'),
      ShortTitleCC: Yup.string(),
   });

   setTimeout(() => {
      dispatch(clearMessage())
   }, 5000)

   const [imageUrl, setImageUrl] = React.useState([])
   const onImageChange = (event, setFieldValue) => {
      if (event.target.files && event.target.files[0]) {
         setFieldValue('BannerImage', event.target.files[0])
         let reader = new FileReader();
         reader.onload = (e) => {
            setImageUrl(e.target.result);
         };
         reader.readAsDataURL(event.target.files[0]);
      }
   }

   return (
      <div>
         <Container fluid>
            <DataTable
               title='Banner'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               actions={actions}
               pagination={true}
               paginationResetDefaultPage={true}
               subHeader
               subHeaderComponent={openFilter ? advanceSearchComponent : searchComponent}
               conditionalRowStyles={conditionalRowStyles}
               persistTableHead
               progressPending={isLoading}
               progressComponent={<TableLoader />}
            />
            <Modal
               open={openAddModal}
               size='tiny'
            >
               <Formik
                  enableReinitialize
                  initialValues={actionMode === "Add" ? {
                     Mode: "A",
                     BannerID: 0,
                     BannerCategoryID: 0,
                     ShortTitle: "",
                     ShortTitleCC: "#451a1a",
                     ShortDesc: "",
                     ShortDescCC: "#616161",
                     BannerImage: []
                  } :
                     {
                        Mode: "E",
                        BannerID: rowData.BannerID,
                        BannerCategoryID: rowData.BannerCatID,
                        ShortTitle: rowData.ShortTitle,
                        ShortTitleCC: rowData.ShortTitleCC,
                        ShortDesc: rowData.ShortDesc,
                        ShortDescCC: rowData.ShortDescCC,
                        BannerImage: rowData.BannerImage
                     }
                  }
                  validationSchema={categorySchema}
                  onSubmit={(values, actions) => { dispatch(saveBannerAction(values, actions, actionMode, authToken, toast)) }}
               // onSubmit={(values, actions) => { console.log(values) }}
               >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     isSubmitting,
                     handleSubmit,
                     setFieldValue
                  }) => (
                     <React.Fragment>
                        <Modal.Header>{actionMode === "Edit" ? 'Edit Banner' : 'Create Banner'}</Modal.Header>
                        <Modal.Content>
                           {messageStatus && <Message error header={messageData.message} />}
                           <Form>
                              <Form.Field>
                                 <label>Banner Category</label>
                                 <Dropdown
                                    error={errors.BannerCategoryID && touched.BannerCategoryID}
                                    placeholder='Select user type'
                                    selection
                                    search
                                    name="BannerCategoryID"
                                    onChange={(event, data) => {
                                       setFieldValue('BannerCategoryID', data.value)
                                    }}
                                    defaultValue={values.BannerCategoryID}
                                    options={bannerTypeList}
                                 />
                              </Form.Field>
                              <Form.Field style={{margin: 0}}>
                                 <Form.Input
                                    error={errors.ShortTitle && touched.ShortTitle ? { content: errors.ShortTitle } : false}
                                    fluid
                                    label='Banner Title'
                                    placeholder='Banner Title'
                                    type="text"
                                    value={values.ShortTitle}
                                    onChange={handleChange('ShortTitle')}
                                 />
                                 <Grid style={{ justifyContent: 'space-evenly', margin: 0 }}>
                                    <Grid.Row style={{ padding: 0 }}>
                                       <Form.Input
                                          error={errors.ShortTitleCC && touched.ShortTitleCC ? { content: errors.ShortTitleCC } : false}
                                          fluid
                                          label='Title text color'
                                          placeholder='Title text color'
                                          type="text"
                                          width={5}
                                          maxLength={7}
                                          value={values.ShortTitleCC}
                                          onChange={handleChange('ShortTitleCC')}
                                       />
                                       <div style={{ width: 100, height: 40, marginTop: 18, backgroundColor: `${values.ShortTitleCC}` }} />
                                    </Grid.Row>
                                 </Grid>
                              </Form.Field>
                              <Form.Field style={{margin: 0}}>
                                 <Form.TextArea
                                    error={errors.ShortDesc && touched.ShortDesc ? { content: errors.ShortDesc } : false}
                                    fluid
                                    label='Banner Descriptions'
                                    placeholder='Banner Descriptions'
                                    type="text"
                                    value={values.ShortDesc}
                                    onChange={handleChange('ShortDesc')}
                                 />
                                 <Grid style={{ justifyContent: 'space-evenly', margin: 0 }}>
                                    <Grid.Row style={{ padding: 0 }}>
                                       <Form.Input
                                          error={errors.ShortDescCC && touched.ShortDescCC ? { content: errors.ShortDescCC } : false}
                                          fluid
                                          label='Descriptions text color'
                                          placeholder='Descriptions text color'
                                          type="text"
                                          width={5}
                                          value={values.ShortDescCC}
                                          onChange={handleChange('ShortDescCC')}
                                       />
                                       <div style={{ width: 100, height: 40, marginTop: 18, backgroundColor: `${values.ShortDescCC}` }} />
                                    </Grid.Row>
                                 </Grid>
                              </Form.Field>
                              <h3 style={{ marginTop: 0 }}>{
                                 values.BannerCategoryID === 1 ? `(Image Size 2000x700)` :
                                    values.BannerCategoryID === 2 ? `(Image Size 2000x800)` :
                                       values.BannerCategoryID === 3 ? `(Image Size 500x540)` :
                                          values.BannerCategoryID === 4 ? `(Image Size 2000x800)` :
                                             values.BannerCategoryID === 5 ? `(Image Size 2000x800)` :
                                                values.BannerCategoryID === 6 ? `(Image Size 2000x800)` :
                                                   values.BannerCategoryID === 12 ? `(Image Size 400x400)` :
                                                      values.BannerCategoryID === 13 ? `(Image Size 400x260)` :
                                                         values.BannerCategoryID === 20 ? `(Image Size 2000x400)` :
                                                            values.BannerCategoryID === 21 ? `(Image Size 2000x400)` :
                                                               values.BannerCategoryID === 22 ? `(Image Size 2000x400)` :
                                                                  values.BannerCategoryID === 9 ? `(Image Size 2000x400)` :
                                                                     values.BannerCategoryID === 7 ? `(Image Size 2000x750)` :
                                                                        values.BannerCategoryID === 8 ? `(Image Size 800x660)` :
                                                                           values.BannerCategoryID === 17 ? `(Image Size 450x300)` :
                                                                              values.BannerCategoryID === 10 ? `(Image Size 2000x800)` :
                                                                                 values.BannerCategoryID === 14 ? `(Image Size 990x800)` :
                                                                                    values.BannerCategoryID === 15 ? `(Image Size 2000x800)` :
                                                                                       `(Image Size 2000x800)`
                              }</h3>
                              <input
                                 type="file"
                                 onChange={(event) => onImageChange(event, setFieldValue)}
                                 accept="image/*"
                              />
                              {actionMode === 'Add' ?
                                 <img src={imageUrl}
                                    style={imageUrl.length > 0 ? {
                                       width: '100%',
                                       height: 200,
                                       marginTop: 20
                                    } : {
                                       display: 'none'
                                    }}
                                    alt="..." /> :
                                 <img key={imageUrl} src={imageUrl.length > 0 ? imageUrl : IMAGE_URL + values.BannerImage} style={{ width: '100%', marginTop: 20 }} alt="..." />
                              }

                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button negative onClick={() => (dispatch(getBannerAction(authToken)), setOpenAddModal(false))}>
                              Close
                           </Button>
                           <Button
                              content={actionMode === "Edit" ? "Save" : "Create"}
                              type="submit"
                              labelPosition='right'
                              icon='checkmark'
                              loading={isSubmitting}
                              onClick={handleSubmit}
                              positive
                           />
                        </Modal.Actions>
                     </React.Fragment>
                  )}
               </Formik>
            </Modal>
            <Confirm
               header='Are you sure?'
               content={
                  actionMode === 'Delete' ? 'Data will lost' :
                     actionMode === 'InActive' ? 'Data will inactive.' :
                        actionMode === 'ReActive' ? 'Data will active?' : null
               }
               open={openDeleteConfirm}
               onCancel={() => setOpenDeleteConfirm(false)}
               onConfirm={() => { dispatch(statusBannerAction(rowId, setOpenDeleteConfirm, actionMode, authToken)) }}
               size='mini'
            />
         </Container>
      </div>
   )
}

export default BannerMaster