const initialState = {
   enquiryListPayload: []
};

export default function (state = initialState, action) {
   switch (action.type) {
      case 'GET_ENQUIRY_LIST': {
         return {
            ...state,
            enquiryListPayload: action.payload
         };
      }
      default:
         return state;
   }
}