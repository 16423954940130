import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Dropdown,
    Input,
    Grid,
    Button,
    Icon,
    Label
} from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import moment from 'moment';
import DataTable from "react-data-table-component-footer";
// import _ from "lodash";
import { getDeleveryAgentList, getDispatchList } from '../../Redux/Actions/OrdersAction'
import AppLoader from "../../Components/AppLoader";
import { numberFormatPdf } from '../../Components/NumberFormat';
import { dispatchListGenerator } from '../../Components/Reports/dispatchListGenerator';

const DispatchList = () => {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [delAgent, setDelAgent] = useState(0);
    const [searchInput, setSearchInput] = React.useState('');

    const authToken = localStorage.getItem('accessToken');
    const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
    const isLoading = loadingData && loadingData.status ? loadingData.status : false
    const dispatchData = useSelector(state => state.orderReducer.dispatchListPayload)
    const filteredItems = dispatchData?.filter(item =>
        (
            item.InvoiceNo.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.OrderNo.toLowerCase().includes(searchInput.toLowerCase())
        ));
    const deleveryAgentData = useSelector(state => state.orderReducer.deleveryAgentListPayload)
    const deleveryAgentList = deleveryAgentData?.map(item => {
        return {
            key: item.DeliveryAgentID,
            text: item.DeliveryAgentName,
            value: item.DeliveryAgentID
        }
    })

    const delAgentAll = [{
        key: 0,
        text: "All Agent",
        value: 0
    }]

    const deliveryAgent = deleveryAgentList && [...delAgentAll, ...deleveryAgentList]

    const actions = <React.Fragment>
        {filteredItems && <Label basic size="large">
            Delivery Charge -
            <Label.Detail>{numberFormatPdf(filteredItems?.reduce((sum, el) => sum + el.DeliveryCharge, 0))}</Label.Detail>
        </Label>}
        {filteredItems && <Label basic size="large">
            Bill Value -
            <Label.Detail>{numberFormatPdf(filteredItems?.reduce((sum, el) => sum + el.BillValue, 0))}</Label.Detail>
        </Label>}
        <Button
            primary
            onClick={() => dispatchListGenerator(filteredItems, startDate, endDate)}>
            <Icon name='add circle' />
            Export List
        </Button>
    </React.Fragment>

    useEffect(() => {
        dispatch(getDispatchList({
            startDate: startDate,
            endDate: endDate,
            DelAgentID: delAgent
        }, authToken))
        dispatch(getDeleveryAgentList())
    }, [])

    const searchComponent = <Grid style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Grid.Row>
            <Grid.Column width={4} style={{ display: 'flex', alignItems: 'center' }}>
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    todayButton="Today"
                    selected={startDate}
                    showMonthDropdown
                    showYearDropdown
                    onChange={date => (
                        setStartDate(date),
                        dispatch(getDispatchList({
                            startDate: date,
                            endDate: endDate,
                            DelAgentID: delAgent
                        }, authToken))
                    )}
                    customInput={
                        <Input placeholder='DD-MM-YYYY' action={{ icon: 'calendar alternate outline' }} />
                    }
                />
                <span style={{ marginLeft: 10, marginRight: 10 }}> To </span>
            </Grid.Column>
            <Grid.Column width={4} >
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    todayButton="Today"
                    selected={endDate}
                    showMonthDropdown
                    showYearDropdown
                    // disabled={isDisable}
                    onChange={date => (
                        setEndDate(date),
                        dispatch(getDispatchList({
                            startDate: date,
                            endDate: endDate,
                            DelAgentID: delAgent
                        }, authToken))
                    )}
                    customInput={
                        <Input placeholder='DD-MM-YYYY' action={{ icon: 'calendar alternate outline' }} />
                    }
                />
            </Grid.Column>
            <Grid.Column width={4} >
                <Dropdown
                    // style={{ width: '30%' }}
                    placeholder='Order Status'
                    selection
                    search
                    options={deliveryAgent}
                    defaultValue={delAgent}
                    onChange={(e, { value }) => {
                        setDelAgent(value);
                        dispatch(getDispatchList({
                            startDate: startDate,
                            endDate: endDate,
                            DelAgentID: value
                        }, authToken))
                    }}
                />
            </Grid.Column>
            <Grid.Column width={4}>
                <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />
            </Grid.Column>
        </Grid.Row>
    </Grid>

    const columns = [
        {
            name: 'Delivery Agent Name',
            selector: 'DelAgentName',
            sortable: true
        },
        {
            name: 'Order No',
            selector: 'OrderNo',
            sortable: true
        },
        {
            name: 'Order Date',
            cell: (data) => moment(data.OrderDate).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Invoice No',
            selector: 'InvoiceNo',
            sortable: true
        },
        {
            name: 'Invoice Date',
            cell: (data) => moment(data.InvoiceDate).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Dispatch Date',
            cell: (data) => moment(data.dispDate).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Del. Charge',
            selector: 'BillValue',
            cell: (data) => numberFormatPdf(data.DeliveryCharge),
            right: true,
            sortable: true
        },
        {
            name: 'Bill Value',
            // selector: 'BillValue',
            // cell: data => <span>{numberFormatPdf(data.BillValue)}</span>,
            selector: d => {
                return numberFormatPdf(d.BillValue)
            },
            right: true,
            sortable: true
        }
    ];

    // const footer = {
    //     DelAgentName: "Total",
    //     // DeliveryCharge: filteredItems?.reduce((a, b) => a + b.DeliveryCharge, 0),
    //     BillValue: _.sum(_.map(filteredItems, d => d.BillValue))
    // };


    const customStyles = {
        header: {
            style: {
                minHeight: '40px',
            },
        },
        headRow: {
            style: {
                backgroundColor: 'rgb(33 133 208)',
                height: '10px',
                minHeight: '40px'
            },
        },
        headCells: {
            style: {
                color: '#FFF',
                fontSize: '15px',
                fontWeight: '700',
                padding: '10px'
            },
        }
    }

    return (
        <div>
            <Container fluid>
                <DataTable
                    title='Dispatch List'
                    columns={columns}
                    customStyles={customStyles}
                    data={filteredItems}
                    pagination={true}
                    paginationResetDefaultPage={true}
                    subHeader
                    actions={actions}
                    highlightOnHover
                    striped
                    subHeaderComponent={searchComponent}
                    persistTableHead
                    progressPending={isLoading}
                    progressComponent={<AppLoader />}
                />
            </Container>
        </div>
    )
}

export default DispatchList