import React from 'react';
import {
   Route,
   Switch,
} from 'react-router-dom'
import routes from '../Resources/routes'
import PrivateRoute from '../Resources/Auth/PrivateRoute';

const AppContent = () => {

   return (
      <main className="content-container">
            <Switch>
               {routes.map((route, index) => {
                  return route.component && (
                     <PrivateRoute
                        key={index}
                        path={route.Navigation}
                        exact={route.exact && route.exact}
                        name={route.MenuName}
                        render={props => (
                           <route.component {...props} />
                        )} />
                  )
               })}
            </Switch>
      </main>
   )
}

export default React.memo(AppContent)