import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Layout from './Components/Layout';
import PrivateRoute from './Resources/Auth/PrivateRoute';
import Login from './Pages/Auth/Login';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import Page404 from './Pages/Common/Page404';
import Page500 from './Pages/Common/Page500';
import { menuAction } from './Redux/Actions/MenuAction'
import TestApp from './Pages/TestApp'

const App = () => {
  const reducerToken = useSelector(state => state.authReducer.tokenPayload);
  { reducerToken && reducerToken === undefined && localStorage.removeItem("accessToken") }
  const authToken = localStorage.getItem('accessToken');
  // console.log(authToken)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (authToken !== null) {
      dispatch(menuAction(authToken))
    }
  }, [])
  const history = useHistory()

  return (
    <React.Fragment>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/test" component={TestApp} />
        <PrivateRoute path="/" render={props => <Layout {...props} />} />
        {/* <Route path="/500" component={Page500} /> */}
        <Route path="*" component={Page404} />
        {/* <Route path="/" name="Home" render={props => <Layout {...props}/>} /> */}
      </Switch>
    </Router>
    </React.Fragment>
  );
}

export default App