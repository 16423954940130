import Home from '../Pages/Home';
import BannerType from '../Pages/Banner/BannerType';
import Banner from '../Pages/Banner/Banner';
import CMSPageType from '../Pages/CMSPage/CMSPageType';
import CMSPage from '../Pages/CMSPage/CMSPage';
import Testimonials from '../Pages/Testimonials';
import Advertisement from '../Pages/Advertisement';
import ProductCategory from '../Pages/Product/ProductCategory';
import Product from '../Pages/Product/Product';
import ProductForm from '../Pages/Product/ProductForm';
import ProductRate from '../Pages/Product/ProductRate';
import TaxMaster from '../Pages/TaxMaster';
import MatchingItems from '../Pages/MatchingItems';
import Occasion from '../Pages/FestMaster/FestMaster';
import UserMaster from '../Pages/UserMaster';
import Transaction from '../Pages/Transaction';
import Orders from '../Pages/Transactions/Orders/index';
import OrdersDetails from '../Pages/Transactions/Orders/OrdersDetails';
// import EnquiryReports from '../Pages/Transactions/Enquiry/index';
import ImageGallery from '../Pages/Banner/ImageGallery';
import ChangePassword from '../Pages/Auth/ChangePassword';
import DispatchList from '../Pages/Reports/DispatchList';

const routes = [
    { Navigation: '/Home', exact: true, component: Home },
    { Navigation: '/BannerType', component: BannerType},
    { Navigation: '/Banner', component: Banner},
    { Navigation: '/CMSPageType', component: CMSPageType},
    { Navigation: '/CMSPage', component: CMSPage},
    { Navigation: '/Testimonials', component: Testimonials},
    { Navigation: '/Advertisement', component: Advertisement},
    { Navigation: '/UserMaster', component: UserMaster},
    { Navigation: '/ProductCategory', component: ProductCategory},
    { Navigation: '/Product', component: Product},
    { Navigation: '/Product-Save/:paramId', component: ProductForm},
    { Navigation: '/ProductRate', component: ProductRate},
    { Navigation: '/TaxMaster', component: TaxMaster},
    { Navigation: '/MatchingItems', component: MatchingItems},
    { Navigation: '/Occasion', component: Occasion},
    { Navigation: '/Transaction', component: Transaction},
    { Navigation: '/Order', component: Orders},
    { Navigation: '/OrderDetails/:slug', component: OrdersDetails},
    { Navigation: '/Dispatch', component: DispatchList},
    // { Navigation: '/Enquiry', component: EnquiryReports},
    { Navigation: '/Gallery', component: ImageGallery},
    { Navigation: '/ChangePassword', component: ChangePassword},
]


export default routes;