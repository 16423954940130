export default function (state = {}, action) {
   switch (action.type) {

      case 'GET_FEST_LIST':
         {
            return {
               ...state,
               festListPayload: action.payload
            };
         }
      case 'GET_FEST_YEAR':
         {
            return {
               ...state,
               festYearListPayload: action.payload
            };
         }
      default:
         return state;
   }
}
