import {
   GET_FEST_URL,
   GET_FEST_YEAR_URL,
   FESTIVAL_PRODUCT_SAVE_URL,
   GET_USERDELETE_URL,
   GET_CHANGE_PASSWORD_URL
} from '../../Resources/Api';
import axios from "axios";
import moment from 'moment'

// const authToken = localStorage.getItem('accessToken');

// Get User List
export function getFestivalList(authToken, toast) {
   return async function (dispatch) {
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L'
         },
         url: GET_FEST_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            if (response.status === 200) {
               if (response.data.length > 0) {
                  dispatch({
                     type: 'GET_FEST_LIST',
                     payload: response.data
                  })
               } else {
                  toast.error("Something went wrong. Please try again.")
               }
            } else {
               toast.error("Something went wrong. Please try again.")
            }
         })
         .catch(function (error) {
            toast.error("Something went wrong. Please try again.")
            console.log(error);
         });
   }
}

// Get User Type
export function festivalYearList(authToken, year, toast) {
   return async function (dispatch) {
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_role'
         }
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         data: {
            Mode: 'L',
            CalendarYear: year,
            TaxonomyID: 2
         },
         url: GET_FEST_YEAR_URL,
      })
         .then(function (response) {
            // console.log('Auth Action=================', response.data);
            if (response.status === 200) {
               dispatch({
                  type: 'GET_FEST_YEAR',
                  payload: response.data
               })
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'user_role'
                  }
               })
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     status: false,
                     string: 'user_role'
                  }
               })
               toast.warn(response.data.Message)
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            toast.error("Server connection failed. Ensure network connection")
            console.log(error);
         });
   }
}

// Create User
export function createFestivalProduct(values, actions, toast, actionMode, authToken) {
   return async function (dispatch) {
      console.log(values)
      actions.setSubmitting(true)
      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: FESTIVAL_PRODUCT_SAVE_URL,
         data: {
            Mode: "A",
            FestYrID: values.FestYrID,
            FestID: values.FestID,
            CalendarYear: values.CalendarYear,
            OrderStartDate: moment(values.OrderStartDate).format('YYYY-MM-DD'),
            OrderEndDate: moment(values.OrderEndDate).format('YYYY-MM-DD'),
            FestStartDate: moment(values.FestStartDate).format('YYYY-MM-DD'),
            FestEndDate: moment(values.FestEndDate).format('YYYY-MM-DD'),
            FestProducts: JSON.stringify(values.FestProducts)
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  toast.success(response.data.Error)
                  actions.setSubmitting(false)
                  actions.resetForm()
                  if (actionMode === "Edit") {
                     festivalYearList(authToken, values.CalendarYear, toast)
                  }
               } else {
                  actions.setSubmitting(false)
                  toast.warn(response.data.Error);
               }
            }
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            if (actionMode === "Edit") {
               toast.warn(error && error.response.data.Message)
            } else if (actionMode === "Add") {
               toast.warn(error && error.response.data.Message)
            } else {
               toast.error("Server connection failed. Ensure network connection")
            }
            console.log(error.response);
         });
   }
}


// Delete User
export function deleteUser(values, setOpenConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(actionMode)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_delete'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: GET_USERDELETE_URL,
         data: {
            Mode: actionMode === 'Delete' ? 'D' : actionMode === 'StatusInactive' ? 'I' : actionMode === 'ReActive' ? 'R' : null,
            UserID: values,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'CREATE_USER',
                     payload: response.data
                  })
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'user_delete',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "user_delete",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_delete",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}

// Password Change
export function changePassword(rowId, setOpenDeleteConfirm, actionMode, authToken) {
   return async function (dispatch) {
      // console.log(rowId, authToken)
      dispatch({
         type: 'LOADING_STATUS',
         payload: {
            status: true,
            string: 'user_delete'
         }
      })
      dispatch({
         type: 'MESSAGE',
         payload: false
      })
      const hashedPassword = new Buffer(`bm_secKey/2020/*${'password'}`).toString('base64');

      axios({
         method: 'POST',
         headers: {
            Authorization: 'Bearer ' + authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
         },
         url: GET_CHANGE_PASSWORD_URL,
         data: {
            Mode: 'R',
            NewPw: hashedPassword,
            UserID: rowId,
         }
      })
         .then(function (response) {
            // console.log('Auth Action=================', response);
            if (response.status === 200) {
               if (response.data.Result > 0) {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'success',
                        status: true
                     }
                  })
                  setOpenDeleteConfirm(false)
                  window.location.reload();
               } else {
                  dispatch({
                     type: 'LOADING_STATUS',
                     payload: {
                        status: false,
                        string: 'user_delete'
                     }
                  })
                  dispatch({
                     type: 'MESSAGE',
                     payload: {
                        message: response.data.Error,
                        string: 'user_delete',
                        msgType: 'error',
                        status: true
                     }
                  })
               }
            } else {
               dispatch({
                  type: 'LOADING_STATUS',
                  payload: {
                     string: 'user_delete',
                     status: false
                  }
               })
               dispatch({
                  type: 'MESSAGE',
                  payload: {
                     message: response.data.Error,
                     string: "user_delete",
                     msgType: 'error',
                     status: true
                  }
               })
            }
         })
         .catch(function (error) {
            dispatch({
               type: 'LOADING_STATUS',
               payload: false
            })
            dispatch({
               type: 'MESSAGE',
               payload: {
                  message: "Server connection failed. Ensure network connection",
                  string: "user_delete",
                  msgType: 'error',
                  status: true
               }
            })
            console.log(error);
         });
   }
}
