import React from 'react';
import { Container, Button, Modal, Dropdown, Form, Message, Icon, Confirm, Input, Popup, Segment, Dimmer, Loader } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { getTaxClass } from '../Redux/Actions/TaxClassAction'
import { clearMessage } from '../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';

const TaxMaster = () => {

   const inputRef = React.useRef();

   const authToken = localStorage.getItem('accessToken');
   const dispatch = useDispatch()
   React.useEffect(() => {
      dispatch(getTaxClass(authToken))
      inputRef.current && inputRef.current.focus();
   }, [])


   const filteredItems = useSelector(state => state.taxClassReducer.taxClassListPayload)
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   const isPageLoading = loadingData && loadingData.string === 'user_list' ? loadingData.status : false


   const columns = [
      {
         name: '#',
         selector: 'TaxClassID',
         sortable: true,
      },
      {
         name: 'CGST Percentage',
         selector: 'CGSTPer',
         sortable: true,
      },
      {
         name: 'SGST Percentage',
         selector: 'SGSTPer',
         sortable: true,
      }
   ];

   const customStyles = {
      headCells: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: '700',
         },
      }
   }


   return (
      <div>
         <Container fluid>
            <DataTable
               title='Tax Master'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               pagination={true}
               paginationResetDefaultPage={true}
              //  subHeader
              //  subHeaderComponent={openFilter ? advanceSearchComponent : searchComponent}
               persistTableHead
               progressPending={isPageLoading}
               progressComponent={
                  <Segment>
                     <Dimmer active inverted>
                        <Loader size='large'>Loading</Loader>
                     </Dimmer>
                  </Segment>
               }
            />
         </Container>
      </div>
   )
}

export default TaxMaster