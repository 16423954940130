import React from 'react'
import {
    Button,
    Modal,
    Form
} from 'semantic-ui-react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux';
import { changeOrderStatus } from '../../Redux/Actions/OrdersAction';
import { openCompleteModal } from '../../Redux/Actions/ButtonAction';

export default function OrderCompleteModal(props) {
    const {InvoiceID, authToken} = props
    const dispatch = useDispatch();
    // const authToken = localStorage.getItem('accessToken');
    const isCompleteOpen = useSelector(state => state.buttonReducer.completeModalPayload)
    // console.log(InvoiceID, authToken);
    return (
        <div>
            <Modal open={isCompleteOpen} size='tiny'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        Mode: "X",
                        InvoiceID: InvoiceID,
                        AWBNo: "",
                        comment: "",
                        RP_OrderID: "",
                        RP_PaymentID: "",
                    }}
                    onSubmit={(values, actions) => { dispatch(changeOrderStatus(values, actions, authToken)) }}
                // onSubmit={(values) => { console.log(values) }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <React.Fragment>
                            <Modal.Header>{`Order Delivered & Closed`}</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Input
                                        error={errors.comment && touched.comment ? { content: errors.comment } : false}
                                        fluid
                                        label='Comment'
                                        placeholder='Comment'
                                        type="text"
                                        value={values.comment}
                                        autoFocus
                                        onChange={handleChange('comment')}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => (dispatch(openCompleteModal(false)))}>
                                    Close
                                </Button>
                                <Button
                                    content="Update"
                                    type="submit"
                                    labelPosition='right'
                                    icon='checkmark'
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    positive
                                />
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}
