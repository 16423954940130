import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Segment, Image, Button, Grid } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux';
import logoImage from '../../Resources/images/logo.jpg'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { loginAction } from '../../Redux/Actions/AuthAction'

const Login = ({ history }) => {

   useEffect(()=>{
      localStorage.clear();
   },[])
   
   const dispatch = useDispatch()
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   const isLoading = loadingData && loadingData.string === 'admin_login' ? loadingData.status : false
   const messageData = useSelector(state => state.messageReducer.messagePayload);

   // console.log(messageData)

   const loginSchema = Yup.object().shape({
      UserName: Yup.string()
         .min(2, 'User name is too short!')
         .max(50, 'User name is too Long!')
         .required('User name is required'),
      Password: Yup.string()
         .min(2, 'Password is too Too short!')
         .max(50, 'Password is too long!')
         .required('Password is required')
   });

   return (
      <Segment className="login-body" placeholder>
         <div className="login-form-content">
            <Image className="login-logo" src={logoImage} />
            <Formik
               initialValues={{
                  UserName: '',
                  Password: ''
               }}
               validationSchema={loginSchema}
               onSubmit={values => dispatch(loginAction(values, history))}>
               {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
               }) => (
                     <Form>
                        <h4 style={{color:'red'}}>{messageData && messageData.message}</h4>
                        <Form.Input
                           error={errors.UserName && touched.UserName ? { content: errors.UserName, pointing: 'below' }: false}
                           fluid
                           autoFocus
                           label='Username'
                           placeholder='Username'
                           type="text"
                           value={values.UserName}
                           onChange={handleChange('UserName')}
                        />
                        <Form.Input
                           error={errors.Password && touched.Password ? { content: errors.Password, pointing: 'below' }: false}
                           fluid
                           label='Password'
                           placeholder='Password'
                           type="password"
                           value={values.Password}
                           onChange={handleChange('Password')}
                        />
                        
                        <Grid.Row className="login-button-container">
                           <Link to='/forgot-password'>Forgot password?</Link>
                           <Form.Button
                              size='large'
                              className="login-button"
                              positive
                              component={Link}
                              type="submit"
                              loading={isLoading}
                              onClick={handleSubmit}
                           >
                              <i aria-hidden="true" className="sign-in icon"></i>
                           Login
                           </Form.Button>
                        </Grid.Row>
                     </Form>
                  )}
            </Formik>
         </div>
      </Segment>
   )
}

export default Login