import React from 'react'
import {
   Dropdown,
   Icon,
   Menu,
} from 'semantic-ui-react'
import { useHistory, Link, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { logoutAction } from '../Redux/Actions/AuthAction'
import { toggleMenu } from "../Redux/Actions/ButtonAction";

const AppHeader = (props) => {

   let history = useHistory();
   const dispatch = useDispatch()
   const authToken = localStorage.getItem('accessToken');

   const options = [
      { key: 'user', text: 'Account', icon: 'user' },
      { key: 'settings', text: 'Settings', icon: 'settings' },
      { key: 'sign-out', text: 'Sign Out', icon: 'sign out' },
   ]

   const [toggle, setToggle] = React.useState(true);

   const handleToggleMenu = () => {
      setToggle(toggle => !toggle)
      dispatch(toggleMenu(toggle))
   }

   return (
      <React.Fragment>
         <Menu secondary className="app-header">
            <Menu.Item
               onClick={handleToggleMenu}
               name='home'>
               <Icon name={props.isActiveSidebar ? 'indent' : 'outdent'} color='grey' size='large' />
            </Menu.Item>
            {/* <Menu.Item
               name='Manage Order'
               as={NavLink}
               to="/Order"
            />
            <Menu.Item
               name='Manage Request'
               as={NavLink}
               to="/Enquiry"
            />
            <Menu.Item
               name='Product'
               as={NavLink}
               to="/Product"
            /> */}
            <Menu.Menu position='right'>
               {/* <Menu.Item onClick={() => dispatch(logoutAction(authToken, history))}>
                  Logout
               </Menu.Item> */}
               <Menu.Item>
                  <Dropdown
                     trigger={<Icon name='braille' color='grey' size='large' />}
                     // options={options}
                     pointing='top right'
                     icon={null}
                  >
                     <Dropdown.Menu>
                        <Dropdown.Item text='Change Password' icon="star" onClick={() => history.push('ChangePassword')} />
                        <Dropdown.Item text='Logout' icon="sign out" onClick={() => dispatch(logoutAction(authToken, history))} />
                     </Dropdown.Menu>
                  </Dropdown>

               </Menu.Item>
            </Menu.Menu>
         </Menu>
      </React.Fragment>
   )
}

export default AppHeader