import React from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppContent from './AppContent';
import IdleTimer from 'react-idle-timer'
import { Transition } from 'semantic-ui-react'
import { ToastContainer } from 'react-toastify';
const Layout = () => {

  const history = useHistory()

  function exampleReducer(state, action) {
    switch (action.type) {
      case 'CHANGE_ANIMATION':
        return { ...state, animation: action.animation, visible: !state.visible }
      default:
        throw new Error()
    }
  }
  const isActiveSidebar = useSelector(state => state.buttonReducer.toggleMenuPayload)
  const [state] = React.useReducer(exampleReducer, {
    animation: 'slide along',
    direction: 'left',
    dimmed: false,
    visible: true,
  })

  const { animation, direction, visible } = state

  const menuList = useSelector(state => state.menuReducer.menuPayload)

  const idleTimerRef = React.useRef(null)


  const sessionLogout = () => {
    history.push('/login')
    localStorage.clear();
  }

  return (
    <IdleTimer ref={idleTimerRef} timeout={1200 * 1000} onIdle={() => sessionLogout()} >
      <div className="default-container">
        <Transition.Group animation="fade right" duration={500}>
          {!isActiveSidebar && !isActiveSidebar &&
            <div className="sidebar-container">
              {/* <div className={isActiveSidebar && isActiveSidebar ? "sidebar-container inActive" : "sidebar-container"}> */}
              <AppSidebar
                animation={animation}
                direction={direction}
                visible={visible}
                routes={menuList && menuList} />
            </div>}
        </Transition.Group>
        <div className="content-wrapper">
          <AppHeader isActiveSidebar={isActiveSidebar} />
          <div className="content-body">
            <AppContent />
          </div>
          <AppFooter />
        </div>
      </div>
      <ToastContainer />
    </IdleTimer>
  );
}

export default Layout