import React, { useEffect } from 'react'
import {
    Button,
    Divider,
    Grid,
    Header,
    Icon,
    Search,
    Segment,
    Form,
} from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { changePasswordAction, getProfile } from '../../Redux/Actions/AuthAction';

const ChangePassword = () => {
    const dispatch = useDispatch()
    const accessToken = localStorage.getItem('accessToken')
    const history = useHistory();
    useEffect(() => {
        dispatch(getProfile(accessToken))
    }, [])
    const userProfile = useSelector(state => state.authReducer.profilePayload)
    console.log(userProfile?.ID);

    const loginSchema = Yup.object().shape({
        OldPw: Yup.string()
            .min(2, 'Old password is too short!')
            .max(50, 'Old password is too Long!')
            .required('Old password is required'),
        NewPw: Yup.string()
            .min(2, 'New password is too Too short!')
            .max(50, 'New password is too long!')
            .required('New password is required')
    });
    return (
        <Segment placeholder>
            <Grid columns={2} textAlign='center'>
                {/* <Divider vertical primary /> */}
                <div style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 0,
                    bottom: 0,
                    left: "50%",
                    borderLeft: '1px solid rgba(34,36,38,.15)',
                    borderRight: '1px solid rgba(255,255,255,.1)',
                    height: '100%'
                }} />
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column >
                        <Header icon>
                            <Icon name='user' />
                            {userProfile?.FirstName} {userProfile?.LastName}
                            { }
                        </Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Formik
                            initialValues={{
                                Mode: "C",
                                OldPw: '',
                                NewPw: '',
                                UserID: userProfile?.ID
                            }}
                            enableReinitialize
                            validationSchema={loginSchema}
                            onSubmit={(values, actions) => dispatch(changePasswordAction(values, actions, history, accessToken))}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <Form>
                                    <Form.Input
                                        error={errors.OldPw && touched.OldPw ? { content: errors.OldPw, pointing: 'below' } : false}
                                        fluid
                                        autoFocus
                                        label='Old Password'
                                        placeholder='Old Password'
                                        type="password"
                                        value={values.OldPw}
                                        onChange={handleChange('OldPw')}
                                    />
                                    <Form.Input
                                        error={errors.NewPw && touched.NewPw ? { content: errors.NewPw, pointing: 'below' } : false}
                                        fluid
                                        label='New Password'
                                        placeholder='New Password'
                                        type="password"
                                        value={values.NewPw}
                                        onChange={handleChange('NewPw')}
                                    />

                                    <Grid.Row>
                                        <Form.Button
                                            size='large'
                                            positive
                                            style={{ width: '100%' }}
                                            component={Link}
                                            type="submit"
                                            loading={isSubmitting}
                                            onClick={handleSubmit}
                                        >
                                            <i aria-hidden="true" className="sign-in icon"></i>
                                            Change Password
                                        </Form.Button>
                                    </Grid.Row>
                                </Form>
                            )}
                        </Formik>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default ChangePassword
