import React, { useState } from 'react';
import { Container, Button, Modal, Dropdown, Form, Message, Icon, Confirm, Input, Popup, Segment, Dimmer, Loader, Select } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { getUserList, getUserRole, createUser, deleteUser, changePassword } from '../../Redux/Actions/UsersAction'
import { getFestivalList, festivalYearList, createFestivalProduct } from '../../Redux/Actions/FestAction'
import { getProduct } from '../../Redux/Actions/ProductAction'
import { clearMessage } from '../../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import ExpandedComponent from "./ExpandedComponent";
import _ from 'lodash'
import { useLocation } from 'react-router-dom';

const UserMaster = () => {

   const inputRef = React.useRef();

   const dispatch = useDispatch()
   const [year, setYear] = useState(moment().format('Y'))
   const [isLoading, setIsLoading] = React.useState(false)
   const [productDataList, setProductData] = React.useState([])
   const currentYear = moment().format('Y');
   const nextYear = moment().add(1, 'y').format('Y');
   const authToken = localStorage.getItem('accessToken');

   React.useEffect(() => {
      dispatch(getFestivalList(authToken, toast))
      dispatch(festivalYearList(authToken, year, toast))
      dispatch(getProduct(authToken, 0))
      inputRef.current && inputRef.current.focus();
   }, [])


   let location = useLocation();
   const menuList = useSelector(state => state.menuReducer.menuPayload)
   const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)

   const [openAddModal, setOpenAddModal] = React.useState(false)
   const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
   const [rowId, setRowId] = React.useState(false)
   const festivalData = useSelector(state => state.festReducer.festListPayload)
   const festYearList = useSelector(state => state.festReducer.festYearListPayload)
   const userList = useSelector(state => state.userListReducer.userListPayload)
   const userRoleData = useSelector(state => state.userListReducer.userRolePayload)
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   const isPageLoading = loadingData && loadingData.string === 'user_list' ? loadingData.status : false
   const messageData = useSelector(state => state.messageReducer.messagePayload);
   const messageStatus = messageData && messageData.string === 'user_save' ? messageData.status : false

   const festivalList = festivalData && festivalData.map((item) => {
      return {
         key: item.FestivalID,
         text: item.FestivalName,
         value: item.FestivalID
      }
   })

   const productData = useSelector(state => state.productReducer.productPayload)
   const productList = productData && productData.filter(item => item.FestStatus === true).map((data, index) => {
      return {
         key: index,
         text: data.ProductName,
         value: data.ProductID
      }
   })

   const renderLabel = (label) => ({
      color: 'blue',
      content: label.text,
      icon: 'check',
   })

   const handleProductSelect = (value, setFieldValue) => {
      const data = value.map(item => {
         return {
            fk_ProdID: item
         }
      })
      setFieldValue("FestProducts", data)
   }

   const [actionMode, setActionMode] = React.useState('');
   const [openFilter, setOpenFilter] = React.useState(false);
   const [productId, setProductId] = React.useState([]);
   const [searchInput, setSearchInput] = React.useState('');
   const [firstInput, setFirstInput] = React.useState('');
   const [secondInput, setSecondInput] = React.useState('');
   const [thirdInput, setThirdInput] = React.useState('');
   const [fourthInput, setFourthInput] = React.useState('');
   const [fifthInput, setFifthInput] = React.useState('');
   const [sixthInput, setSixthInput] = React.useState('');


   const filteredItems = festYearList && festYearList.pdata.festListForProd.filter(item => (
      openFilter ?
         item.FestName.toLowerCase().includes(firstInput.toLowerCase()) :
         // item.LastName.toLowerCase().includes(secondInput.toLowerCase()) &&
         // item.UserName.toLowerCase().includes(thirdInput.toLowerCase()) &&
         // item.RoleName.toLowerCase().includes(fourthInput.toLowerCase()) &&
         // item.MobileNo.toLowerCase().includes(fifthInput.toLowerCase()) &&
         // item.EmailID.toLowerCase().includes(sixthInput.toLowerCase()) :
         item.FestName.toLowerCase().includes(searchInput.toLowerCase())
      // item.LastName.toLowerCase().includes(searchInput.toLowerCase()) ||
      // item.UserName.toLowerCase().includes(searchInput.toLowerCase()) ||
      // item.RoleName.toLowerCase().includes(searchInput.toLowerCase()) ||
      // item.EmailID.toLowerCase().includes(searchInput.toLowerCase()) ||
      // item.MobileNo.toLowerCase().includes(searchInput.toLowerCase())
   ));


   const actions = <React.Fragment>
      <Button
         color='violet'
         onClick={() => setOpenFilter(openFilter => !openFilter)}>
         <Icon name='filter' />
         Advance Filter
      </Button>
      {rollPermission && rollPermission.Canadd &&
         <Button
            primary
            onClick={() => (setOpenAddModal(true), setActionMode('Add'))}>
            <Icon name='add user' />
            Add New
         </Button>}
   </React.Fragment>;

   const searchComponent = <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />

   const getDropdownText = (value) => {
      const data = festivalList.find(o => o.value === value);
      data ? setFourthInput(data.text) : setFourthInput('')
      // console.log(data)
   }
   const advanceSearchComponent = <div className="subheader-searchbar">
      <Input autoFocus placeholder='First Name' value={firstInput} onChange={e => setFirstInput(e.target.value)} />
      <Input placeholder='Last Name' value={secondInput} onChange={e => setSecondInput(e.target.value)} />
      <Input placeholder='User Name' value={thirdInput} onChange={e => setThirdInput(e.target.value)} />
      <Dropdown
         placeholder='User Type'
         selection
         clearable
         options={festivalList}
         defaultValue={fourthInput}
         onChange={(e, { value }) => getDropdownText(value)}
      />
      <Input placeholder='Mobile No.' value={fifthInput} onChange={e => setFifthInput(e.target.value)} />
      <Input placeholder='Email Id.' value={sixthInput} onChange={e => setSixthInput(e.target.value)} />
   </div>

   const conditionalRowStyles = [
      {
         when: row => row.UserStat === 'Inactive',
         style: {
            backgroundColor: '#f3f3f3',
            color: 'rgb(132 132 132 / 87%)'
         },
      }
   ]
   const columns = [
      {
         name: 'Fest Name',
         selector: 'FestName',
         sortable: true,
      },
      {
         name: 'Fest Start Dt.',
         selector: 'FestStartDate',
         cell: (data) => moment(data.FestStartDate).format('DD/MM/YYYY'),
         sortable: true,
      },
      {
         name: 'Fest End Dt.',
         selector: 'FestEndDate',
         cell: (data) => moment(data.FestEndDate).format('DD/MM/YYYY'),
         sortable: true,
      },
      {
         name: 'Order Start Dt.',
         selector: 'OrderStartDate',
         cell: (data) => moment(data.OrderStartDate).format('DD/MM/YYYY'),
         sortable: true,
      },
      {
         name: 'Order End Dt.',
         selector: 'OrderEndDate',
         cell: (data) => moment(data.OrderEndDate).format('DD/MM/YYYY'),
         sortable: true,
      },
      {
         name: 'Action',
         sortable: false,
         right: true,
         cell: (data) => <React.Fragment>
            {data.UserName !== 'admin' ?
               <Button.Group basic size='small'>
                  {rollPermission && rollPermission.Candelete &&
                     <Popup
                        trigger={
                           <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('Delete'))}>
                              <Icon style={{ color: 'red' }} name='trash alternate outline' />
                           </Button>}
                        content='Delete'
                        position='top right'
                     />}
                  {rollPermission && rollPermission.Canedit &&
                     <React.Fragment>
                        {data.UserStat === 'Inactive' ?
                           <Popup
                              trigger={
                                 <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('ReActive'))}>
                                    <Icon style={{ color: '#ff8d00' }} name='lock' />
                                 </Button>}
                              content='Make Active'
                              position='top right'
                           /> : <Popup
                              trigger={
                                 <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.UserID), setActionMode('StatusInactive'))}>
                                    <Icon style={{ color: '#ff8d00' }} name='lock open' />
                                 </Button>}
                              content='Make Inactive'
                              position='top right'
                           />}
                        <Popup
                           trigger={
                              <Button icon onClick={() => (setOpenAddModal(true), setActionMode('Edit'))}>
                                 <Icon style={{ color: '#001fff' }} name='edit outline' />
                              </Button>}
                           content='Edit'
                           position='top right'
                        />
                     </React.Fragment>}
               </Button.Group> : null}
         </React.Fragment>,
      },
   ];

   const customStyles = {
      header: {
         style: {
            minHeight: '40px',
         },
      },
      headRow: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            height: '10px',
            minHeight: '40px'
         },
      },
      headCells: {
         style: {
            color: '#FFF',
            fontSize: '15px',
            fontWeight: '700',
            padding: '10px'
         },
      }
   }

   // let input = null

   setTimeout(() => {
      dispatch(clearMessage())
   }, 5000)

   return (
      <div>
         <Container fluid>
            <DataTable
               title='Festival Products'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               actions={actions}
               pagination={true}
               paginationResetDefaultPage={true}
               subHeader
               subHeaderComponent={openFilter ? advanceSearchComponent : searchComponent}
               conditionalRowStyles={conditionalRowStyles}
               persistTableHead
               expandableRows
               expandableRowsComponent={<ExpandedComponent />}
               progressPending={isPageLoading}
               progressComponent={
                  <Segment>
                     <Dimmer active inverted>
                        <Loader size='large'>Loading</Loader>
                     </Dimmer>
                  </Segment>
               }
            />
            <Modal open={openAddModal} size='small'>
               <Formik
                  enableReinitialize
                  initialValues={actionMode === "Add" ? {
                     Mode: "A",
                     FestYrID: 0,
                     FestID: 0,
                     CalendarYear: currentYear,
                     OrderStartDate: new Date(),
                     OrderEndDate: new Date(),
                     FestStartDate: new Date(),
                     FestEndDate: new Date(),
                     FestProducts: productId
                  } :
                     {
                        Mode: "E",
                        FestYrID: 4,
                        FestID: 5,
                        CalendarYear: "2021",
                        OrderStartDate: "2021-08-02T23:08:54.7798582+05:30",
                        OrderEndDate: "2021-08-16T23:08:54.7798582+05:30",
                        FestStartDate: "2021-08-15T23:08:54.7798582+05:30",
                        FestEndDate: "2021-08-15T23:08:54.7798582+05:30",
                        FestProducts: "5,6"
                     }
                  }
                  // validationSchema={userSchema}
                  onSubmit={(values, actions) => { dispatch(createFestivalProduct(values, actions, toast, actionMode, authToken)) }}
               // onSubmit={(values, actions) => console.log(values)}
               >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     isSubmitting,
                     handleSubmit,
                     setFieldValue
                  }) => (
                     <React.Fragment>
                        <Modal.Header>{actionMode === "Edit" ? 'Edit Festival' : 'Create Festival'}</Modal.Header>
                        <Modal.Content>
                           {messageStatus && <Message error header={messageData.message} />}
                           <Form>
                              <Form.Group>
                                 <Form.Field width={6}>
                                    <label>Year</label>
                                    <Dropdown
                                       error={errors.CalendarYear && touched.CalendarYear}
                                       placeholder='Select festival year'
                                       selection
                                       search
                                       name="CalendarYear"
                                       onChange={(event, data) => {
                                          setFieldValue('CalendarYear', data.value)
                                       }}
                                       defaultValue={values.CalendarYear}
                                       options={[
                                          { key: 1, text: currentYear, value: currentYear },
                                          { key: 2, text: nextYear, value: nextYear }
                                       ]}
                                    />
                                 </Form.Field>
                                 <Form.Field width={10}>
                                    <label>Year</label>
                                    <Dropdown
                                       error={errors.FestID && touched.FestID}
                                       placeholder='Select festival year'
                                       selection
                                       search
                                       name="FestID"
                                       onChange={(event, data) => {
                                          setFieldValue('FestID', data.value)
                                       }}
                                       defaultValue={values.FestID}
                                       options={festivalList}
                                    />
                                 </Form.Field>
                                 {/* <Form.Field width={10}>
                                    <label>Festival Name</label>
                                    <Dropdown
                                       error={errors.FestID && touched.FestID}
                                       placeholder='Select user type'
                                       selection
                                       search
                                       name="FestID"
                                       onChange={(event, data) => {
                                          setFieldValue('FestID', data.value)
                                          console.log(data.value);
                                       }}
                                       defaultValue={values.FestID}
                                       options={festivalList}
                                    />
                                 </Form.Field> */}
                              </Form.Group>
                              <Form.Group widths='equal'>
                                 <Form.Field className="fest-date-picker">
                                    <label>Festival Start Date</label>
                                    <DatePicker
                                       dateFormat="dd-MM-yyyy"
                                       todayButton="Today"
                                       selected={values.FestStartDate}
                                       showMonthDropdown
                                       showYearDropdown
                                       onChange={date => (
                                          setFieldValue('FestStartDate', date)
                                       )}
                                       customInput={
                                          <Input placeholder='DD-MM-YYYY' value={values.FestStartDate} action={{ icon: 'calendar alternate outline' }} />
                                       } />
                                 </Form.Field>
                                 <Form.Field className="fest-date-picker">
                                    <label>Festival End Date</label>
                                    <DatePicker
                                       dateFormat="dd-MM-yyyy"
                                       todayButton="Today"
                                       selected={values.FestEndDate}
                                       showMonthDropdown
                                       showYearDropdown
                                       onChange={date => (
                                          setFieldValue('FestEndDate', date)
                                       )}
                                       customInput={
                                          <Input placeholder='DD-MM-YYYY' value={values.FestEndDate} action={{ icon: 'calendar alternate outline' }} />
                                       } />
                                 </Form.Field>
                              </Form.Group>
                              <Form.Group widths='equal'>
                                 <Form.Field className="fest-date-picker">
                                    <label>Order Start Date</label>
                                    <DatePicker
                                       dateFormat="dd-MM-yyyy"
                                       todayButton="Today"
                                       selected={values.OrderStartDate}
                                       showMonthDropdown
                                       showYearDropdown
                                       onChange={date => (
                                          setFieldValue("OrderStartDate", date)
                                       )}
                                       customInput={
                                          <Form.Input placeholder='DD-MM-YYYY' value={values.OrderStartDate} action={{ icon: 'calendar alternate outline' }} />
                                       } />
                                 </Form.Field>
                                 <Form.Field className="fest-date-picker">
                                    <label>Order End Date</label>
                                    <DatePicker
                                       dateFormat="dd-MM-yyyy"
                                       todayButton="Today"
                                       selected={values.OrderEndDate}
                                       showMonthDropdown
                                       showYearDropdown
                                       onChange={date => (
                                          setFieldValue("OrderEndDate", date)
                                       )}
                                       customInput={
                                          <Input placeholder='DD-MM-YYYY' value={values.OrderEndDate} action={{ icon: 'calendar alternate outline' }} />
                                       } />
                                 </Form.Field>
                              </Form.Group>
                              <Form.Field>
                                 <label>Products for Festival</label>
                                 <Dropdown
                                    placeholder="Festival Product"
                                    fluid
                                    multiple
                                    selection
                                    search
                                    options={productList}
                                    name="FestProducts"
                                    renderLabel={renderLabel}
                                    onChange={(e, { value }) => {
                                       const data = value.map(item => {
                                          return {
                                             fk_ProdID: item
                                          }
                                       })
                                       setFieldValue("FestProducts", data)
                                    }}
                                 />
                              </Form.Field>
                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button negative onClick={() => (dispatch(festivalYearList(authToken, year, toast)), setOpenAddModal(false))}>
                              Close
                           </Button>
                           <Button
                              content={actionMode === "Edit" ? "Save" : "Create"}
                              type="submit"
                              labelPosition='right'
                              icon='checkmark'
                              loading={isSubmitting}
                              onClick={handleSubmit}
                              positive
                           />
                        </Modal.Actions>
                     </React.Fragment>
                  )}
               </Formik>
            </Modal>
            <Confirm
               header='Are you sure?'
               content={
                  actionMode === 'Delete' ? 'Data will lost' :
                     actionMode === 'StatusInactive' ? 'Data will inactive.' :
                        actionMode === 'ReActive' ? 'Data will active?' :
                           actionMode === 'Reset' ? 'Password will reset as default' : null
               }
               open={openDeleteConfirm}
               onCancel={() => setOpenDeleteConfirm(false)}
               onConfirm={() => {
                  actionMode === 'Reset' ?
                     dispatch(changePassword(rowId, setOpenDeleteConfirm, actionMode, authToken)) :
                     dispatch(deleteUser(rowId, setOpenDeleteConfirm, actionMode, authToken))
               }}
               size='mini'
            />
         </Container>
      </div>
   )
}

export default UserMaster