import React from 'react'

const Transaction = () => {
   return(
      <div>
         <h1>Transaction</h1>
      </div>
   )
}

export default Transaction