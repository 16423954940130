import React from 'react';
import { Container, Button, Modal, Form, Message, Icon, Confirm, Input, Popup } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import {
   getBannerTypeAction,
   saveBannerCategoryAction,
   statusBannerCategoryAction,
} from '../../Redux/Actions/BnnerAction'
import { clearMessage } from '../../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TableLoader from "../../Components/TableLoader";

const BannerMaster = () => {

   const inputRef = React.useRef();

   const dispatch = useDispatch()
   React.useEffect(() => {
      dispatch(getBannerTypeAction(authToken))
      inputRef.current && inputRef.current.focus();
   }, [])


   const location = useLocation();
   const menuList = useSelector(state => state.menuReducer.menuPayload)
   const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)

   const authToken = localStorage.getItem('accessToken');
   const [openAddModal, setOpenAddModal] = React.useState(false)
   const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
   const [rowId, setRowId] = React.useState(false)
   const bannerTypeData = useSelector(state => state.bannerReducer.bannerTypeListPayload)
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   const isPageLoading = loadingData && loadingData.string === 'banner_category_list' ? loadingData.status : false
   const messageData = useSelector(state => state.messageReducer.messagePayload);
   const messageStatus = messageData && messageData.string === 'banner_category_save' ? messageData.status : false

   const [actionMode, setActionMode] = React.useState('');
   const [userData, setUserData] = React.useState([]);
   const [searchInput, setSearchInput] = React.useState('');

   const filteredItems = bannerTypeData && bannerTypeData.filter(item => (
      item.CategoryName.toLowerCase().includes(searchInput.toLowerCase())
   ));

   const searchComponent = <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />
   // console.log(filteredItems)

   const actions = <React.Fragment>
      {rollPermission && rollPermission.Canadd &&
         <Button
            primary
            onClick={() => (setOpenAddModal(true), setActionMode('Add'))}>
            <Icon name='add user' />
            Add New
         </Button>}
   </React.Fragment>;

   const conditionalRowStyles = [
      {
         when: row => row.BannerCatStat === 'InActive',
         style: {
            backgroundColor: '#f3f3f3',
            color: 'rgb(132 132 132 / 87%)'
         },
      }
   ]
   const columns = [
      {
         name: 'Banner category Name',
         selector: 'CategoryName',
         sortable: true,
      },
      {
         name: 'Action',
         sortable: false,
         right: true,
         cell: (data) => <React.Fragment>
            <Button.Group basic size='small'>
               {rollPermission && rollPermission.Candelete &&
                  <Popup
                     trigger={
                        <Button icon onClick={() => (
                           setOpenDeleteConfirm(true),
                           setRowId(data.BannerCategoryID),
                           setActionMode('Delete'))}>
                           <Icon style={{ color: 'red' }} name='trash alternate outline' />
                        </Button>}
                     content='Delete'
                     position='top right'
                  />}
               {rollPermission && rollPermission.Canedit &&
                  <React.Fragment>
                     {data.BannerCatStat === 'InActive' ? <Popup
                        trigger={
                           <Button icon onClick={() => (
                              setOpenDeleteConfirm(true),
                              setRowId(data.BannerCategoryID),
                              setActionMode('ReActive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock' />
                           </Button>}
                        content='Make Active'
                        position='top right'
                     /> : <Popup
                        trigger={
                           <Button icon onClick={() => (
                              setOpenDeleteConfirm(true),
                              setRowId(data.BannerCategoryID),
                              setActionMode('InActive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock open' />
                           </Button>}
                        content='Make InActive'
                        position='top right'
                     />}
                     <Popup
                        trigger={
                           <Button icon onClick={() => (
                              setUserData(data),
                              setOpenAddModal(true),
                              setActionMode('Edit'))}>
                              <Icon style={{ color: '#001fff' }} name='edit outline' />
                           </Button>}
                        content='Edit'
                        position='top right'
                     />
                  </React.Fragment>}
            </Button.Group>
         </React.Fragment>,
      },
   ];

   const customStyles = {
      headCells: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: '700',
         },
      }
   }

   const userSchema = Yup.object().shape({
      CategoryName: Yup.string()
         .min(2, 'User name is too short!')
         .max(50, 'User name is too Long!')
         .required('User name is required'),
   });

   // let input = null

   setTimeout(() => {
      dispatch(clearMessage())
   }, 5000)

   return (
      <div>
         <Container fluid>
            <DataTable
               title='Banner Type'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               actions={actions}
               pagination={true}
               paginationResetDefaultPage={true}
               conditionalRowStyles={conditionalRowStyles}
               persistTableHead
               subHeader
               subHeaderComponent={searchComponent}
               progressPending={isPageLoading}
               progressComponent={<TableLoader />}
            />
            <Modal open={openAddModal} size='tiny'>
               <Formik
                  enableReinitialize
                  initialValues={actionMode === "Add" ? {
                     Mode: "A",
                     BannerCategoryID: 0,
                     CategoryName: ""
                  } :
                     {
                        Mode: "E",
                        BannerCategoryID: userData.BannerCategoryID,
                        CategoryName: userData.CategoryName
                     }
                  }
                  validationSchema={userSchema}
                  onSubmit={(values, actions) => { dispatch(saveBannerCategoryAction(values, actions, actionMode, authToken, toast)) }}
               >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     isSubmitting,
                     handleSubmit,
                     setFieldValue
                  }) => (
                     <React.Fragment>
                        <Modal.Header>{actionMode === "Edit" ? 'Edit Banner Type' : 'Create Banner Type'}</Modal.Header>
                        <Modal.Content>
                           {messageStatus && <Message error header={messageData.message} />}
                           <Form>
                              <Form.Input
                                 autoFocus
                                 error={errors.CategoryName && touched.CategoryName ? { content: errors.CategoryName } : false}
                                 fluid
                                 label='Banner Type'
                                 placeholder='Banner Type'
                                 innerRef={inputRef}
                                 type="text"
                                 value={values.CategoryName}
                                 onChange={handleChange('CategoryName')}
                              />
                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button negative onClick={() => (window.location.reload(), setOpenAddModal(false))}>
                              Close
                           </Button>
                           <Button
                              content={actionMode === "Edit" ? "Save" : "Create"}
                              type="submit"
                              labelPosition='right'
                              icon='checkmark'
                              loading={isSubmitting}
                              onClick={handleSubmit}
                              positive
                           />
                        </Modal.Actions>
                     </React.Fragment>
                  )}
               </Formik>
            </Modal>
            <Confirm
               header='Are you sure?'
               content={
                  actionMode === 'Delete' ? 'Data will lost' :
                     actionMode === 'InActive' ? 'Data will inactive.' :
                        actionMode === 'ReActive' ? 'Data will active?' : null
               }
               open={openDeleteConfirm}
               onCancel={() => setOpenDeleteConfirm(false)}
               onConfirm={() => {
                  // console.log(actionMode, rowId)
                  dispatch(statusBannerCategoryAction(rowId, setOpenDeleteConfirm, actionMode, authToken))
               }}
               size='mini'
            />
         </Container>
      </div>
   )
}

export default BannerMaster