export default function (state = {}, action) {
   switch (action.type) {

      case 'GET_BANNERTYPE':
         {
            return {
               ...state,
               bannerTypeListPayload: action.payload
            };
         }
      case 'GET_BANNER':
         {
            return {
               ...state,
               bannerListPayload: action.payload
            };
         }
      default:
         return state;
   }
}
