export const numberFormat = value =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
  }).format(value);

export const numberFormatPdf = value =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
  })
  .formatToParts(value) // returns an array of the different parts of the amount
  .filter(p => p.type != 'currency') // removes the currency part
  .reduce((s, p) => s + p.value, '') // joins the remaining values
  .trim()