import React from 'react'
import { Container, Grid, Header, Dropdown, Button, Table, Confirm } from 'semantic-ui-react'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getDeleveryAgentList, getOrderDetails, } from '../../../Redux/Actions/OrdersAction';
import logo from '../../../Resources/images/logo.jpg';
import moment from 'moment';
import { openCompleteModal, openDispatchModal, openConfirmModal, openCancelModal } from '../../../Redux/Actions/ButtonAction';
import PDFSample from '../../../Components/Reports/PDFSample';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderDispatchModal from '../../../Components/Modals/OrderDispatchModal';
import OrderCompleteModal from '../../../Components/Modals/OrderCompleteModal';
import OrderConfirmModal from '../../../Components/Modals/OrderConfirmModal';
import OrderCancelModal from '../../../Components/Modals/OrderCancelModal';
import { numberFormatPdf } from '../../../Components/NumberFormat'
import AppLoader from '../../../Components/AppLoader';

const OrderDetails = ({ history }) => {
  let { slug } = useParams();
  const dispatch = useDispatch()
  const authToken = localStorage.getItem('accessToken');
  const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
  const isLoading = loadingData && loadingData.status ? loadingData.status : false
  // const [openConfirm, setOpenConfirm] = React.useState({
  //   status: false,
  //   text: "",
  //   value: ""
  // })
  React.useEffect(() => {
    if (slug && slug > 0) {
      dispatch(getOrderDetails(authToken, slug))
      dispatch(getDeleveryAgentList())
    }
  }, [])
  const orderDetailsData = useSelector(state => state.orderReducer.orderDetailPayload)

  // console.log("orderDetailsData", orderDetailsData);

  const options = [
    { key: "S", text: 'Select order status', value: '' },
    { key: "C", text: 'Confirmed Order, Payment Received & Generate Invoice', value: 'C', disabled: (orderDetailsData?.CurrentStatusID === "PF" || orderDetailsData?.CurrentStatusID === "PP") ? false : true },
    { key: "D", text: 'Order Dispatched', value: 'D', disabled: orderDetailsData?.CurrentStatusID === "PF" || orderDetailsData?.CurrentStatusID === "PP" || orderDetailsData?.CurrentStatusID === "DC" || orderDetailsData?.CurrentStatusID === "OC" },
    { key: "X", text: 'Order Delivered & Closed', value: 'X', disabled: orderDetailsData?.CurrentStatusID === "PF" || orderDetailsData?.CurrentStatusID === "PP" || orderDetailsData?.CurrentStatusID === "PR" || orderDetailsData?.CurrentStatusID === "DC" },
    { key: "R", text: 'Cancel Order', value: 'R', disabled: orderDetailsData?.CurrentStatusID === "PF" || orderDetailsData?.CurrentStatusID === "DC" || orderDetailsData?.CurrentStatusID === "OC" }
  ]


  const handleStatus = (value) => {
    // const text = options.find(o => o.value === value);
    // value === "N" 
    // setOpenConfirm({
    //   status: true,
    //   text: `Chang order status in ${text.text}`,
    //   value: value
    // })
    // console.log(value);
    if (value === "C") {
      dispatch(openConfirmModal(true))
    } else if (value === "D") {
      dispatch(openDispatchModal(true))
    } else if (value === "X") {
      dispatch(openCompleteModal(true))
    } else if (value === "R") {
      dispatch(openCancelModal(true))
    }
  }

  return (
    <div>
      { isLoading ?
        <AppLoader /> :
        <Container fluid>
          <Grid>
            <Grid.Column floated='left' width={ 4 }>
              <Header as='h2'>Order Details</Header>
            </Grid.Column>
            <Grid.Column
              floated='right'
              width={ 7 }
              style={ {
                display: "flex",
                justifyContent: "flex-end"
              } }>
              <Dropdown
                placeholder='Change order status'
                style={ { width: '100%' } }
                search
                selection
                disabled={ orderDetailsData?.CurrentStatusID === "DC" || orderDetailsData?.CurrentStatusID === "OC" }
                onChange={ (e, { value }) => handleStatus(value) }
                options={ options } />
            </Grid.Column>
            <Grid.Column
              floated='right'
              width={ 5 }
              style={ {
                display: "flex",
                justifyContent: "flex-end"
              } }>
              <Button
                negative
                icon='arrow alternate circle left'
                content='Back'
                onClick={ () => history.push('/Order') } />
              <PDFDownloadLink document={ <PDFSample data={ orderDetailsData && orderDetailsData } /> } fileName={ `invoice_${orderDetailsData?.BillNo}.pdf` }>
                { ({ blob, url, loading, error }) =>
                  <Button
                    positive
                    icon='print'
                    content='Print Invoice'
                    disabled={ loading }
                    loading={ !Boolean(orderDetailsData?.BillNo) ? loading : false }
                  />
                }
              </PDFDownloadLink>
            </Grid.Column>
          </Grid>
          <Grid columns={ 3 }>
            <Grid.Row>
              <Grid.Column>
                <h3>Payment Status : { orderDetailsData?.PaymentStatus }</h3>
              </Grid.Column>
              <Grid.Column>
                <h3>Order Status : { orderDetailsData?.OrderStatus }</h3>
              </Grid.Column>
              <Grid.Column>
                <h3>Dispatch Through : { orderDetailsData?.DispatchThru }</h3>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="invoice-view">
            <Grid.Column>
              <Table celled padded>
                <Table.Row>
                  <Table.Cell width={ 8 }>
                    <div style={ { textAlign: 'center' } }>
                      <img src={ logo } width={ 300 } height={ 100 } />
                      <h5 style={ { margin: 0 } }>{ orderDetailsData?.CompAddress } { orderDetailsData?.CompCity }</h5>
                      <h5 style={ { margin: 0 } }>GST - { orderDetailsData?.GSTNo }</h5>
                      <h5 style={ { margin: 0 } }>{ "MSME: UDYAM-WB-10-0001628" }</h5>
                      <h5 style={ { margin: 0 } }>FASSAI - { orderDetailsData?.FASSAINo }</h5>
                      <h5 style={ { margin: 0 } }>( INVOICE FOR E-COMMERCE SALES THROUGH WEBSITE WWW.BALARAMMULLICK.COM AT 3A, PADDAPUKUR ROAD, KOLKATA - 700020 )</h5>
                    </div>
                    <div>
                      <Table.Cell width={ 8 }>
                        <p><b>Invoice #:</b> { orderDetailsData?.BillNo }</p>
                        <p><b>Invoice Date #:</b> { moment(orderDetailsData?.BillDate).format('DD-MM-YYYY') }</p>
                      </Table.Cell>
                      <Table.Cell width={ 8 }>
                        <p><b>Order #:</b> { orderDetailsData?.OrderNo }</p>
                        <p><b>Order Date #:</b> { moment(orderDetailsData?.OrderDate).format('DD-MM-YYYY') }</p>
                      </Table.Cell>
                    </div>
                  </Table.Cell>
                  <Table.Cell width={ 8 }>
                    <h4>Address</h4>
                    <p><b>Customer Name</b>: { orderDetailsData?.ShippingName }</p>
                    <p><b>Customer Address</b>: { orderDetailsData?.ShippingAddress }</p>
                    <p><b>City</b>: { orderDetailsData?.ShippingCity }</p>
                    <p><b>State</b>: { orderDetailsData?.ShippingState }</p>
                    <p><b>Phone</b>: { orderDetailsData?.ShippingMobile }</p>
                  </Table.Cell>
                </Table.Row>
              </Table>
              <Table celled padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign='center'>S/N</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>HSN Code</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>ITEM DESCRIPTIONS</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>RATE</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>QTY.</Table.HeaderCell>
                    { orderDetailsData?.CGSTorIGST === "I" ?
                      <>
                        <Table.HeaderCell textAlign='center'>IGST%</Table.HeaderCell>
                      </> :
                      <>
                        <Table.HeaderCell textAlign='center'>CGST%</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>SGST%</Table.HeaderCell>
                      </> }
                    <Table.HeaderCell textAlign='center'>TOTAL</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                { orderDetailsData?.InvItemDetails?.map((item, i) =>
                  <Table.Row key={ i }>
                    <Table.Cell>{ i + 1 }</Table.Cell>
                    <Table.Cell>{ item.HSNCode }</Table.Cell>
                    <Table.Cell>{ item.ProdDescr }</Table.Cell>
                    <Table.Cell textAlign='right'>{ item.BasicAmt }</Table.Cell>
                    <Table.Cell textAlign='center'>{ item.Qty }</Table.Cell>
                    { orderDetailsData?.CGSTorIGST === "I" ?
                      <>
                        <Table.Cell textAlign='right'>{ item.CGSTPer + item.SGSTPer }%</Table.Cell>
                      </> :
                      <>
                        <Table.Cell textAlign='right'>{ item.CGSTPer }%</Table.Cell>
                        <Table.Cell textAlign='right'>{ item.SGSTPer }%</Table.Cell>
                      </> }
                    <Table.Cell textAlign='right'>{ item.TotalAmt }.00</Table.Cell>
                  </Table.Row>) }
              </Table>
              <Table>
                <Table.Row>
                  {/* <Table.Cell collapsing={ 7 }>Including packaging charges wherever applicable.</Table.Cell> */ }
                </Table.Row>
              </Table>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={ 8 }>
                      <p><b>Payment Mode:</b> { orderDetailsData?.PaymentMode }</p>
                      <p><b>Dispatch Through:</b> { orderDetailsData?.DispatchThru }</p>
                      <p><b>Rupees (In Word):</b> { orderDetailsData?.RupeesInWord }</p>
                      { orderDetailsData?.CGSTorIGST === "I" ?
                        <Table celled padded>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>IGST%</Table.HeaderCell>
                              <Table.HeaderCell>IGST Amt.</Table.HeaderCell>
                              <Table.HeaderCell>Total</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          { orderDetailsData?.invGSTDetails?.map((item, i) =>
                            <Table.Row key={ i }>
                              <Table.Cell>{ item.BasicAmt }</Table.Cell>
                              <Table.Cell>{ item.CGSTPer + item.SGSTPer }</Table.Cell>
                              <Table.Cell>{ item.CGSTAmt + item.SGSTAmt }</Table.Cell>
                            </Table.Row>) }
                        </Table> :
                        <Table celled padded>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell textAlign='center'>Basic</Table.HeaderCell>
                              <Table.HeaderCell textAlign='center'>CGST%</Table.HeaderCell>
                              <Table.HeaderCell textAlign='center'>SGST%</Table.HeaderCell>
                              <Table.HeaderCell textAlign='center'>CGST Amt.</Table.HeaderCell>
                              <Table.HeaderCell textAlign='center'>SGST Amt.</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          { orderDetailsData?.invGSTDetails?.map((item, i) =>
                            <Table.Row key={ i }>
                              <Table.Cell textAlign='right'>{ item.BasicAmt }</Table.Cell>
                              <Table.Cell textAlign='right'>{ item.CGSTPer }</Table.Cell>
                              <Table.Cell textAlign='right'>{ item.SGSTPer }</Table.Cell>
                              <Table.Cell textAlign='right'>{ item.CGSTAmt }</Table.Cell>
                              <Table.Cell textAlign='right'>{ item.SGSTAmt }</Table.Cell>
                            </Table.Row>) }
                        </Table> }

                      <h5>THANK YOU</h5>

                    </Table.Cell>
                    <Table.Cell className="invoice-price-summary">
                      <Table.Row>
                        <Table.Cell width={ 14 }>Basic Amount:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.BasicAmt) }</Table.Cell>
                      </Table.Row>
                      { orderDetailsData?.CGSTorIGST === "I" ?
                        <Table.Row>
                          <Table.Cell>IGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.CGSTAmt + orderDetailsData?.SGSTAmt) }</Table.Cell>
                        </Table.Row> :
                        <>
                          <Table.Row>
                            <Table.Cell>SGST:</Table.Cell>
                            <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.SGSTAmt) }</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>CGST:</Table.Cell>
                            <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.CGSTAmt) }</Table.Cell>
                          </Table.Row>
                        </> }
                      {/* <Table.Row>
                        <Table.Cell>Delivery Charges (HSN Code:{ orderDetailsData?.ShippingHSN }):</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.ShippingCharge) }</Table.Cell>
                      </Table.Row> */}
                      {/* { orderDetailsData?.CGSTorIGST === "I" ? <>
                        <Table.Row>
                          <Table.Cell>Delivery IGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.ShippingGST) }</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>IGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.CGSTAmt + orderDetailsData?.SGSTAmt) }</Table.Cell>
                        </Table.Row>
                      </> : <>
                        <Table.Row>
                          <Table.Cell>{ `Delivery GST (CGST - ${numberFormatPdf((orderDetailsData?.ShippingGST) / 2)} + SGST - ${numberFormatPdf((orderDetailsData?.ShippingGST) / 2)})` }:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.ShippingGST) }
                          </Table.Cell>
                        </Table.Row> */}
                      {/* <Table.Row>
                          <Table.Cell>Delivery CGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{numberFormatPdf((orderDetailsData?.ShippingGST) / 2)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Delivery SGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{numberFormatPdf((orderDetailsData?.ShippingGST) / 2)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>SGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{numberFormatPdf(orderDetailsData?.SGSTAmt)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>CGST:</Table.Cell>
                          <Table.Cell collapsing textAlign='right'>{numberFormatPdf(orderDetailsData?.CGSTAmt)}</Table.Cell>
                        </Table.Row> */}
                      {/* </> } */ }
                      <Table.Row>
                        <Table.Cell>Delivery Charges (HSN Code:{ orderDetailsData?.ShippingHSN }):</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.ShippingCharge) }</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Delivery GST { orderDetailsData?.CGSTorIGST === "I" ? `(IGST(${numberFormatPdf(orderDetailsData?.ShippingGSTPer)}%) - ${numberFormatPdf(orderDetailsData?.ShippingGST)}` : `(CGST(${numberFormatPdf(orderDetailsData?.ShippingGSTPer / 2)}%) - ${numberFormatPdf((orderDetailsData?.ShippingGST) / 2)} + SGST(${numberFormatPdf(orderDetailsData?.ShippingGSTPer / 2)}%) - ${numberFormatPdf((orderDetailsData?.ShippingGST) / 2)})` }:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.ShippingGST) }
                        </Table.Cell>
                      </Table.Row>
                      { orderDetailsData?.PackingCharge > 0 && <Table.Row>
                        <Table.Cell>Packing Charges (HSN Code:{ orderDetailsData?.PackingHSN }):</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.PackingCharge) }</Table.Cell>
                      </Table.Row> }
                      { orderDetailsData?.PackingGST > 0 && <Table.Row>
                        <Table.Cell>Packing GST { orderDetailsData?.CGSTorIGST === "I" ? `(IGST(${numberFormatPdf(orderDetailsData?.PackingGSTPer)}%) - ${numberFormatPdf(orderDetailsData?.PackingGST)}` : `(CGST(${numberFormatPdf(orderDetailsData?.PackingGSTPer)}%) - ${numberFormatPdf((orderDetailsData?.PackingGST) / 2)} + SGST(${numberFormatPdf(orderDetailsData?.PackingGSTPer)}%) - ${numberFormatPdf((orderDetailsData?.PackingGST) / 2)})` }:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.PackingGST) }
                        </Table.Cell>
                      </Table.Row> }
                      <Table.Row>
                        <Table.Cell>Total Amount:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.GTotAmount) }</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Round Amount:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>{ numberFormatPdf(orderDetailsData?.Roff) }</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Payment Rcvd.:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>
                          { orderDetailsData?.PaymentMode !== "KOL COD" ? numberFormatPdf(orderDetailsData?.GTotAmount) : "0.00" }</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Receivable:</Table.Cell>
                        <Table.Cell collapsing textAlign='right'>
                          { orderDetailsData?.PaymentMode === "KOL COD" ? numberFormatPdf(orderDetailsData?.GTotAmount) : "0.00" }</Table.Cell>
                      </Table.Row>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
          <OrderDispatchModal InvoiceID={ slug } authToken={ authToken } />
          <OrderCompleteModal InvoiceID={ slug } authToken={ authToken } />
          <OrderConfirmModal InvoiceID={ slug } authToken={ authToken } />
          <OrderCancelModal InvoiceID={ slug } authToken={ authToken } />
        </Container> }
    </div >
  )
}

export default OrderDetails