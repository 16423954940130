export default function (state = {}, action) {
   switch (action.type) {

      case 'GET_GALLERYTYPE':
         {
            return {
               ...state,
               galleryTypeListPayload: action.payload
            };
         }
      case 'GET_GALLERY':
         {
            return {
               ...state,
               galleryListPayload: action.payload
            };
         }
      default:
         return state;
   }
}
