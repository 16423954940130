import React from "react";
import { Table } from "semantic-ui-react";
import { IMAGE_URL } from "../../../Resources/Api";

const ExpandedComponent = ({ data }) => {
    console.log("ExpandedComponent", data);
    return (
        <>
            <Table basic='very' compact celled collapsing style={{padding: 20, marginLeft: 50}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Product Name</Table.HeaderCell>
                        <Table.HeaderCell>Rate</Table.HeaderCell>
                        <Table.HeaderCell>GST Amt.</Table.HeaderCell>
                        <Table.HeaderCell>Pack</Table.HeaderCell>
                        <Table.HeaderCell>Qty</Table.HeaderCell>
                        <Table.HeaderCell>Image</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data?.InvProducts?.map((item, index) =>
                        <Table.Row key={index}>
                            <Table.Cell>{index+1}</Table.Cell>
                            <Table.Cell>{item.ProductName}</Table.Cell>
                            <Table.Cell>{item.ProdValue}</Table.Cell>
                            <Table.Cell>{item.GSTAmt}</Table.Cell>
                            <Table.Cell>{item.PackQty} {item.UOM}</Table.Cell>
                            <Table.Cell>{item.OrdQty}</Table.Cell>
                            <Table.Cell><img src={ IMAGE_URL + item.ProductImage} width="50" height="50" /></Table.Cell>
                        </Table.Row>)}
                </Table.Body>
            </Table>
        </>
    )
}

export default ExpandedComponent