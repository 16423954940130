const initialState = {
    loadingPayload: false,
    pageLoadingPayload: false,
    multiLoadingPayload: false,
    pageLodingTypePayload: false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "LOADING_STATUS":
            return {
                ...state,
                loadingPayload: action.payload
            }
        case "LOADING_STATUS_MENU":
            return {
                ...state,
                loadingMenuPayload: action.payload
            }
        default:
            return state
    }
}
