export const changePasswordBtnAction = () => ({
   type: 'CHANGE_PASSWORD_BTN_STATUS',
   payload: true
});

export const changePasswordCloseBtnAction = () => ({
   type: 'CHANGE_PASSWORD_BTN_STATUS',
   payload: false
});

export const editProfileNameAction = () => ({
   type: 'EDIT_PROFILE_NAME_STATUS',
   payload: true
});

export function clearMessage() {
   return async function (dispatch) {
      dispatch({
         type: 'MESSAGE',
         payload: false,
      })
   }
}

export function openCompleteModal(value) {
   return async function (dispatch) {
      dispatch({
         type: 'COMPLETE_MODAL',
         payload: value,
      })
   }
}

export function openDispatchModal(value) {
   return async function (dispatch) {
      dispatch({
         type: 'DISPATCH_MODAL',
         payload: value,
      })
   }
}

export function openConfirmModal(value) {
   return async function (dispatch) {
      dispatch({
         type: 'CONFIRM_MODAL',
         payload: value,
      })
   }
}

export function openCancelModal(value) {
   return async function (dispatch) {
      dispatch({
         type: 'CANCEL_MODAL',
         payload: value,
      })
   }
}

export function clearError() {
   return async function (dispatch) {
      dispatch({
         type: 'ERROR_MESSAGE',
         payload: false,
      })
      dispatch({
         type: 'MULTI_ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'SUCCESS_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'LOADING_STATUS',
         payload: false
      })
   }
}

export const clearSuccess = () => ({
   type: 'SUCCESS_MESSAGE',
   payload: false
});

export function addAddressOpen() {
   return async function (dispatch) {
      dispatch({
         type: 'ADD_ADDRESS_OPEN',
         payload: true
      })
   }
};

export function addAddressClose() {
   return async function (dispatch) {
      dispatch({
         type: 'ADD_ADDRESS_CLOSE',
         payload: false
      })
   }
};

export function pincodeCheckStatus() {
   return async function (dispatch) {
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: {
            status: true,
            data: 'check_pincode'
         }
      })
      localStorage.removeItem("pinCode")

      dispatch({
         type: 'OTP_OPEN_STATUS',
         payload: false
      })
   }
}

export function signInRouteAction(history) {
   return async function (dispatch) {
      dispatch({
         type: 'ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'SUCCESS_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'OTPINPUT_OPEN',
         payload: false
      })
      dispatch({
         type: 'OTP_BUTTON_OPEN',
         payload: true
      })
      history.push('/login')
   }
}
export function signUpRouteAction(history) {
   return async function (dispatch) {
      dispatch({
         type: 'MULTI_ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'SUCCESS_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'OTPINPUT_OPEN',
         payload: false
      })
      dispatch({
         type: 'OTP_BUTTON_OPEN',
         payload: true
      })
      history.push('/signup')
   }
}

export function homeRouteAction(history) {
   return async function (dispatch) {
      dispatch({
         type: 'ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'SUCCESS_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'OTPINPUT_OPEN',
         payload: false
      })
      dispatch({
         type: 'OTP_BUTTON_OPEN',
         payload: true
      })
      history.push('/')
   }
}

export function forgotPasswordAction(history) {
   return async function (dispatch) {
      dispatch({
         type: 'ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'SUCCESS_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_ERROR_MESSAGE',
         payload: false
      })
      dispatch({
         type: 'MULTI_LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'LOADING_STATUS',
         payload: false
      })
      dispatch({
         type: 'OTPINPUT_OPEN',
         payload: false
      })
      history.push('/forgot-password')
   }
}

export function toggleMenu(action) {
   return async function (dispatch) {
      // console.log("action", action)
      localStorage.setItem('toggleMenu', JSON.stringify(action));
      dispatch({
         type: 'TOGGLE_MENU',
         payload: action
      })
   }
}