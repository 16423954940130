import React from 'react'
import {
    Button,
    Modal,
    Form
} from 'semantic-ui-react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux';
import { changeOrderStatus } from '../../Redux/Actions/OrdersAction';
import { openConfirmModal } from '../../Redux/Actions/ButtonAction';

export default function OrderConfirmModal(props) {
    const { InvoiceID, authToken } = props
    const dispatch = useDispatch();
    // const authToken = localStorage.getItem('accessToken');
    const isConfirmOpen = useSelector(state => state.buttonReducer.confirmModalPayload)
    const orderDetailsData = useSelector(state => state.orderReducer.orderDetailPayload)
    // console.log("isConfirmOpen", isConfirmOpen);
    return (
        <div>
            <Modal open={isConfirmOpen} size='tiny'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        Mode: "C",
                        InvoiceID: InvoiceID,
                        AWBNo: "",
                        comment: "",
                        RP_OrderID: "",
                        RP_PaymentID: "",
                    }}
                    onSubmit={(values, actions) => { dispatch(changeOrderStatus(values, actions, authToken)) }}
                // onSubmit={(values) => { console.log(values) }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <React.Fragment>
                            <Modal.Header>Confirm Order ID#: {orderDetailsData?.OrderNo}</Modal.Header>
                            <Modal.Content>
                                <h4>Are you sure, Do you want confirm the Order?</h4>
                                {/* <Form>
                                    <Form.Input
                                        error={errors.RP_OrderID && touched.RP_OrderID ? { content: errors.RP_OrderID } : false}
                                        fluid
                                        label='Razorpay Order Id'
                                        placeholder='Razorpay Order Id'
                                        type="text"
                                        value={values.RP_OrderID}
                                        autoFocus
                                        onChange={handleChange('RP_OrderID')}
                                    />
                                    <Form.Input
                                        error={errors.RP_PaymentID && touched.RP_PaymentID ? { content: errors.RP_PaymentID } : false}
                                        fluid
                                        label='Razorpay Payment Id'
                                        placeholder='Razorpay Payment Id'
                                        type="text"
                                        value={values.RP_PaymentID}
                                        onChange={handleChange('RP_PaymentID')}
                                    />
                                </Form> */}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => (dispatch(openConfirmModal(false)))}>
                                    Close
                                </Button>
                                <Button
                                    content="Update"
                                    type="submit"
                                    labelPosition='right'
                                    icon='checkmark'
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    positive
                                />
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}
