import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment'
import { numberFormatPdf } from "../NumberFormat";

export const dispatchListGenerator = (dispatchData, startDate, endDate) => {
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontSize(8);
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const totalPagesExp = '{total_pages_count_string}';


    const tableHeader = [[
        "#",
        "Delivery Agent Name",
        "Order No",
        "Order Date",
        "Invoice No",
        "Invoice Date",
        "Dispatch Date",
        "Del. Charge",
        "Bill Value"
    ]];

    doc.autoTable({
        head: tableHeader,
        body: dispatchData?.map((item, index) => {
            return [
                { content: index + 1 },
                { content: item.DelAgentName },
                { content: item.OrderNo },
                { content: moment(item.OrderDate).format('DD-MM-YYYY') },
                { content: item.InvoiceNo },
                { content: moment(item.InvoiceDate).format('DD-MM-YYYY') },
                { content: moment(item.dispDate).format('DD-MM-YYYY') },
                { content: numberFormatPdf(item.DeliveryCharge), styles: { halign: "right" } },
                { content: numberFormatPdf(item.BillValue), styles: { halign: "right" } }
            ]
        }),
        didDrawPage: function (data) {

            // Header
            doc.setFontSize(15)
            doc.text("BALARAM MULLICK & RADHARAMAN MULLICK LLP", pageWidth / 2, 10, 'center');
            doc.setFontSize(10)
            doc.text("2 PUDDAPUKUR ROAD, KOLKATA, WEST BENGAL, Pincode: 700020", pageWidth / 2, 15, 'center');
            doc.setFontSize(9)
            doc.text(`Dispatch List From - ${moment(startDate).format('DD-MM-YYYY')}, To - ${moment(endDate).format('DD-MM-YYYY')}`, pageWidth / 2, 20, 'center');

            // Footer
            var str = "Page " + doc.internal.getNumberOfPages()
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(6);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
        theme: 'grid',
        margin: {
            top: 25
        },
        headStyles: {
            fillColor: [63, 81, 181],
            fontSize: 8
        },
        bodyStyles: {
            fillColor: [216, 216, 216],
            textColor: 50,
            fontSize: 8
        },
        footStyles: {
            fillColor: [63, 81, 181],
            fontSize: 8
        },
        alternateRowStyles: {
            fillColor: [255, 255, 255]
        },
    });
    
    doc.autoTable({
        head: [[
            { content: "Total", styles: { halign: "center" } },
            { content: numberFormatPdf(dispatchData.reduce((sum, el) => sum + el.DeliveryCharge, 0)), styles: { halign: "right", cellWidth: 20 } },
            { content: numberFormatPdf(dispatchData.reduce((sum, el) => sum + el.BillValue, 0)), styles: { halign: "right", cellWidth: 20 } }
        ]],
        theme: 'grid',
        margin: {
            top: -25
        },
        headStyles: {
            fillColor: [63, 81, 181],
            textColor: [255, 255, 255],
            fontSize: 8,
            margin: {
                top: -25
            },
        }
    })
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
    }
    doc.save(`dispatch_list_${moment(startDate).format('DD-MM-YYYY')}-${moment(endDate).format('DD-MM-YYYY')}.pdf`);
};
