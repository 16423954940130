export default function (state = {}, action) {
   switch (action.type) {

      case 'GET_USERLIST':
         {
            return {
               ...state,
               userListPayload: action.payload
            };
         }
      case 'GET_USERROLE':
         {
            return {
               ...state,
               userRolePayload: action.payload
            };
         }
      default:
         return state;
   }
}
