import {combineReducers} from 'redux';
import LoadingReducer from './LoadingReducer';
import AuthReducer from './AuthReducer';
import MenuReducer from './MenuReducer';
import HomeReducer from './HomeReducer';
// import ProductCategoryReducer from './ProductCategoryReducer';
import TaxClassReducer from './TaxClassReducer';
import TaxonomyReducer from './TaxonomyReducer';
import ProductReducer from './ProductReducer';
import MessageReducer from './MessageReducer';
import ButtonReducer from './ButtonReducer';
import UserListReducer from './UserListReducer'
import BannerReducer from './BannerReducer';
import GalleryReducer from './GalleryReducer';
import FestReducer from './FestReducer';
import OrderReducer from './OrderReducer';
import EnquiryReducer from './EnquiryReducer';

const RootReducer = combineReducers({
     loadingReducer:LoadingReducer,
     authReducer:AuthReducer,
     menuReducer:MenuReducer,
     bannerReducer:BannerReducer,
     galleryReducer:GalleryReducer,
     userListReducer:UserListReducer,
     homeReducer:HomeReducer,
     // productCategoryReducer:ProductCategoryReducer,
     taxClassReducer:TaxClassReducer,
     taxonomyReducer:TaxonomyReducer,
     productReducer:ProductReducer,
     messageReducer:MessageReducer,
     buttonReducer:ButtonReducer,
     festReducer:FestReducer,
     orderReducer:OrderReducer,
     enquiryReducer:EnquiryReducer,
})

export default RootReducer;