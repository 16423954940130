import React from 'react';
import { Container, Button, Modal, Image, Form, Message, Icon, Confirm, Input, Popup, Segment, Dimmer, Loader } from 'semantic-ui-react'
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { getProductCategory, saveProductCategory, statusProductCategory } from '../../Redux/Actions/ProductCategoryAction'
import { clearMessage } from '../../Redux/Actions/ButtonAction'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { IMAGE_URL } from '../../Resources/Api';
import uploadImage from '../../Resources/images/upload_image.png';
import _ from 'lodash'

const CategoryMaster = () => {

   const dispatch = useDispatch()
   React.useEffect(() => {
      dispatch(getProductCategory(authToken))
   }, [])

   let location = useLocation();
   const menuList = useSelector(state => state.menuReducer.menuPayload)
   const rollPermission = menuList && menuList.reduce((prev, menu) => prev || menu.sub.find(item => item.Navigation === location.pathname), undefined)
   // console.log(rollPermission);
   const productCategoryList = useSelector(state => state.productReducer.productCategoryPayload)
   const authToken = localStorage.getItem('accessToken');
   const [openAddModal, setOpenAddModal] = React.useState(false)
   const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
   const [rowId, setRowId] = React.useState(false)
   const loadingData = useSelector(state => state.loadingReducer.loadingPayload);
   // const isPageLoading = loadingData && loadingData.string === 'product_category_list' ? loadingData.status : false
   const isLoading = loadingData && loadingData.string === 'product_category_save' ? loadingData.status : false
   // const isDeleteLoading = loadingData && loadingData.string === 'product_category_delete' ? loadingData.status : false
   const messageData = useSelector(state => state.messageReducer.messagePayload);
   const messageStatus = messageData && messageData.string === 'product_category_save' ? messageData.status : false

   const [actionMode, setActionMode] = React.useState('');
   const [rowData, setRowData] = React.useState([]);
   const [searchInput, setSearchInput] = React.useState('');

   const filteredItems = productCategoryList && productCategoryList.filter(item => (
      item.CategoryName.toLowerCase().includes(searchInput.toLowerCase())
   ));

   const searchComponent = <Input icon='search' placeholder='Search..' value={searchInput} onChange={e => setSearchInput(e.target.value)} />

   const actions = <React.Fragment>
      {rollPermission && rollPermission.Canadd &&
         <Button
            primary
            onClick={() => (setOpenAddModal(true), setActionMode('Add'))}>
            <Icon name='add circle' />
            Add new
         </Button>}
   </React.Fragment>;

   const conditionalRowStyles = [
      {
         when: row => row.CatStatus === 'InActive',
         style: {
            backgroundColor: '#f3f3f3',
            color: 'rgb(132 132 132 / 87%)'
         },
      }
   ]
   const columns = [
      {
         name: 'Category Name',
         selector: 'CategoryName',
         sortable: true,
      },
      // {
      //    name: 'Image',
      //    selector: 'CatImagePath',
      //    sortable: true,
      //    grow: 0,
      //    cell: row => <img height="50px" width="50px" alt={row.CategoryName} src={row.CatImagePath &&  IMAGE_URL + row.CatImagePath } />,
      // },
      {
         name: 'Url',
         selector: 'Slug',
         sortable: true,
      },
      {
         name: 'Action',
         sortable: false,
         right: true,
         cell: (data) => <React.Fragment>
            <Button.Group basic size='small'>
               {rollPermission && rollPermission.Candelete &&
                  <Popup
                     trigger={
                        <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.CategoryID), setActionMode('Delete'))}>
                           <Icon style={{ color: 'red' }} name='trash alternate outline' />
                        </Button>}
                     content='Delete'
                     position='top right'
                  />}
               {rollPermission && rollPermission.Canedit &&
                  <React.Fragment>
                     {data.CatStatus === 'InActive' ? <Popup
                        trigger={
                           <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.CategoryID), setActionMode('ReActive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock' />
                           </Button>}
                        content='Make Active'
                        position='top right'
                     /> : <Popup
                        trigger={
                           <Button icon onClick={() => (setOpenDeleteConfirm(true), setRowId(data.CategoryID), setActionMode('StatusInactive'))}>
                              <Icon style={{ color: '#ff8d00' }} name='lock open' />
                           </Button>}
                        content='Make Inactive'
                        position='top right'
                     />}
                  </React.Fragment>}
               {rollPermission && rollPermission.Canedit &&
                  <Popup
                     trigger={
                        <Button icon onClick={() => (setRowData(data), setOpenAddModal(true), setActionMode('Edit'))}>
                           <Icon style={{ color: '#001fff' }} name='edit outline' />
                        </Button>}
                     content='Edit'
                     position='top right'
                  />}
            </Button.Group>
         </React.Fragment>,
      },
   ];

   const customStyles = {
      headCells: {
         style: {
            backgroundColor: 'rgb(33 133 208)',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: '700',
         },
      }
   }

   const categorySchema = Yup.object().shape({
      CategoryName: Yup.string()
         .min(4, 'Product category name is too short!')
         .max(50, 'Product category name is too Long!')
         .required('Product category name is required'),
   });

   setTimeout(() => {
      dispatch(clearMessage())
   }, 5000)

   // const [imageUrl, setImageUrl] = React.useState([])

   const onImageChange = (event, setFieldValue) => {
      if (event.target.files && event.target.files[0]) {
         setFieldValue('CatImagePath', event.target.files[0])
         let reader = new FileReader();
         reader.onload = (e) => {
            setFieldValue('imageUrl', e.target.result);
         };
         reader.readAsDataURL(event.target.files[0]);
      }
   }


   //  console.log(imageUrl)

   return (
      <div>
         <Container fluid>
            <DataTable
               title='Product category Master'
               columns={columns}
               customStyles={customStyles}
               data={filteredItems}
               actions={actions}
               pagination={true}
               paginationResetDefaultPage={true}
               subHeader
               subHeaderComponent={searchComponent}
               conditionalRowStyles={conditionalRowStyles}
               persistTableHead
               progressPending={isLoading}
               progressComponent={
                  <Segment>
                     <Dimmer active inverted>
                        <Loader size='large'>Loading</Loader>
                     </Dimmer>
                  </Segment>
               }
            />
            <Modal open={openAddModal} size='tiny'>
               <Formik
                  enableReinitialize
                  initialValues={actionMode === "Add" ? {
                     Mode: "A",
                     CategoryID: 0,
                     CategoryName: "",
                     CatImagePath: [],
                     imageUrl: uploadImage
                  } :
                     {
                        Mode: "E",
                        CategoryID: rowData.CategoryID,
                        CategoryName: rowData.CategoryName,
                        CatImagePath: rowData.CatImagePath,
                     }
                  }
                  validationSchema={categorySchema}
                  onSubmit={(values, actions) => { dispatch(saveProductCategory(values, actions, actionMode, authToken)) }}
               // onSubmit={(values) => { console.log(values) }}
               >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     isSubmitting,
                     handleSubmit,
                     setFieldValue
                  }) => (
                     <React.Fragment>
                        <Modal.Header>{actionMode === "Edit" ? 'Edit product category' : 'Create product category'}</Modal.Header>
                        <Modal.Content>
                           {messageStatus && <Message error header={messageData.message} />}
                           <Form>
                              <Form.Input
                                 error={errors.CategoryName && touched.CategoryName ? { content: errors.CategoryName } : false}
                                 fluid
                                 label='Category Name'
                                 placeholder='Category Name'
                                 type="text"
                                 value={values.CategoryName}
                                 onChange={handleChange('CategoryName')}
                              />
                              <label htmlFor="imageInput">
                                 {actionMode === 'Edit' ?
                                    <img src={values.imageUrl ? values.imageUrl : IMAGE_URL + values.CatImagePath} style={{ width: 200, height: 200, marginTop: 20 }} alt="..." /> :
                                    <img src={values.CatImagePath !== [] ? values.imageUrl : uploadImage}
                                       style={{
                                          width: 200,
                                          height: 200,
                                          marginTop: 20
                                       }}
                                       alt="..." />

                                 }
                              </label>
                              <input
                                 id="imageInput"
                                 type="file"
                                 onChange={(event) => onImageChange(event, setFieldValue)}
                                 accept="image/*"
                                 style={{ opacity: 0, position: 'absolute' }}
                              />
                              <h5 style={{ marginTop: 0 }}>Select or drop product image</h5>
                              <h5 style={{ marginTop: 0 }}>(Image Size 400x400)</h5>
                              <p style={{ color: 'red' }}>{errors.CatImagePath && touched.CatImagePath ? errors.CatImagePath : null}</p>

                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button negative onClick={() => (window.location.reload(), setOpenAddModal(false))}>
                              Close
                           </Button>
                           <Button
                              content={actionMode === "Edit" ? "Save" : "Create"}
                              type="submit"
                              labelPosition='right'
                              icon='checkmark'
                              loading={isSubmitting}
                              onClick={handleSubmit}
                              positive
                           />
                        </Modal.Actions>
                     </React.Fragment>
                  )}
               </Formik>
            </Modal>
            <Confirm
               header='Are you sure?'
               content={
                  actionMode === 'Delete' ? 'Data will lost' :
                     actionMode === 'StatusInactive' ? 'Data will inactive.' :
                        actionMode === 'ReActive' ? 'Data will active?' : null
               }
               open={openDeleteConfirm}
               onCancel={() => setOpenDeleteConfirm(false)}
               onConfirm={() => { dispatch(statusProductCategory(rowId, setOpenDeleteConfirm, actionMode, authToken)) }}
               size='mini'
            />
         </Container>
      </div>
   )
}

export default CategoryMaster